import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { WHITE, BABEL_BLUE, SPACE_GREY } from '../colors'

// How to wrap up ReactRouter Link so we have `to=`
// But still need to style said link (remove text-decoration), etc...
// https://github.com/styled-components/styled-components/issues/184
const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-size: 1em;
  font-weight: 700;

  letter-spacing: .3px;
  line-height: 20px;
  
  color: ${WHITE};
  
  background-color: ${props => props.fill};
  border-radius: 8px;
  width: 8em;
  //max-height: 30px;
  padding: .5em .3em .4em .3em;
  display: block;
  text-decoration: none;
  text-align: center;
  

  &:hover {
    text-decoration: none;
  }
  &.active {
    color: ${WHITE};
  }
`

const ButtonLink = (props) => {
  const COLOR = ''
  const BACKGROUND_COLOR = ((props.backgroundColor) ? props.backgroundColor : SPACE_GREY)
  // used `fill`, for some reason as backgroundColor -- it would get passed down to a.Link
  // and the console in the browser picked up an error message -- annoying
  return (<StyledLink to={props.to} fill={BACKGROUND_COLOR}>{props.children}</StyledLink>)
}

export default ButtonLink
