/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import Loader from '../../core/molecules/Loader'
import AppPage from '../../core/pages/appPage'

import SherpaLayout from '../templates/sherpaLayout'

import CardStepper from '../organisms/CardStepper'

import { ACTIVITY_AND_PHASES_QUERY } from '../../lib/queries/sherpa'

import { getUrlSlug } from '../../lib/utils'

import * as _ from 'lodash'

import { progressTasksVar } from '../../cache'

function SherpaLandingView (props) {
  const phaseId = props.match.params.phaseId
  const activityId = props.match.params.activityId

  const { loading, error, data } = useQuery(ACTIVITY_AND_PHASES_QUERY, {
    // TODO: errors if fetchPolicy is not 'no-cache'
    fetchPolicy: 'no-cache'
  })

  // TODO: UI WORK -- create a real loader
  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) return `Error!: ${error}`

  // console.log('UserLearning API Data', data)
  const cachedProgress = progressTasksVar()
  let currentActivity
  let nextActivity
  let currentTasks = []

  // find current phase
  const currentPhase = data.userLearning.phases.find(phase => {
    // console.log('phaseId', phaseId)
    // console.log('getUrlSlug(phase.title)', getUrlSlug(phase.title))
    return getUrlSlug(phase.title.toLowerCase()) === phaseId.toLowerCase()
  })

  // find current activity
  if (currentPhase) {
    // console.log('currentPhase', currentPhase)
    currentActivity = currentPhase.activities.find(activity => {
      console.log(activity.title, '-2-', activityId)
      return getUrlSlug(activity.title.toLowerCase()) === activityId.toLowerCase()
    })

    if (currentActivity) {
      console.log('currentActivity', currentActivity)
      // set next phase/activity component
      // activity land page - next is next activity
      currentActivity.tasks.forEach(task => {
        if (task.sections) {
          task.sections.forEach(section => {
            currentTasks.push(section.topics)
          })
        } else {
          currentTasks.push(task)
        }
      })

      currentTasks = currentTasks.flat()
      // console.log('currentTasks', currentTasks)

      // figure out current position in order to figure out nextActivity
      const currentActivityPos = currentPhase.activities.findIndex(activity => {
        return getUrlSlug(activity.title.toLowerCase()) === activityId.toLowerCase()
      })

      const nextActivityPos = currentActivityPos + 1
      if (currentPhase.activities.length > nextActivityPos) {
        nextActivity = currentPhase.activities[nextActivityPos]
      }

      // console.log('next', nextActivity)
    } else {
      console.error(currentActivity)
      return 'Error!: can not find activity'
    }
  } else {
    return 'Error!: can not find phase'
  }

  // set back button
  const backObj = {
    title: 'Back to Activities',
    phaserUrl: '/learning/' + phaseId,
    activityUrl: '/learning/' + phaseId + '/' + activityId,
    url: '/onboarding/' + phaseId
  }

  // display rail
  const railObj = [{
    type: 'activity',
    state: 'active'

  }]

  // card data
  const pageData = {
    title: currentActivity.title ? currentActivity.title : '',
    description: currentActivity.description ? currentActivity.description : '',
    tasks: currentActivity.tasks,
    userActivityUuid: currentActivity.uuid

  }

  const nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTasks[0].title)}/1`

  return (
    <SherpaLayout type='landing' backto={backObj} currentPhase={currentPhase} currentActivity={currentActivity} nextActivity={nextActivity}>
      <CardStepper type='landing' data={pageData} rail={railObj} nextURL={nextURL} previousURL='' localProgress={cachedProgress} />
    </SherpaLayout>
  )
}

export default SherpaLandingView
