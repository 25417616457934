import React from 'react'
/*
 *  The GitBranch is our base
 * 
 
 */

 
const GitBranch = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="ui/atoms/glyphs/git-branch" transform="translate(1.000000, 1.000000)" stroke="#000000" strokeWidth="1">
            <g id="git-pull-request">
                <circle id="Oval" cx="15" cy="15" r="3"></circle>
                <circle id="Oval" cx="3" cy="3" r="3"></circle>
                <path d="M10,3 L13,3 C14.1045695,3 15,3.8954305 15,5 L15,12" id="Shape"></path>
                <path d="M3,6 L3,18" id="Shape"></path>
            </g>
        </g>
    </g>
    </svg>
)


export default GitBranch
