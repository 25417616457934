
import React from 'react'

// this looks like a mess and thing we have this icon els where

const Repository = () => (
  <div style={{}}>
    <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M14.6667 20C15.4 20 16 19.3571 16 18.5714V1.42857C16 0.642857 15.4 0 14.6667 0H1.33333C0.6 0 0 0.642857 0 1.42857V18.5714C0 19.3571 0.6 20 1.33333 20H4H8H14.6667ZM5 3H4V4H5V3ZM4 6H5V7H4V6ZM5 9H4V10H5V9ZM5 13H4V12H5V13ZM15 16H2V19H4.6V17.5H8.5V19H15V16ZM3 1H15V14H3V1Z' fill='#A3A9B1' />
    </svg>
  </div>
)

export default Repository
