import { gql } from '@apollo/client'

export const GET_CONSOLE_DATA = gql`
 query consoleData {
    me {
        uuid, 
        firstName, 
        lastName, 
        email, 
        isActive, 
        login, 
        githubUserId,
        org
      }
    appEntities (entityType: String, uuid: ID) {
        entityType,
        entittyUuid,
        appId,
        installId,
        isInstalled,
        repositorySelection
     }
 }
`

// Sending Parameters, map the schema definition and the param-handoff
// If you don't do both, you will get a 400 Error
export const GET_APP_ENTITIES = gql`
 query appEntities ($entityType: String!, $uuid: ID!) {
   appEntities (entityType: $entityType, uuid: $uuid) {
    entityType,
    entityUuid,
    appId,
    installId,
    isInstalled,
    repositorySelection,
    createdAt,
    updatedAt
  }
 }
`

export const GET_TOPICSCORECARD_DATA = gql`
 query topicScorecard {
   topics {
     title
   },
   topicScorecard {
   adr,
   guide,
   readme,
   changelog,
   reference,
   runbook,
   policy,
   uncategorized
 }
}
`

export const GET_REPOS = gql`
  query getRepos ($org: String!) {
    repositories (org: $org) {
      uuid,
      name,
      org,
      description,
      active,
      deleted,
      private,
      connected
    }
  }
`

export const UPDATE_REPOSITORY_MUTATION = gql`
  mutation updateRepository ($uuid: String!, $org: String!, $active: Boolean!){
    repositories(org: $org, uuid: $uuid, active: $active) {
      uuid,
      active,
      org
  }
}
`

export const GET_WORKSPACE_DROPDOWN_FIELDS = gql`
  query fetchWorkspaceByUser ($userUuid: ID!) {
    fetchWorkspaceByUser (userUuid: $userUuid) {
    workspaceUuid,
    userUuid,
    workspaces {
      workspaceType,
      name,
      appEntityUuid,
      billingUserUuid
    }
    }
  }
`

export const GET_WORKSPACE_REPOS = gql`
query fetchRepos ($workspaceUuid: ID!) {
  fetchRepos (workspaceUuid: $workspaceUuid) {
    workspaceUuid,
    repositoryUuid,
    repos {
      uuid,
      name,
      org,
      fullName,
      description,
      active,
      private,
      connected
    }
  }
}
`

export const GET_WORKSPACE_TOPIC_SCORECARD = gql`
query topicScorecardByWorkspace ($workspaceUuid: ID!) {
  topicScorecardByWorkspace (workspaceUuid: $workspaceUuid) {
    adr,
    guide,
    readme,
    changelog,
    reference,
    uncategorized,
    runbook,
    policy,
    rfc
  }
}
`

export const GET_WORKSPACE_APP_ENTITIES = gql`
query fetchAppEntities ($appEntityUuid: ID!) {
  fetchAppEntities (appEntityUuid: $appEntityUuid) {
    uuid,
    entityType,
    entityUuid,
    appId,
    installId,
    isInstalled
  }
}
`