import React from 'react'

const Repository = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.6667 20C17.4 20 18 19.3571 18 18.5714V1.42857C18 0.642857 17.4 0 16.6667 0H3.33333C2.6 0 2 0.642857 2 1.42857V18.5714C2 19.3571 2.6 20 3.33333 20H6H10H16.6667ZM7 3H6V4H7V3ZM6 6H7V7H6V6ZM7 9H6V10H7V9ZM7 13H6V12H7V13ZM17 16H4V19H6.6V17.5H10.5V19H17V16ZM5 1H17V14H5V1Z' fill='#A3A9B1' />
    </svg>

  )
}

export default Repository
