
import styled from 'styled-components/macro';

// TODO: consider moving into Doc-ops, was placed here in a hurry to move out of `app.js`
// AppHeader gets loaded on most pages except for onboarding
// set flex 0 0 auto, so that it shrinks to the size of it's content
// otherwise flex 1 0 auto, it will take on a crazy vertical height
const AppHeaderControls = styled.div`
  display: flex;
  flex-direction:row;
  flex: 0 0 auto;
`;

export default AppHeaderControls;