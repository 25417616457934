import React from 'react'
// Get our ReactRouter -- {Route, Switch, Link, Redirect, withRouter }
import { Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './privateRoute'

// [CORE]
import ReportView from '../core/views/reportView'
import ReportDetailView from '../core/views/reportDetailView'
// import OnboardingView from '../core/views/onboardingView'
import ConsoleView from '../core/views/consoleView'
import SubscriptionView from '../core/views/subscriptionView'

// [DOC-OPS]
import LibraryView from '../doc-ops/views/libraryView'
import TopicView from '../doc-ops/views/topicView'
import DiscoveryView from '../doc-ops/views/discoveryView'

// [Bonvoy]
import Phaser from '../bonvoy/views/phaser'
import SherpaLandingView from '../bonvoy/views/sherpaLandingView'
import SherpaContentView from '../bonvoy/views/sherpaContentView'
import SherpaCompletedView from '../bonvoy/views/sherpaCompletedView'

function AppRoutes () {
  /* Switch, will force only one component to load on matching otherwise
        redirect to `discovery`
        IMPORTANT: ORDER MATTERS HERE
        Redirect should be last

        Topic Urls
        - /topic/:repository/:urlSlug
        --TODO - defering as a followup
        - /topic/:repository/tree/:branch/:urlSlug
        - /topic/:repository/tag/:tag/:urlSlug
    */
  return (
    <Switch>
      <PrivateRoute path='/onboarding/:phaseId?' component={Phaser} />

      <PrivateRoute path='/learning/:phaseId/:activityId/:taskId/complete' component={SherpaCompletedView} />
      <PrivateRoute path='/learning/:phaseId/:activityId/:taskId/:sectionId?' component={SherpaContentView} />
      <PrivateRoute path='/learning/:phaseId/:activityId' component={SherpaLandingView} />

      <PrivateRoute path='/discovery/:detailId' component={DiscoveryView} />
      <PrivateRoute path='/discovery' component={DiscoveryView} />

      <PrivateRoute path='/topic/:repository/:topicUrlSlug' component={TopicView} />

      <PrivateRoute path='/library' component={LibraryView} />

      <PrivateRoute path='/console' component={ConsoleView} />

      <PrivateRoute path='/subscription' component={SubscriptionView} />

      {/* reportDetailSelction should be reference-report or contributor-report atm */}
      <PrivateRoute path='/reports/engagement/:reportDetailSelection' component={ReportDetailView} />
      <PrivateRoute path='/reports' component={ReportView} />

      {/* Catch All is to discovery? */}
      <Redirect to='/discovery' />
    </Switch>
  )
}

export default AppRoutes
