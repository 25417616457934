// Need React for our functional component
import React from 'react'
// Create Necessary presentation components wrappers
import styled from 'styled-components/macro'

import {
  DOC_OPS_TOPIC_ADR, DOC_OPS_TOPIC_CHANGELOG, DOC_OPS_TOPIC_FAQ, DOC_OPS_TOPIC_GUIDE, DOC_OPS_TOPIC_README,
  DOC_OPS_TOPIC_REFERENCE, DOC_OPS_TOPIC_RUNBOOK
} from '../constants'

import Icon from '../../core/atoms/Icon'

// Need a helper to wrap up all the components into a flex-container
const HeaderFlexWrapper = styled.div`
  padding-top:1em;
  display: flex;
  flex-direction: row;
`

// This needs to get mounted into a functional molecule/doc-ops and it will need additional work
const HeaderText = styled.h3`
  color: '#ff0000';
  display: flex;
  flex-basis: 20%;
  flex-direction: column;
  padding:0 0 0 1em;
  margin:0;
`

function TopicCategoryHeader (props) {
  // console.log('category header props',props)

  const headerType = props.header.toLowerCase()

  // Local Vars
  let headerIcon = ''
  let headerText = ''

  const iconStrokeColor = '#000000'
  // Build the Output to mount into our render return( ...component(s) )
  if (headerType === DOC_OPS_TOPIC_ADR) {
    headerIcon = <Icon glyph='topicAdr' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Architecture Decision Records'
  } else if (headerType === DOC_OPS_TOPIC_CHANGELOG) {
    headerIcon = <Icon glyph='topicChangelog' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Changelog'
  } else if (headerType === DOC_OPS_TOPIC_FAQ) {
    headerIcon = <Icon glyph='topicFaq' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'FAQ'
  } else if (headerType === DOC_OPS_TOPIC_GUIDE) {
    headerIcon = <Icon glyph='topicGuide' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Guides'
  } else if (headerType === DOC_OPS_TOPIC_README) {
    headerIcon = <Icon glyph='topicReadme' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Readme'
  } else if (headerType === DOC_OPS_TOPIC_REFERENCE) {
    headerIcon = <Icon glyph='topicReference' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Reference'
  } else if (headerType === DOC_OPS_TOPIC_RUNBOOK) {
    headerIcon = <Icon glyph='topicRunbook' size='standard' strokeColor={iconStrokeColor} />
    headerText = 'Runbooks'
  }

  return (
    <HeaderFlexWrapper>
      {headerIcon}
      <HeaderText>{headerText}</HeaderText>
    </HeaderFlexWrapper>
  )
}

export default TopicCategoryHeader
