import React from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { LEGACY, SPACE_GREY, WHITE } from '../../../core/colors'

const DescriptionLabel = styled.div`
  font-size: .8em;
  color: ${SPACE_GREY};
  font-weight: 300;
  padding: 0;
  margin: 0;
`
const DescriptionStatement = styled.p`
  font-size: 1em;
  color: ${LEGACY};
  font-weight: 400;
  padding: .7em 0 1em 0;
  margin: 0;
`

const DescriptionFrame = styled.div`
    background-color: ${WHITE};
    padding: 1em;
    border: 1px solid #e5e5e5;
    box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
    border-radius: 4px;
    min-height:300px
`

function DiscoveryCollectionDetailContent (props) {
  return (
    <DescriptionFrame>
      <DescriptionLabel>DESCRIPTION</DescriptionLabel>
      {/* description sits in a frame with the section-summary blocks */}
      <DescriptionStatement>{props.description}</DescriptionStatement>

      {props.children}

    </DescriptionFrame>
  )
}

DiscoveryCollectionDetailContent.propTypes = {
  description: PropTypes.string.isRequired
}

export default DiscoveryCollectionDetailContent
