// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

// Colors
import { WHITE, SPACE_GREY } from '../../../core/colors'

// Atoms
import BigButton from '../../../core/atoms/BigButton'
import { findLastIndex } from 'lodash'

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-size: 1em;
  letter-spacing: .8px;
  line-height: 24px;
  /* identical to box height */
  //letter-spacing: -0.16px;

  font-weight: 700;
  //text-shadow: 0 2px 4px rgba(0,0,0,0.50);
  color: ${WHITE};

  /* blue / BabelingBlue - Base */
  background-color: ${SPACE_GREY};
  border-radius: 6px;
  width: 5em;
  //max-height: 30px;
  padding: .3em .3em .4em .3em;
  display: block;
  box-shadow: 4px 4px 0px #DDE0E4;
  text-decoration: none;
  text-align: center;
  /* Text gets underline unless we put an override here */
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
`

const TextFrame = styled.div`
  flex: 1 0 auto;
  margin: 0 4px 0 0;
`
const ButtonFrame = styled.div`
 display: flex;
 margin-right: .8em;
`

// Convert to a `class`

function PreviousButton (props) {
  // need to bind a click event in here

  /*
   * For now we just want to resuse the BigButton style-plate
   * and styled-link wrapper to send us back to previous url...
   * 1. Going back to previous TopicSection
   * 2. IF, there isn't a previous topicSection then we can go back to the last card in a topic?
   * 3... need a version of this that is a disabled visual....
   *
   */

  // We can park this here
  // Eventually we will want to track the back-clicks... important to know if people are flipping
  function handleClick (event) {
    // console.log('cliclie -props:', props)
    // lets start to write values now
  }
  // console.log('@@@@ LOADED bUTOON', props)
  const TEXT = '< PREV'
  return (
    <ButtonFrame onClick={handleClick}>
      <StyledLink to={props.to}>

        {TEXT}
      </StyledLink>
    </ButtonFrame>

  )
}

PreviousButton.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool
}

export default PreviousButton
