// Need React for our functional component
import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import StyledLink from '../../atoms/styledLink'

const Row = styled.div`
    margin: 0 0 10px 0;
`

const Header = styled.h3`
    margin: 0 0 10px 0;
    padding: 0;
`

function GlobalSearchTopicResults(props) {
    // console.log('props', props)
    function createTableDataRows (topics) {
        const topicRows = topics.map((topic) => {
            const topicUrl = `/topic/${topic.repository}/${topic.urlSlug}`
            return (
            <Row key={topic.uuid + Math.floor(Math.random() * 10000)}>
                <StyledLink to={topicUrl}>{topic.title}</StyledLink>
            </Row>
            )
        })
        return topicRows
    }

    const results = createTableDataRows(props.data)

    return (
       <div>
           <Header>Topics</Header>
           {results}
        </div>
    )
}

GlobalSearchTopicResults.propTypes = {
    data: PropTypes.array
};

export default GlobalSearchTopicResults;