import React from 'react'
/*
 * Discovery ICON, change the stroke width if selected
 *
 */
const Discovery = (props) => {
  let strokeWidth = 1
  // TODO Color-Constant: LEGACY - D2
  let strokeColor = '#343639'
  if (props.selected) {
    strokeWidth = 1.2
    // TODO Color-Constant: NightsWatch - D3
    strokeColor = '#0d0e11'
  }

  return (
    <div style={{}}>
      <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth={strokeWidth} fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/organisms/nav/vertical/v2/library-selected' transform='translate(-32.000000, -167.000000)' stroke={strokeColor}>
            <g id='discovery' transform='translate(29.000000, 165.000000)'>
              <g id='icon/discovery' transform='translate(3.000000, 2.000000)'>
                <g id='business-climb-top'>
                  <path d='M4.25,23.229 L12.75,9.729 C12.9886763,9.41830183 13.3582096,9.23619334 13.75,9.23619334 C14.1417904,9.23619334 14.5113237,9.41830183 14.75,9.729 L23.25,23.229 L4.25,23.229 Z' id='Shape' />
                  <polyline id='Shape' points='4.25 23.229 0.75 23.229 4.5 16.479 6.675 19.378' />
                  <path d='M8.7,16.162 L11.048,18.135 C11.4449495,18.4709917 12.0331737,18.4466454 12.401,18.079 L13.043,17.436 C13.4334999,17.0456181 14.0665001,17.0456181 14.457,17.436 L15.099,18.079 C15.4668263,18.4466454 16.0550505,18.4709917 16.452,18.135 L18.8,16.162' id='Shape' />
                  <path d='M13.5,9.267 L13.5,0.729' id='Shape' />
                  <path d='M13.5,0.729 L20.693,0.729 C20.8934125,0.730016851 21.0751079,0.846988109 21.159,1.029 C21.2453414,1.21091249 21.2209183,1.42606834 21.096,1.584 L19.959,3.026 C19.8081408,3.21864703 19.8081408,3.48935297 19.959,3.682 L21.1,5.126 C21.2248683,5.28433678 21.2492753,5.49973867 21.163,5.682 C21.0793029,5.86418119 20.8974859,5.98123071 20.697,5.982 L13.5,5.982' id='Shape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Discovery
