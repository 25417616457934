import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

// Atoms
import Markdown from '../../atoms/glyphs/markdown'
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import Adr from '../../atoms/glyphs/doc-ops/topic/topicAdrGlyph'
import Readme from '../../atoms/glyphs/doc-ops/topic/topicReadmeGlyph'
import Guide from '../../atoms/glyphs/doc-ops/topic/topicGuideGlyph'
import Changelog from '../../atoms/glyphs/doc-ops/topic/topicChangelogGlyph'
import Reference from '../../atoms/glyphs/doc-ops/topic/topicReferenceGlyph'
import Uncategorized from '../../atoms/glyphs/doc-ops/topic/topicFaqGlyph'
import Runbook from '../../atoms/glyphs/doc-ops/topic/topicRunbookGlyph'
import Policy from '../../atoms/glyphs/doc-ops/topic/topicPolicyGlyph'

// Atoms
import ConsoleFrame from '../../atoms/consoleFrame'

// Moleclues
import Loader from '../Loader'

// Queries
import { GET_WORKSPACE_TOPIC_SCORECARD } from '../../../lib/queries/console'

const TopicGrid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width:641px)  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
  }
`

const TopicColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const TopicTitle = styled.span`
    font-family: Open Sans;
    font-size: 1.5em;
    font-weight: 500;
    `

const TopicItem = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    `

const ScorecardItem = styled(TopicItem)`
    padding: 0.3em 0 0.3em 0;
    `

const Glyph = styled.div`
    padding: 0 0.5em 0 0;
`

const TopicsSection = (props) => {
  const {loading, error, data} = useQuery(GET_WORKSPACE_TOPIC_SCORECARD, { variables: { workspaceUuid: props.userdata.state.workspace } })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }

  const topics = data.topicScorecardByWorkspace
  const topicCount = topics.adr + topics.changelog + topics.guide + topics.policy + topics.readme + topics.reference + topics.rfc + topics.runbook + topics.uncategorized
  const scorecard = data.topicScorecardByWorkspace

  return (
    <ConsoleFrame>
      <TopicItem><div style={{ paddingRight: '.75em' }}><Markdown /></div> <TopicTitle>Topics</TopicTitle></TopicItem>
      <GreySkinnyRectangle />
      {/* Adding any type of bolding to {topics} throws the alignement of everything off */}
      <TopicItem style={{ paddingTop: '1em', paddingBottom: '2 em' }}>There are currently <strong style={{padding: '0 0.2em 0 0.2em'}}>{topicCount}</strong> processed topics inside of this workspace</TopicItem>
      <TopicGrid>
        <TopicColumn>
          <b style={{padding: '1.5em 0 1.5em 0'}}>Architecture</b>
          <ScorecardItem><Glyph><Adr width={24} height={24} strokeColor='grey' /></Glyph> ADR ({scorecard.adr})</ScorecardItem>
          <ScorecardItem><Glyph><Readme width={24} height={24} strokeColor='grey' /></Glyph>  Readme ({scorecard.readme})</ScorecardItem>
          <ScorecardItem><Glyph><Guide width={24} height={24} strokeColor='grey' /></Glyph>  Guide ({scorecard.guide})</ScorecardItem>
        </TopicColumn>
        <TopicColumn>
          <b style={{padding: '1.5em 0 1.5em 0'}}>Development</b>
          <ScorecardItem><Glyph><Uncategorized width={24} height={24} strokeColor='grey' /></Glyph>  Uncategorized ({scorecard.uncategorized})</ScorecardItem>
          <ScorecardItem><Glyph><Reference width={24} height={24} strokeColor='grey' /></Glyph>  Reference ({scorecard.reference})</ScorecardItem>
          <ScorecardItem><Glyph><Changelog width={24} height={24} strokeColor='grey' /></Glyph> Changelog ({scorecard.changelog})</ScorecardItem>
        </TopicColumn>
        <TopicColumn>
          <b style={{padding: '1.5em 0 1.5em 0'}}>Operations</b>
          <ScorecardItem><Glyph><Runbook width={24} height={24} strokeColor='grey' /></Glyph>  Runbook ({scorecard.runbook})</ScorecardItem>
          <ScorecardItem><Glyph><Policy width={24} height={24} strokeColor='grey' /></Glyph>  Policy ({scorecard.policy})</ScorecardItem>
        </TopicColumn>
      </TopicGrid>
    </ConsoleFrame>
  )
}

export default TopicsSection
