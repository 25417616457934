import React from 'react'
/*
 *
 */
const Library = (props) => {
  let strokeWidth = 1
  // TODO Color-Constant: LEGACY - D2
  let strokeColor = '#343639'
  if (props.selected) {
    strokeWidth = 1.2
    // TODO Color-Constant: NightsWatch - D3
    strokeColor = '#0d0e11'
  }
  return (
    <div style={{}}>
      <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth={strokeWidth} fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/organisms/nav/vertical/v2/library-selected' transform='translate(-33.000000, -244.000000)' stroke={strokeColor} strokeWidth={strokeWidth}>
            <g id='library' transform='translate(21.000000, 234.000000)'>
              <g id='icon/library' transform='translate(12.000000, 10.000000)'>
                <g id='bookmarks-document'>
                  <path d='M5.25,2.249 L2.25,2.249 C1.42157288,2.249 0.75,2.92057288 0.75,3.749 L0.75,21.749 C0.75,22.5774271 1.42157288,23.249 2.25,23.249 L21.75,23.249 C22.5784271,23.249 23.25,22.5774271 23.25,21.749 L23.25,3.749 C23.25,2.92057288 22.5784271,2.249 21.75,2.249 L11.25,2.249' id='Shape' />
                  <path d='M11.2499993,11.249 L8.24999933,9 L5.24999933,11.25 L5.24999933,1.5 C5.24973455,1.30091443 5.32863498,1.10989199 5.46931605,0.96902335 C5.60999711,0.82815471 5.80091425,0.749 6,0.749 L10.5,0.749 C10.9142136,0.749 11.2499993,1.08478644 11.2499993,1.499 L11.2499993,11.249 Z' id='Shape' />
                  <path d='M5.25,18.749 L15.75,18.749' id='Shape' />
                  <path d='M5.25,14.249 L18.75,14.249' id='Shape' />
                  <path d='M18.75,9.749 L14.25,9.749' id='Shape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Library
