import React from 'react'

const RepoDisconnected = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.961 13.747L17.848 12.306C18.0422 11.9904 18.0505 11.5945 17.8698 11.2711C17.6891 10.9476 17.3476 10.7471 16.977 10.747H13.5V1.747L10.269 7' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.03896 12.247L6.15196 13.689C5.95765 14.0044 5.94928 14.4003 6.13006 14.7237C6.31084 15.0471 6.65248 15.2473 7.02296 15.247H10.5V24.247L13.731 19' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.25 3.247L21.75 22.747' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>

  )
}

export default RepoDisconnected
