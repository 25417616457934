import styled from 'styled-components/macro'

// Hard pixel setting our sidebar for now
const Sidebar = styled.div`
  display: ${props => !props.isOpen ? 'none' : 'flex'};;
  flex-basis: ${props => !props.isOpen ? '90px' : '769px'};
  // TODO Color-Constant Slush/L1?
  background-color: #dde0e4;
  flex-direction: column;
  @media (min-width:769px) {
    display: flex;
  }

`

export default Sidebar
