import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react'

export const AuthUserContext = createContext()

export const useAuth = () => {
    const context = useContext(AuthUserContext)
    if (!context) {
        throw new Error('Not Allowed!')
    }
    return context
}

const authReducer = (state, params) => {
    // console.log('initial state', state)
    // console.log('incoming params', params)
    switch (params.type) {
        case 'workspace':
            return {
                ...state,
                workspace: params.payload.uuid,
                label: params.payload.label,
                appentityuuid: params.payload.appentityuuid,
                billingUserUuid: params.payload.billingUserUuid
            }
        case 'user':
            return {
                ...state,
                userUuid: params.payload.userUuid
            }
        default:
            return state
    }
}

export const AuthUserProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, {label: null, workspace: null, appentityuuid: null, userUuid: null, billingUserUuid: null})


    const setSelectedWorkspace = useCallback((uuid, label, appentityuuid, billingUserUuid) => {
        const payload = {   
            type: 'workspace', 
            payload: {
                uuid: uuid,
                label: label,
                appentityuuid: appentityuuid,
                billingUserUuid: billingUserUuid

            }
        }
        dispatch(payload)
    })

    const setUserUuid = useCallback((userUuid) => {
        const payload = {
            type: 'user',
            payload: {
                userUuid: userUuid
            }
        }
        dispatch(payload)
    })

    // const value = {state, setSelectedWorkspace}
    const value = useMemo(() => ({ state, setSelectedWorkspace, setUserUuid }), [state.workspace, state.label, state.appentityuuid, state.billingUserUuid])



    return <AuthUserContext.Provider value={value}>{children}</AuthUserContext.Provider>
}

