/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import Loader from '../../core/molecules/Loader'

// Got some styling to do initially
import styled from 'styled-components/macro'

import AppPage from '../../core/pages/appPage'
import AppBaseRow from '../../core/templates/appBaseRow'

// looks contained, needs updates for activity -- only supports Phase
//
import SectionHeader from '../molecules/SectionHeader'

// Activity Status needs some cleanup -- remove the option layer and update the notes for followups
// as we need a showing-dropdown
import ActivityStatus from '../molecules/ActivityStatus'

// think this is a place to look
import ActivityBlock from '../organisms/ActivityBlock'

import BigButton from '../../core/atoms/BigButton'

import { USER_LEARNING_QUERY } from '../../lib/queries/learning'

import * as _ from 'lodash'

import { generateStateMapFromCache, generateDefaultStateMapFromApi } from './utils'
import { progressTasksVar } from '../../cache'

/* AppPage  > AppMainSection
   sets the flexbox into play, any
   inner box needs to now have flex enabled
   or we cheat here and just set a width and no longer use flex...
   if we move back to flex use `display:flex, flex: 1 1 auto;`

   ---

   okay the AppPage has a mainSection that sets up a column flexbox, so now we can position this as our center wrapper

   */
const MainSectionFrame = styled.div`
  background: #F6F5F7;
  display:flex;
  // Let this region expand and grow to fill the parent AppMainSection -- import for Detail
  flex-grow: 1;
  flex-direction: column;
`

const Row = styled.div`
  flex-basis: 100%;
  position: relative;
  max-width: 829px;
  display: flex;
`

const MainHeader = styled.div`
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 20px 0 20px 8px;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    padding-left: 80px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const Content = styled.div`
  padding: 0 1em 0 .5em;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    padding-left: 80px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const SectionHeaderFrame = styled.div`
  flex: 1 0 auto;
`

const ScheduleButtonFrame = styled.div`
  flex: 0 1 auto;
  padding-top:1em;
`

function PhaserView (props) {
  const { loading, error, data } = useQuery(USER_LEARNING_QUERY)
  // TODO: UI WORK -- create a real loader
  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) return `Error!: ${error}`
  // console.log('PhaserView:', data)
  // add in basic error messageing
  if (data.userLearning.phases.length === 0) {
    return (<p>No Phases Scheduled</p>)
  }

  // From our API which is going to for now return all phases, we extract the first for now
  const currentPhase = data.userLearning.phases[0]

  // Check how many activities are in phase and store
  const currentPhaseTotalActivities = currentPhase.activities.length

  const cachedProgress = progressTasksVar()

  // Default current Phase activities completed to 0
  // We will iterate over each activity to see if they are completed (from api)
  // for now we should use the cache/local state instead
  let currentPhaseCompletedActivitiesCount = 0
  /* API iterator
  currentPhase.activities.forEach(activity => {
    if (activity.status === 'completed') {
      currentPhaseCompletedActivitiesCount += 1
    }
  }) */
  // Iterate over our local cache to get a count of how many activities completed
  const completedActivityUuids = []
  cachedProgress.forEach(userActivity => {
    if (userActivity.completed === true) {
      currentPhaseCompletedActivitiesCount += 1
      completedActivityUuids.push(userActivity.userActivityUuid)
    }
  })

  // Time to start munging API & Cache data together, think we do it here first
  // As we will hand these blocks down.  First piece our activity.status flag should carry to cache
  const currentPhaseAvailableActivities = _.filter(currentPhase.activities, activity => {
    // add a check here now for available activities -- check if this activity is in the completedUuid array we buit
    return !completedActivityUuids.includes(activity.uuid) && (activity.status === null || activity.status === 'available' || activity.status === 'active')
  })

  const currentPhaseLockedActivities = _.filter(currentPhase.activities, activity => {
    return activity.status === 'locked'
  })

  const currentPhaseCompletedActivities = _.filter(currentPhase.activities, activity => {
    return completedActivityUuids.includes(activity.uuid)
  })
  // console.log('have completed? ', currentPhaseCompletedActivities)

  // TODO - Next up for when we add Phases as part of CH2668
  const nextPhase = { title: 'Setup Developer Environment' }

  return (
    <AppPage appPanelPadding='0 0 0 0'>
      <MainSectionFrame>
        {/* main header */}
        <MainHeader>
          <Row>
            <SectionHeaderFrame>
              <SectionHeader label='Phase' header={currentPhase.title} />
            </SectionHeaderFrame>
            <ScheduleButtonFrame>
              <BigButton>SCHEDULE</BigButton>
            </ScheduleButtonFrame>
          </Row>
        </MainHeader>

        <Content>

          <Row>
            <ActivityStatus completed={currentPhaseCompletedActivitiesCount} total={currentPhaseTotalActivities} />
          </Row>

          {/* available activities */}
          {currentPhaseAvailableActivities.map((activity, index) => {
            // const progressStateTasks = generateStateMap(activity)
            let progressStateTasks
            // we know from iterator the userActivityUuid, we can should be able to fetch ths `topics`
            // have to account for the situation that by default we don't have a stub if the activity is not in cache
            // cachedProgress exists, do a findBy `uuid`
            const userActivityTasksProgress = cachedProgress.find(record => {
              return (record.userActivityUuid === activity.uuid)
            })
            const userActivityTasksPosition = cachedProgress.findIndex(record => {
              return (record.userActivityUuid === activity.uuid)
            })

            // if null -- means it hasn't been cached yet so stub the UI

            if (!userActivityTasksProgress) {
              progressStateTasks = generateDefaultStateMapFromApi(activity)
            } else {
              progressStateTasks = generateStateMapFromCache(cachedProgress[userActivityTasksPosition])
            }

            return (
              <Row key={'activity-' + Math.random()}>
                <ActivityBlock data={activity} displayDetails taskProgress={progressStateTasks} />
              </Row>
            )
          })}

          {/* locked activities */}
          {currentPhaseLockedActivities.map((activity, index) => (
            <Row key={'activity-' + Math.random()}>
              <ActivityBlock upnext={(index === 0)} data={activity} />
            </Row>
          ))}

          {/* Completed Activities */}

          {currentPhaseCompletedActivities.map((activity, index) => {
            // !IMPORTANT -- going to munie activity.status = completed
            // will need to undo once we square awway more of the cache/loca, but this reduce out code atm

            // const progressStateTasks = generateStateMap(activity)
            let progressStateTasks
            // we know from iterator the userActivityUuid, we can should be able to fetch ths `topics`
            // have to account for the situation that by default we don't have a stub if the activity is not in cache
            // cachedProgress exists, do a findBy `uuid`
            const userActivityTasksProgress = cachedProgress.find(record => {
              return (record.userActivityUuid === activity.uuid)
            })
            const userActivityTasksPosition = cachedProgress.findIndex(record => {
              return (record.userActivityUuid === activity.uuid)
            })

            // Think we don't need this heere, these should be completed already, but as a precaution
            // keep this here so we don't blow up <ActivityBlock />
            if (!userActivityTasksProgress) {
              progressStateTasks = generateDefaultStateMapFromApi(activity)
            } else {
              progressStateTasks = generateStateMapFromCache(cachedProgress[userActivityTasksPosition])
            }

            return (
              <Row key={'activity-' + Math.random()}>
                <ActivityBlock data={activity} taskProgress={progressStateTasks} activityCompleted />
              </Row>
            )
          })}

          {/* next section */}
          <Row>
            <SectionHeader label='Next Phase' header={nextPhase.title} timeEstimate='10-15 Mins' details style='border' />
          </Row>
        </Content>
      </MainSectionFrame>
    </AppPage>
  )
}

export default PhaserView
