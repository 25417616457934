// React Include
import React from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import Loader from '../../../core/molecules/Loader'

// Context
import { useAuth } from '../../../Context/authUserContext'

import { GET_COLLECTIONS_LIST_QUERY, GET_COLLECTIONS_FOR_WORKSPACE } from '../../../lib/queries/doc-ops'

// [core] Atoms
// - icons
// import GitRepositoryIcon from '../../../core/atoms/icons/gitRepository';
// - misc
// import StyledLink from '../../../core/atoms/styledLink'
// [core] Molecules
// import RepositoryBadge from '../../../core/molecules/repositoryBadge'

// [doc-ops]
// Local Molecules
import CollectionListSummary from '../../molecules/CollectionListSummary'
// import TopicGenreHeader from '../../molecules/topicGenreHeader';

// Todo
// if I remove the MainPanel div from LibraryContainer I can use this instead
/* const TopicTypeFull = styled.div`
  width: 100%;
  padding-right: 1em;
`; */

const PageHeader = styled.h2`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 31px;
    color: #343639;
    letter-spacing: -0.21px;
    padding: 0;
    margin: 0;
`
const HeaderMeta = styled.span`
    font-size: 22px;
    margin-left: 2em;
    font-weight: 400;
`

// NOTE: might be able to move this all into <CollectionListSummary />
//  UNLESS - we find it is easier to mount Storybook onto CollectionListSummary if it takes topics
//  as a set of props...
function DiscoveryCollectionList (props) {
  const UserAuthContext = useAuth()
  const workspaceUuid = UserAuthContext.state.workspace

  const { loading, error, data } = useQuery(GET_COLLECTIONS_FOR_WORKSPACE, { variables: {workspaceUuid: workspaceUuid}})
  

  if (loading) return <Loader />
  if (error) return `Error!: ${error}`

  // console.log(data.collections)
  // Marshall our data to send down into our panel
  // collections (raw)
  const collections = data.fetchWorkspaceCollections
  const totalCollections = collections.length

  // Need to get the unique repository count
  // iterate over collections and see how many repos there are (consider putting in api)
  const uniqueRepositoryMap = []
  collections.map((collection) => {
    // If repository isn't in the collection make unique
    if (uniqueRepositoryMap.includes(collection.repository) === false) {
      uniqueRepositoryMap.push(collection.repository)
    }
  })
  const totalRepositories = uniqueRepositoryMap.length

  return (
    <div>
      <PageHeader>Discovery <HeaderMeta>{totalCollections} Collections | {totalRepositories} Repositories</HeaderMeta></PageHeader>
      <CollectionListSummary collectionData={collections} />
    </div>
  )
}

export default DiscoveryCollectionList
