// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

// Colors
import { WHITE, BABEL_BLUE } from '../../../core/colors'

// Atoms
import BigButton from '../../../core/atoms/BigButton'
import { findLastIndex } from 'lodash'

// ApolloCache Loader
import { progressTasksVar } from '../../../cache'

import { handleContentSectionView, presistProgressStateLocalStorage } from '../../../lib/models/ProgressState'

/*
handleContentSectionView(currentState, userActivityUuid, topicUuidTwo, currentSection, totalSections)
  console.log(finalState[0].topics)
*/
const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-size: 1em;
  letter-spacing: .8px;
  line-height: 24px;
  /* identical to box height */
  //letter-spacing: -0.16px;

  font-weight: 700;
  //text-shadow: 0 2px 4px rgba(0,0,0,0.50);
  color: ${WHITE};

  /* blue / BabelingBlue - Base */
  background-color: ${BABEL_BLUE};
  border-radius: 6px;
  width: 5em;
  //max-height: 30px;
  padding: .3em .3em .4em .3em;
  display: block;
  box-shadow: 4px 4px 0px #DDE0E4;
  text-decoration: none;
  text-align: center;
  /* Text gets underline unless we put an override here */
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
`

const TextFrame = styled.div`
  flex: 1 0 auto;
  margin: 0 4px 0 0;
`
const ButtonFrame = styled.div`
 display: flex;
`

// Convert to a `class`

function NextButton (props) {
  // need to bind a click event in here

  /*
   * This click event is going to hold some logic
   * 1. Needs to understand were it will point next `to=`
   * 2. Prior to redirect, needs to update the appropriate services
   * 2.1 - Local Cache - write to local-storage
   * 2.2 - Dispatch to graphqlQL service -- deferred
   *
   */
  function handleClick (event) {
    // console.log('NEXT-BUTTON [click-props]', props)
    // check if we have props for sections
    // if so -- then we are being mounted with a Topic in carousel
    if (props.currentSection & props.totalSections) {
      // [{userActivityUuid}]
      const mutatingProgressTaskVar = progressTasksVar()
      const finalState = handleContentSectionView(mutatingProgressTaskVar, props.userActivityUuid, props.topicUuid, props.currentSection, props.totalSections)
      // console.log('finalState-->', finalState[0].topics)

      // update the reactive-variable with our new/mutated state
      progressTasksVar(finalState)

      // use our wrapper to write our reactive-variable to local-storage
      presistProgressStateLocalStorage(progressTasksVar())
    }
    // lets start to write values now
  }

  const TEXT = 'NEXT >'
  return (
    <ButtonFrame onClick={handleClick}>
      <StyledLink to={props.to}>

        {TEXT}
      </StyledLink>
    </ButtonFrame>

  )
}

NextButton.propTypes = {
  to: PropTypes.string,
  totalSections: PropTypes.number,
  currentSection: PropTypes.number,
  topicUuid: PropTypes.string,
  userActivityUuid: PropTypes.string
}

export default NextButton
