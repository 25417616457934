
// Sections
export const DOC_OPS_GENRE_REFERENCE = 'reference'
export const DOC_OPS_GENRE_DISCUSSION = 'discussion'
export const DOC_OPS_GENRE_HOWTO = 'howto'

export const DOC_OPS_TOPIC_ADR = 'adr'
export const DOC_OPS_TOPIC_CHANGELOG = 'changelog'
export const DOC_OPS_TOPIC_FAQ = 'faq'
export const DOC_OPS_TOPIC_GUIDE = 'guide'
export const DOC_OPS_TOPIC_README = 'readme'
export const DOC_OPS_TOPIC_REFERENCE = 'reference'
export const DOC_OPS_TOPIC_RFC = 'rfc'
export const DOC_OPS_TOPIC_RUNBOOK = 'runbook'
export const DOC_OPS_TOPIC_UNCATEGORIZED = 'uncategorized'

/// /////
// Glyph References
// Used To Build Icons
/// /////////
// Genre
export const DOC_OPS_GLYPH_GENRE_REFERENCE = 'genrereference'
export const DOC_OPS_GLYPH_GENRE_DISCUSSION = 'genrediscussion'
export const DOC_OPS_GLYPH_GENRE_HOWTO = 'genrehowto'
// Topic Categories
export const DOC_OPS_GLYPH_TOPIC_ADR = 'topicadr'
export const DOC_OPS_GLYPH_TOPIC_CHANGELOG = 'topicchangelog'
export const DOC_OPS_GLYPH_TOPIC_FAQ = 'topicfaq'
export const DOC_OPS_GLYPH_TOPIC_GUIDE = 'topicguide'
export const DOC_OPS_GLYPH_TOPIC_POLICY = 'topicpolicy'
export const DOC_OPS_GLYPH_TOPIC_README = 'topicreadme'
export const DOC_OPS_GLYPH_TOPIC_REFERENCE = 'topicreference'
export const DOC_OPS_GLYPH_TOPIC_RFC = 'topicrfc'
export const DOC_OPS_GLYPH_TOPIC_RUNBOOK = 'topicrunbook'
export const DOC_OPS_GLYPH_TOPIC_UNCATEGORIZED = 'topicuncategorized'

// This still used?
export const COLLECTION_DATA_FIXTURE = [
  { uuid: 'fe4', collectionTitle: 'API Deployment Procedures', description: 'How to deploy and provision RDS volumes for GraphQL API server onto AWS', assets: [{ type: 'runbook', count: 5 }, { type: 'guide', count: 9 }] },
  { uuid: 'yolo1', collectionTitle: 'Key Architecture Decisions', description: 'Collection of the most important key ADRs that outline the history and context of decisions', assets: [{ type: 'adr', count: 21 }] },
  { uuid: 'bke2', collectionTitle: 'Developer Onboarding', description: 'Getting a new hire Engineer update to speed on Developer Platform', assets: [{ type: 'adr', count: 16 }, { type: 'reference', count: 13 }, { type: 'guide', count: 9 }] },
  { uuid: 'bke4', collectionTitle: 'Securing the Network', description: 'Security is a priority for the platform, this is all you need.', assets: [{ type: 'reference', count: 7 }, { type: 'guide', count: 3 }] }
]

// Storybook Configuration Section
export const STORYBOOK_SECTION = 'doc-ops'

// TODO move this into the actual *.Stories.* for this component
// Used in Storybook for <TopicTableContents>
export const TOPIC_TABLE_CONTENTS_FIXTURE = [
  { sectionTitle: 'Edgar DAO Overview', type: 'h2', children: [] },
  { sectionTitle: 'Knex Usage', type: 'h2', children: [] },
  {
    sectionTitle: 'Knex CLI',
    type: 'h3',
    children: [
      {
        sectionTitle: 'Creating & Running Migrations',
        type: 'h3',
        children: [
          {
            sectionTitle: 'Creating Migrations',
            type: 'h4',
            children: [
              { sectionTitle: 'Setting Camel Case', type: 'h5', children: [] },
              { sectionTitle: 'Setting JSON_B Types', type: 'h5', children: [] }
            ]
          },
          { sectionTitle: 'Running Migrations', type: 'h4', children: [] }]
      }]
  },
  { sectionTitle: 'ObjectionJS', type: 'h2', children: [] },
  { sectionTitle: 'Test', type: 'h2', children: [] }
]
