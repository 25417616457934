// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
// Local / Core Atoms
import Header from '../../../bonvoy/atoms/TopicSectionHeader'
import Label from '../../../bonvoy/atoms/label'
import RepositoryBadge from '../../../core/molecules/RepositoryBadge'
import TopicCategoryBadge from '../../../core/molecules/TopicCategoryBadge'

const StepHeaderFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
`

const HeaderFrame = styled.div`
  flex-basis: 100%;
`

const MetaFrame = styled.div`
  flex-basis: 20%;
  padding: 0 0 0 40px;
`

function StepHeader (props) {
  return (
    <StepHeaderFrame>
      <HeaderFrame>
        <Header>{props.title}</Header>
      </HeaderFrame>
      {props.type
        ? <MetaFrame>
         <Label>Type</Label>
         <TopicCategoryBadge category={props.type} />
                </MetaFrame>
        : ''}
      {props.type
        ? <MetaFrame>
         <Label>Repository</Label>
         <RepositoryBadge>{props.repository}</RepositoryBadge>
                </MetaFrame>
        : ''}
    </StepHeaderFrame>
  )
}

StepHeader.propTypes = {

}

export default StepHeader
