// Need React for our functional component
import React from 'react'
// Create Necessary presentation components wrappers
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// Imports from CORE
import RepositoryBadge from '../../core/molecules/RepositoryBadge/RepositoryBadge'
// TODO Make a Molecule
import GitBranchIcon from '../../core/atoms/glyphs/doc-ops/repository/gitBranch'

// Import Local Doc-ops
import StatusLabel from '../atoms/statusLabel'

const SummaryHeader = styled.h3`
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.1em;
    color: #424447;
    letter-spacing: -0.09px;
    margin: 2em 0 .5em 0;
    padding: 0;
`

const SummaryList = styled.ul`
    list-style:none;
    margin: 0;
    padding: 0;
`

const SummaryListItem = styled.li`
    list-style:none;
    margin: 0;
    padding: 0 0 .2em 0;
`

const BranchLabel = styled.span`
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: .9em;
    color: #343639;
    letter-spacing: -0.09px;
    position:relative;
    top:-4px;
    padding-left:.4em;
`

const PRLabel = styled.span`
    text-decoration: underline;
`

function TopicGitSummary (props) {
  // console.log('Topic GitSummary (props): ',props);

  // check our branch (ref)
  // if could have a few shapes (refs/heads/master) | (refs/heads/<branch-name>)
  // could also be `refs/tags/2.2` ...
  // Right now we will only support branch-names (master/features)
  // When ready be prepared to show a GitTagIcon if the ref is a tag
  let refLabel = 'N/A'
  if (props.branch.startsWith('refs/heads/')) {
    // fortunately, `refs/heads/` should never change
    refLabel = props.branch.slice(11)
  }
  return (
    <div>
      <SummaryHeader>Repository Connection</SummaryHeader>
      <SummaryList>
        <SummaryListItem><RepositoryBadge>{props.repository}</RepositoryBadge></SummaryListItem>
        <SummaryListItem><GitBranchIcon /><BranchLabel>{refLabel}</BranchLabel></SummaryListItem>
        {/*
        <SummaryListItem><StatusLabel>{props.status.toUpperCase()}</StatusLabel> #<PRLabel>{props.pr}</PRLabel></SummaryListItem>
        */}
      </SummaryList>

    </div>
  )
}

TopicGitSummary.propTypes = {
  repository: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pr: PropTypes.string.isRequired
}

export default TopicGitSummary
