
// This is a // React Include
import React from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// [core] Atoms
import StyledLink from '../../core/atoms/styledLink'

// [doc-ops] LOCAL
import TopicSummaryBadge from './topicSummaryBadge'
import TopicCategoryHeader from './topicCategoryHeader'
import DisplayTopicTitle from '../atoms/displayTopicTitle'

// This was a port of topicCategoryListSummary
// But includes some specific changes
// This version will need to display the TopicCategoryBadge in the SummaryMeta, so need to determine
// How that will happen within the confines of the components
// Do a cleanup of all this shit here as well

/*
 * Styled Component Vomit, these should get shuffled into an ATOM(s) but not making this a requirement
 * until anyone of these ATOMS are required on their own.. for now they can live here
 *
 * Also, want to evaluate `StoryBook` and see how it may fit into our overall component architecture
 */

// Frame creates our initial flexbox pattern for a series of rows
// the internal blocks are SummaryBody and SummaryHeader, which Header is simple, but Body is column based
// Put a flex-basis on here so I can get a 2 column-layout going (these require another div wrapper around them)
// that does a flex-wrap + justify-content to get these to go to columns, could go three columns if necessary
const TopicSummaryFrame = styled.div`
  display: flex;
  flex-direction: row;
  // shutdown left padding
  padding: .5em 0 .5em 0em;
  flex-basis: 48%;
  // set a min-width on these so in a mobile/response view they keep some shape
  // otherwise the cat/repo/section badge collapses and blows up
  min-width: 320px;
  // debug border
  // border:1px dotted #fc03f0;
`

const TopicSummaryBody = styled.div`
  display: flex;
  flex-direction: column;
  // shutdown left padding
  padding: 0 0 1em 0em;
`

const TopicSummaryHeader = styled.div`
  display: flex;
  font-weight: bold;
  padding-bottom:.3em;
`
// Working name for an Organism/Molecule that wraps up a general header
function TopicDiverseListSummary (props) {
  // console.log('ViewProps [TopicDiverseListSummary]: ', props)
  /*
    *  Create our Summary Blocks, something funky happening with uuid and data coming-in, added a crazy unique random-number
    *  to prevent the overflow of errors in console, once fix we can reduce this
    */
  const topicBlocks = props.docs.map(topic => {
  // console.log(topic)
    const topicUrl = `/topic/${topic.repository}/${topic.urlSlug}`
    return (
      <TopicSummaryFrame key={topic.uuid + Math.floor((Math.random() * 10000) + 1)}>
        <TopicSummaryBody>
          <TopicSummaryHeader>
            <StyledLink to={topicUrl}><DisplayTopicTitle topic={topic} /></StyledLink>
          </TopicSummaryHeader>
          <TopicSummaryBadge category={topic.type} repository={topic.repository} sections={topic.sections} />
          {/* Would place the Toggle Section Displayer here */}
        </TopicSummaryBody>
      </TopicSummaryFrame>
    )
  })

  return (
    <div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', paddingTop: '.5em' }}>
        {/* TODO Foor-Loop here to render each of these */}
        {topicBlocks}
      </div>
    </div>)
}

TopicDiverseListSummary.propTypes = {
  docs: PropTypes.array.isRequired
}

export default TopicDiverseListSummary
