/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// Got some styling to do initially
import styled from 'styled-components/macro'

import AppPage from '../../core/pages/appPage'

// Local / Core Atoms
import SectionHeader from '../molecules/SectionHeader'

/* AppPage  > AppMainSection
   sets the flexbox into play, any
   inner box needs to now have flex enabled
   or we cheat here and just set a width and no longer use flex...
   if we move back to flex use `display:flex, flex: 1 1 auto;`

   ---

   okay the AppPage has a mainSection that sets up a column flexbox, so now we can position this as our center wrapper

   */
const MainSectionFrame = styled.div`
    background: #F6F5F7;
    display:flex;
    // Let this region expand and grow to fill the parent AppMainSection -- import for Detail
    flex-grow: 1;
    flex-direction: column;
`

const Row = styled.div`
  flex-basis: 100%;
  position: relative;
  max-width: 829px;
  display: flex;
`

const SectionHeaderFrame = styled.div`
  flex: 1 0 auto;
`

const MainHeader = styled.div`
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 20px 0 20px 8px;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    padding-left: 80px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const Content = styled.div`
  padding: 0 1em 0 .5em;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    padding-left: 80px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const DisplaySectionHeader = (args) => {
  return (<SectionHeader {...args} />)
}

function SherpaView (props) {
  // console.log('SherpaView ->', props)

  // for Sherpa content, need to know if we are in intro mode of card mode...
  // and that will change the header text and label system
  // TODO still here need to maybe build the SectionHEader to send down now since the label needs replaced...
  // should send in the data for back-buttons...
  // Two SectionHeaders, one that is static and just Headers/Label.... summary info
  // The Other SectionHeader with breadcrumbs
  const breadCrumbLinks = {
    // the Phase breadcrumb should redirect back to Phaser/Onboarding Entry (show current phase)
    phase: { label: props.currentPhase.title, to: '/onboarding' },
    // currentActivity as the tile, props.backto.url is our IntroCard link for activity
    // not a fan of the name
    activity: { label: props.currentActivity.title, to: props.backto.url }
  }
  let headerText = props.children.props.data.title
  let sectionHeaderArgs
  // Intro Card is landing
  if (props.type === 'landing') {
    // lets put Phase back into the header for landing
    headerText = props.currentPhase.title
    sectionHeaderArgs = { header: headerText, label: 'Phase' }
  } else {
    sectionHeaderArgs = { header: headerText, breadCrumbLinks: breadCrumbLinks }
  }
  return (
    <AppPage appPanelPadding='0 0 0 0'>
      <MainSectionFrame>
        {/* main header */}
        <MainHeader>
          <Row>
            <SectionHeaderFrame>
              <SectionHeader {...sectionHeaderArgs} />
            </SectionHeaderFrame>
          </Row>
        </MainHeader>

        <Content>

          {/* body */}
          <Row>
            {props.children}
          </Row>

          {/* next section */}
          {props.nextActivity
            ? <Row>

              <SectionHeader label={props.type === 'landing' ? 'Next Activity' : 'Next Task'} header={props.nextActivity.title} timeEstimate='10-15 Mins' details style='border' />
            </Row>
            : ''}

        </Content>
      </MainSectionFrame>
    </AppPage>
)
}

export default SherpaView
