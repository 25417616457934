// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
// import ActivityIcon from '../../atoms/glyphs/activity'
import Text from '../../atoms/text'
// import TopicIcon from '../../atoms/glyphs/topic'
// import QuestionIcon from '../../atoms/glyphs/question'
// DELETE LEarning
// import LearningIcon from '../../../core/atoms/glyphs/sherpa/learning'
import ContentTaskIcon from '../../../core/atoms/glyphs/sherpa/contentTask'
import ContentTaskCompletedIcon from '../../../core/atoms/glyphs/sherpa/contentTaskCompleted'
// import WorkflowIcon from '../../../core/atoms/glyphs/sherpa/workflow'

/*
 * FrameMount can expect an override on the border
 */
const ActivityListItemFrameMount = styled.div`
  display: flex;
  flex-direction: row;
`

const IconFrame = styled.div`
  margin: 0 5px 0 0;
`

const TextFrame = styled.div`

`

/*
 * View Story for followup information
 */
function LearningTaskListItem (props) {
  let IconComponent
  if (props.completed) {
    IconComponent = (<ContentTaskCompletedIcon size='medium' />)
  } else if (props.active) {
    IconComponent = (<ContentTaskIcon size='medium' active />)
  } else {
    IconComponent = (<ContentTaskIcon size='medium' active={false} />)
  }

  switch (props.type) {
    case 'reference':
    default:
      return (
        <ActivityListItemFrameMount>
          <IconFrame>
            {IconComponent}
          </IconFrame>
          <TextFrame>
            <Text>{props.title}</Text>
          </TextFrame>
        </ActivityListItemFrameMount>
      )
  }
}

LearningTaskListItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired

}

export default LearningTaskListItem
