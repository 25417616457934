
import React from 'react'
/*
 *  The Topic Adr Glyph
 */
const TopicAdrGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/adr/standard24' stroke={strokeColor} strokeWidth='1.5'>

            <path d='M3.75,16.5 L3.75,12.75 C3.75,11.9215729 4.42157288,11.25 5.25,11.25 L18.75,11.25 C19.5784271,11.25 20.25,11.9215729 20.25,12.75 L20.25,16.5' id='Shape' />
            <path d='M9,23.25 L9,19.5 C9,17.8431458 10.3431458,16.5 12,16.5 C13.6568542,16.5 15,17.8431458 15,19.5 L15,23.25' id='Shape' />
            <path d='M9,19.5 L1.5,19.5 C1.08578644,19.5 0.75,19.8357864 0.75,20.25 L0.75,22.5 C0.75,22.9142136 1.08578644,23.25 1.5,23.25 L9,23.25' id='Shape' />
            <path d='M15,19.5 L22.5,19.5 C22.9142136,19.5 23.25,19.8357864 23.25,20.25 L23.25,22.5 C23.25,22.9142136 22.9142136,23.25 22.5,23.25 L15,23.25' id='Shape' />
            <path d='M8.25,6.75 L8.25,11.25' id='Shape' />
            <path d='M15.75,6.75 L15.75,11.25' id='Shape' />
            <path d='M7,5.439 C6.76668571,5.64601647 6.68585473,5.97582744 6.79700617,6.26726652 C6.90815761,6.5587056 7.18808572,6.7509273 7.5,6.75 L16.5,6.75 C16.8119143,6.7509273 17.0918424,6.5587056 17.2029938,6.26726652 C17.3141453,5.97582744 17.2333143,5.64601647 17,5.439 L12,0.75 L7,5.439 Z' id='Shape' />

          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicAdrGlyph
