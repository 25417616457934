
import React from 'react'
/*
 *  The Topic Changelog Glyph
 */
const TopicChangelogGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/changelog/standard24' stroke={strokeColor} strokeWidth='1.5'>
            <path d='M14.25,23.25 C15.9068542,23.25 17.25,21.9068542 17.25,20.25 L17.25,3.75 C17.25,2.09314575 18.5931458,0.75 20.25,0.75 C21.9068542,0.75 23.25,2.09314575 23.25,3.75 L23.25,6.75 L17.25,6.75' id='Shape' />
            <path d='M6.75,17.25 L6.75,3.75 C6.75,2.09314575 8.09314575,0.75 9.75,0.75 L20.25,0.75' id='Shape' />
            <path d='M3.75,17.25 C2.09314575,17.25 0.75,18.5931458 0.75,20.25 C0.75,21.9068542 2.09314575,23.25 3.75,23.25 L14.25,23.25 C12.5931458,23.25 11.25,21.9068542 11.25,20.25 C11.25,18.5931458 12.5931458,17.25 14.25,17.25 L3.75,17.25 Z' id='Shape' />
            <path d='M9.75,5.25 L14.25,5.25' id='Shape' />
            <path d='M9.75,8.25 L14.25,8.25' id='Shape' />
            <path d='M9.75,11.25 L14.25,11.25' id='Shape' />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicChangelogGlyph
