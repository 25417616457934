import React from 'react'
/*
 * ONBOARDING ICON
 *
 */
const Onboarding = (props) => {
    return (
        <div style={{}}>
            <svg width='26px' height='26px' viewBox='0 0 26 26' version='1.1'>
                <g id='Symbols' stroke='none' strokeWidth='1.5' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                    <g id='ui/organisms/nav/vertical/v2/library-selected' transform='translate(-32.000000, -167.000000)' stroke='#343639'>
                        <g id='onboarding' transform='translate(29.000000, 165.000000)'>
                            <g id='icon/onboarding' transform='translate(3.000000, 2.000000)'>
                                <g id='business-climb-top'>
                                    <path d='M5.8 21.8H2.6C1.71634 21.8 1 21.0837 1 20.2V2.6C1 1.71634 1.71634 1 2.6 1H13.9376C14.3617 1.00009 14.7683 1.16851 15.0683 1.46827L18.1317 4.53173C18.4315 4.83168 18.5999 5.23835 18.6 5.6624V10.6' />
                                    <path d='M21.8 18.9551V23.8447L17.8 24.9999L13.8 23.8447V18.9551' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M25 17.9599L17.8 15.3359L10.6 17.9599L17.8 20.1999L25 17.9599V17.9599Z' />
                                    <path d='M10.6 17.96V21.5333' />
                                    <path d='M4.19995 5H5.79995' />
                                    <path d='M9 5H13.8' />
                                    <path d='M4.19995 9H5.79995' />
                                    <path d='M9 9H13.8' />
                                    <path d='M4.19995 13H5.79995' />
                                    <path d='M4.19995 17H5.79995' />
                                    <path d='M9 13H13.8' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Onboarding
