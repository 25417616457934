import React from 'react'

const HamburgerMenu = (props) => {
  return (

    <div onClick={props.onHandleClick}>
      <svg width='36' height='36' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12 23.253C18.2132 23.253 23.25 18.2162 23.25 12.003C23.25 5.7898 18.2132 0.753006 12 0.753006C5.7868 0.753006 0.75 5.7898 0.75 12.003C0.75 18.2162 5.7868 23.253 12 23.253Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.5 8.25301H16.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.5 12.003H16.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.5 15.753H16.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  )
}

export default HamburgerMenu
