
import React from 'react'

const arrowRight = () => (
    <div style={{}}>
        <svg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2.10522 12.9309L7.8947 7.49988L2.10522 2.06885' stroke='#DDE0E4' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    </div>
)

export default arrowRight
