// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types';

// Got some styling to do initially
import styled from 'styled-components/macro'

// Create a bunch of ATOMS in here for now
/* building this as a series of divs using flex */
// this will be the container that will take some JSON shape and handle the displaying
const TableContents = styled.div`
  display: flex;
  flex-direction: column;
`

// something weird going on with height, had to max-height cap it, else the border stretched
const TableContentH2Section = styled.div`
  border-left: 6px solid #D8D8D8;
  padding: 1px 0 1px 10px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #0D0E11;
  letter-spacing: -0.09px;
  font-size: 1rem;
`

const TableContentH3Section = styled.div`
  border-left: 6px solid #D8D8D8;
  padding: 1px 0 1px 20px;
  font-family: 'Open Sans';
  color: #0D0E11;
  letter-spacing: -0.09px;
  font-size: 1rem;
`

const TableContentH4Section = styled.div`
  border-left: 6px solid #D8D8D8;
  padding: 1px 0 1px 30px;
  font-family: 'Open Sans';
  color: #0D0E11;
  letter-spacing: -0.09px;
  font-size: 1rem;
`

const TableContentH5Section = styled.div`
  border-left: 6px solid #D8D8D8;
  padding: 1px 0 1px 40px;
  color: #989898;
  letter-spacing: -0.09px;
  font-size: 1rem;
  font-family: 'Open Sans';
  font-style: italic;
`

class TopicTableContents extends Component {
  render () {
    // Brutal.... Could be written using recursion, but wait till the remaining scrolling interaction
    // and open/close behavior is added
    // need to iterate over the tableContentShape and create the react components
    const headerSections = this.props.toc.map((section) => {
      let sectionChildren = []
      // console.log(section);
      const topSectionKey = section.sectionTitle + '-' + section.type

      // Check if the topH2 section has no children, mount return it for rendering
      if (section.children.length === 0) {
        return <TableContentH2Section key={topSectionKey}>{section.sectionTitle}</TableContentH2Section>
        // ELSE we will map through the children and traverse down the child-relationships
      } else {
        // similar should check if our h3 level has children in the h4/h5 layers

        sectionChildren = section.children.map((sectionChild) => {
          // make our key
          const sectionChildKey = sectionChild.sectionTitle + '-' + sectionChild.type
          // create a stub to hold all our grandChildren headers(h4)
          let sectionGrandChildren = ''

          // console.log('sectionChild: ',sectionChild)
          // check if our sectionChildren have children(s), if not just return the tableh3 sectionChild
          if (sectionChild.children.length === 0) {
            return (<TableContentH3Section key={sectionChildKey}>{sectionChild.sectionTitle}</TableContentH3Section>)
          } else {
            // if this one has children, continue the nesting traversal
            sectionGrandChildren = sectionChild.children.map((grandChild) => {
              // have one more level inside of here....
              const sectionGrandChildKey = grandChild.sectionTitle + '-' + grandChild.type
              // Hold our greatGrandChildren headers (h5)
              let greatGrandChildren = ''
              // console.log('grandChild: ',grandChild)
              if (grandChild.length === 0) {
                return (<TableContentH4Section key={sectionGrandChildKey}>{grandChild.sectionTitle}</TableContentH4Section>)
              } else {
                greatGrandChildren = grandChild.children.map((greatGrandChild) => {
                  const greatGrandChildKey = greatGrandChild.sectionTitle + '-' + greatGrandChild.type
                  console.log('here? ', greatGrandChildKey)
                  return (<TableContentH5Section key={greatGrandChildKey}>{greatGrandChild.sectionTitle}</TableContentH5Section>)
                })
                return (
                  <div key={sectionGrandChildKey}>
                    <TableContentH4Section>{grandChild.sectionTitle}</TableContentH4Section>
                    {greatGrandChildren}
                  </div>
                )
              }
            })
            return (
              <div key={sectionChildKey}>
                <TableContentH3Section key={sectionChildKey}>{sectionChild.sectionTitle}</TableContentH3Section>
                {sectionGrandChildren}
              </div>
            )
          }
        })
        return (
          <div key={topSectionKey}>
            <TableContentH2Section>{section.sectionTitle}</TableContentH2Section>
            {sectionChildren}
          </div>
        )
      }
    })
    return (
      <TableContents>
        {headerSections}
      </TableContents>
    )
  }
}

TopicTableContents.propTypes = {
  toc: PropTypes.array.isRequired
}

export default TopicTableContents
