// These functions need to be used to power the `LearningTaskList`
// Which depending if the cache is empty, we should try and generate a default progressState for ui components....
// Love to do this another way, but since the pre-fill data comes from API gets abit challenging...
// and once we have bonvoy-engine working and we have state from scheduling this all gets handled by default
// So the functions here are just that -- temporary

import { getUrlSlug } from '../../lib/utils'

// NOTE: there is one piece here to remember -- the cache has our data -- but it is in [{...data..}] and ultimately what we want
//       is a object shape with topicUuids as the keys {'<topicUUid>': {active, completed}, ...}
/*
 * From the `cache` we've selected the Activity we are focused on
 * and pass in the state -- will have `data.topics` which we can generate the stateMap we want
 */
export const generateStateMapFromCache = (data) => {
  const stateMap = {}

  const topics = data.topics

  let ctr = 0
  topics.forEach(topic => {
    // set first topic to active
    const isActive = (ctr === 0)

    stateMap[topic.uuid] = {
      active: topic.active,
      completed: topic.completed
    }
    ctr++
  })

  return stateMap
}

/*
 * Version of the stateMap, used when cache is empty, and we need to have a version powered by the API
 * Really just want to find the first Topic in the sections and put as active...
 */
export const generateDefaultStateMapFromApi = (data) => {
  const stateMap = {}

  // This is the HACK
  const topics = data.tasks[0].sections[0].topics

  let ctr = 0
  topics.forEach(topic => {
    // set first topic to active
    const isActive = (ctr === 0)

    stateMap[topic.uuid] = {
      active: isActive,
      completed: false
    }
    ctr++
  })

  return stateMap
}

// rename to buildPreviousNextCardUrls
/*
 *  Based on several pieces of data we need to build the previous/next card urls while the user
 *  is in the CardStepper.  Should not be part of the GraphQL endpoint as this is front-end only at this point
 *
 *  Next/PreviousURL should match this route pattern
 *  <PrivateRoute path='/learning/:phaseId/:activityId/:taskId/:sectionId?' component={SherpaContentView} />
 *
 *  @selectedSectionId is the current section-position the CardStepper is at in an Activity
 *  @currentTopicSections is an array of all the sections for the current Activity/Topic
 *  @prevTopic is an obj with an attribute `sections:array` to know how many sections it has..
 *  @nextTopic is an obj with an attribute `sections:array` to know how many sections it has..
 */
export const buildPreviousNextUrl = (phaseId, activityId, selectedSectionId, currentTopic, prevTopic, nextTopic) => {
  let previousURL = null
  let nextURL = null
  // console.log(selectedSectionId, ' -vs- ', currentTopic)
  // console.log('PrevTopic:', prevTopic)

  // There are times one may have a single TopicTask, best to just handle this edge case as-is since it is self-contained
  if (prevTopic === null && nextTopic === null) {
    // if section is not equal to section.lenth we should iterate nextUrl
    //  and if section is not 1 we can set previousUrl to section-1
    // elseIF section is equal to length - nextUrl should be completed, and previousUrl can be section-1
    if ((selectedSectionId < currentTopic.sections.length)) {
      // First time we come in here as the first section we don't want to set PreviousURl, but subsequent cycles we can
      if (parseInt(selectedSectionId) !== 1) {
        previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${(selectedSectionId - 1)}`
      }
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${parseInt(selectedSectionId) + 1}`
    } else if (selectedSectionId === currentTopic.sections.length) {
      previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${(selectedSectionId - 1)}`
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/complete`
    }

    // Return back the object shape
    return { previousURL, nextURL }
  }

  //
  // Now for the cases when we have different scenarios of (PrevTopic -vs- NextTopic)
  // The simple approach is to build one at a time

  // goal is set previousUrl first
  if (prevTopic) {
    // Handle the edge case we are viewing card-1 of current topic, and the prevTopic has only one section/length(1)
    if (parseInt(selectedSectionId) === 1) {
      // if the previous Topic only has a single card, (lenght=1 we use that, if it is greater then decrement)
      if (prevTopic.sections.length === 1) {
        previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/1`
      } else {
        previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${(prevTopic.sections.length)}`
      }
    // If we aren't the first card, then our previousUrl should just be the decrement of our current selection
    } else {
      previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${parseInt(selectedSectionId) - 1}`
    }
  } else {
    // Working out this case here... when we don't have a prevTopic, it shouldn't be the case we made it this far in the logic and
    // we don't have a NextTopic, the assumption is we are viewing the firstTopicTask in the activity and there is no PrevTopic
    // so in this case if we are viewing card-1 we should set previousUrl to null, otherwise decrement currentTopic
    if (parseInt(selectedSectionId) === 1) {
      // defaults to null, but helpful to explicitly set it for edification
      previousURL = null
    // If we aren't the first card, then our previousUrl should just be the decrement of our current selection
    } else {
      previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${parseInt(selectedSectionId) - 1}`
    }
  }

  // Now we set the NextUrl
  if (nextTopic) {
    // if we have a next topic we are concerned whether we our at the end of the current topic or not
    // If we are not at the end then we simply increment our nextUrl within CurrentTopic
    // Else, we are going to the firstCard of the next topic
    if ((selectedSectionId < currentTopic.sections.length)) {
      // increment
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${parseInt(selectedSectionId) + 1}`
    } else {
      // card one next topic
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(nextTopic.title.toLowerCase())}/1`
    }
  // If no nextTopic, means we are at the last Topic/Task in an activity
  } else {
    // if our viewing section matches the length of sections, we have completed the activity
    if (parseInt(selectedSectionId) === currentTopic.sections.length) {
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/complete`
    } else {
      // ELSE we should increment the viewing section for currentTopic
      nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(currentTopic.title.toLowerCase())}/${parseInt(selectedSectionId) + 1}`
    }
  }

  return { previousURL, nextURL }
}
