// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

// TODO
// activities.uuid comes to us userActivityUuid, we should be explicit about this in here it is important to know
// and topics.uuid is the actual topicUuid being mounted

export const ACTIVITY_AND_PHASES_QUERY = gql`
  query activity_and_phases_Query {
    userLearning {
        phases {
            uuid,
            title,
            activities {
                uuid,
                title,
                description,
                tasks {
                    type
                    ... on ContentTask{
                        description,
                        sections {
                            header,
                            topics {
                            uuid,
                            title,
                            owner,
                            filepath,
                            filename,
                            ref,
                            repository,
                            type, 
                                sections {
                                    order,
                                    blocks {
                                        type,
                                        depth,
                                        value,
                                        lang,
                                        meta,
                                        children{ 
                                            type, 
                                            value, 
                                            url,
                                            children {
                                                type, 
                                                value, 
                                                url,
                                                children {
                                                    type, 
                                                    value, 
                                                    url,
                                                    children {
                                                        type, 
                                                        value, 
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
`
