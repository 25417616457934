
import React from 'react'
import { SPACE_GREY, BLUE_500, GREEN_500 } from '../../../colors'

function Component (props) {
  let color = SPACE_GREY
  // console.log('LEarnings icon->', props)
  switch (props.state) {
    case 'done':
      switch (props.size) {
        case 'medium':
          return <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 23.251C11.472 21.588 8.05703 19.651 2.15703 19.385C1.36242 19.3356 0.744787 18.6741 0.750033 17.878V4.52699C0.748971 4.10991 0.921608 3.71122 1.2265 3.42663C1.5314 3.14204 1.94102 2.99724 2.35703 3.02699C6.22203 3.22699 8.96503 4.15199 10.514 5.23199' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M12 23.251C12.528 21.588 15.943 19.651 21.843 19.385C22.6376 19.3357 23.2552 18.6741 23.25 17.878V9' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M9.34207 8.77299C7.5414 8.12282 5.67057 7.6865 3.76807 7.47299' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M9.34207 12.588C7.53992 11.9428 5.66968 11.5067 3.76807 11.288' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M9.34204 16.632C7.52292 15.985 5.63558 15.5488 3.71704 15.332' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M12 10.501V23.251' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path fillRule='evenodd' clipRule='evenodd' d='M17 11C19.7614 11 22 8.98528 22 6.5C22 4.01472 19.7614 2 17 2C14.2386 2 12 4.01472 12 6.5C12 8.98528 14.2386 11 17 11Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M20 5L17.3763 7.78451C17.2582 7.90937 17.078 7.98745 16.8822 7.99862C16.6863 8.00978 16.494 7.95294 16.3548 7.84275L15 6.76431' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
                 </svg>

        case 'small':
          return <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.87552 19.2509C9.44723 17.902 6.67713 16.3308 1.89132 16.115C1.24677 16.075 0.745772 15.5384 0.750027 14.8926V4.06288C0.749165 3.72457 0.8892 3.40117 1.13652 3.17032C1.38383 2.93947 1.7161 2.82202 2.05355 2.84615C5.18866 3.00838 7.41366 3.7587 8.67014 4.63475' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M9.87549 19.251C10.3038 17.902 13.0739 16.3308 17.8597 16.1151C18.5042 16.075 19.0052 15.5384 19.001 14.8927V7.69122' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.7195 7.50708C6.25888 6.97969 4.74135 6.62576 3.19812 6.45258' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.7195 10.6016C6.25768 10.0783 4.74062 9.72449 3.19812 9.54712' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.71948 13.8819C6.24389 13.3571 4.71297 13.0033 3.15674 12.8274' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M9.87549 8.90878V19.251' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path fillRule='evenodd' clipRule='evenodd' d='M14.1833 9C16.3925 9 18.1833 7.433 18.1833 5.5C18.1833 3.567 16.3925 2 14.1833 2C11.9742 2 10.1833 3.567 10.1833 5.5C10.1833 7.433 11.9742 9 14.1833 9Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M16.1833 4L14.0844 6.78451C13.9899 6.90937 13.8457 6.98745 13.6891 6.99862C13.5324 7.00978 13.3785 6.95294 13.2672 6.84275L12.1833 5.76431' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
                 </svg>

        case 'xsmall':
        case 'tiny':
        default:
          return <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.90039 14.6674C7.55776 13.6396 5.34168 12.4425 1.51303 12.2781C0.997391 12.2476 0.596593 11.8388 0.599997 11.3468V3.09554C0.599308 2.83777 0.711336 2.59137 0.909189 2.41549C1.10704 2.2396 1.37285 2.15011 1.64282 2.1685C4.15091 2.29211 5.9309 2.86378 6.93609 3.53124' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.90039 14.6675C8.24302 13.6397 10.4591 12.4426 14.2877 12.2782C14.8034 12.2477 15.2042 11.8388 15.2008 11.3468V5.86002' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6.17557 5.71969C5.00708 5.31787 3.79305 5.04821 2.55847 4.91626' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6.17557 8.07745C5.00612 7.67873 3.79247 7.40916 2.55847 7.27402' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6.17559 10.5767C4.99511 10.1769 3.77038 9.90729 2.52539 9.77328' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.90039 6.78763V14.6674' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            <path fillRule='evenodd' clipRule='evenodd' d='M11 6C12.6569 6 14 4.88071 14 3.5C14 2.11929 12.6569 1 11 1C9.34315 1 8 2.11929 8 3.5C8 4.88071 9.34315 6 11 6Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M12 2.5L11 4C10.9528 4.04162 10.8807 4.06765 10.8024 4.07137C10.724 4.07509 10.6471 4.05614 10.5914 4.01941L10 3.5881' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round' />
                 </svg>
      }
    case 'completed':
      color = GREEN_500

      break
    case 'unassigned':
      color = SPACE_GREY

      break
    case 'active':
      color = BLUE_500
      break
    default:
      color = SPACE_GREY
      break
  }

  let svg
  switch (props.size) {
    case 'medium':
      svg = <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 23.251C11.472 21.588 8.05703 19.651 2.15703 19.385C1.36242 19.3357 0.744787 18.6741 0.750033 17.878V4.52702C0.748971 4.10994 0.921608 3.71125 1.2265 3.42666C1.5314 3.14207 1.94102 2.99727 2.35703 3.02702C6.22203 3.22702 8.96503 4.15202 10.514 5.23202' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 23.251C12.528 21.588 15.943 19.651 21.843 19.385C22.6376 19.3357 23.2552 18.6741 23.25 17.878V9' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.34207 8.77302C7.5414 8.12285 5.67057 7.68653 3.76807 7.47302' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.34207 12.588C7.53992 11.9428 5.66968 11.5067 3.76807 11.288' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.34204 16.632C7.52292 15.985 5.63558 15.5488 3.71704 15.332' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 10.501V23.251' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.25 0.751007V2.25101' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.4391 3.22101L13.5001 4.28201' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M22.061 3.22101L21 4.28201' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.25 12.751V14.251' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M20.25 8.25101C20.2568 6.79338 19.2149 5.54178 17.7802 5.28418C16.3455 5.02657 14.9333 5.83752 14.4326 7.20647C13.9319 8.57542 14.4876 10.1062 15.75 10.835V12.751H18.75V10.835C19.676 10.3037 20.2478 9.31856 20.25 8.25101Z' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>

      break
    case 'small':
      svg = <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M9.87552 19.2509C9.44723 17.902 6.67713 16.3308 1.89132 16.115C1.24677 16.075 0.745772 15.5384 0.750027 14.8926V4.06288C0.749165 3.72457 0.8892 3.40117 1.13652 3.17032C1.38383 2.93947 1.7161 2.82202 2.05355 2.84615C5.18866 3.00838 7.41366 3.7587 8.67014 4.63475' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.87549 19.251C10.3038 17.902 13.0739 16.3308 17.8597 16.1151C18.5042 16.075 19.0052 15.5384 19.001 14.8927V7.69122' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.7195 7.50708C6.25888 6.97969 4.74135 6.62576 3.19812 6.45258' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.7195 10.6016C6.25768 10.0783 4.74062 9.72449 3.19812 9.54712' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.71948 13.8819C6.24389 13.3571 4.71297 13.0033 3.15674 12.8274' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.87549 8.90878V19.251' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.1342 1V2.21673' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.2317 3.00351L11.0923 3.86414' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M18.0365 3.00351L17.1759 3.86414' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.1342 10.7339V11.9506' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M16.5675 7.08368C16.5731 5.90132 15.7279 4.88608 14.5642 4.67712C13.4004 4.46816 12.2548 5.12596 11.8487 6.2364C11.4426 7.34683 11.8934 8.58851 12.9173 9.17971V10.7339H15.3508V9.17971C16.1019 8.74877 16.5658 7.94963 16.5675 7.08368Z' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            </svg>

      break
    case 'xsmall':
    case 'tiny':
    default:
      svg = <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.90039 14.6674C7.55776 13.6396 5.34168 12.4425 1.51303 12.2781C0.997391 12.2476 0.596593 11.8388 0.599997 11.3468V3.09554C0.599308 2.83777 0.711336 2.59137 0.909189 2.41549C1.10704 2.2396 1.37285 2.15011 1.64282 2.1685C4.15091 2.29211 5.9309 2.86378 6.93609 3.53124' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.90039 14.6674C8.24302 13.6397 10.4591 12.4425 14.2877 12.2781C14.8034 12.2477 15.2042 11.8388 15.2008 11.3468V5.85999' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.17557 5.71969C5.00708 5.31787 3.79305 5.04821 2.55847 4.91626' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.17557 8.07745C5.00612 7.67873 3.79247 7.40916 2.55847 7.27402' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.17559 10.5767C4.99511 10.1768 3.77038 9.90726 2.52539 9.77325' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.90039 6.78763V14.6674' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.3073 0.761902V1.68894' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.1853 2.28839L8.87381 2.94411' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.4292 2.28839L13.7407 2.94411' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.3073 8.17819V9.10523' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M13.254 5.39708C13.2584 4.49624 12.5823 3.72272 11.6513 3.56351C10.7203 3.40431 9.80385 3.90549 9.47893 4.75153C9.15402 5.59758 9.51468 6.54362 10.3338 6.99405V8.17819H12.2806V6.99405C12.8815 6.66572 13.2526 6.05685 13.254 5.39708Z' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            </svg>
  }

  return (
    <div style={{}}>
      {svg}
    </div>
  )
}

export default Component
