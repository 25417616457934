// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import ActivityIcon from '../../atoms/glyphs/activity'
import Text from '../../atoms/text'

/*
 * FrameMount can expect an override on the border
 */
const ActivityEstimateFrameMount = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const TextFrame = styled.div`
  flex: 1 0 auto;
  margin: 0 4px 0 0;
`

const IconFrame = styled.div`
  flex: 0 1 20px;
`

/*
 * View Story for followup information
 */
function ActivityEstimate (props) {
  return (
    <ActivityEstimateFrameMount>
      <TextFrame>
        <Text>{props.children}</Text>
      </TextFrame>
      <IconFrame>
        <ActivityIcon />
      </IconFrame>
    </ActivityEstimateFrameMount>
  )
}

ActivityEstimate.propTypes = {
  children: PropTypes.string.isRequired
}

export default ActivityEstimate
