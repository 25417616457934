
import React from 'react'

const Clock = () => (
    <div style={{}}>
        <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M7.49998 13.9284C11.0504 13.9284 13.9286 11.0503 13.9286 7.49986C13.9286 3.94946 11.0504 1.07129 7.49998 1.07129C3.94958 1.07129 1.07141 3.94946 1.07141 7.49986C1.07141 11.0503 3.94958 13.9284 7.49998 13.9284Z' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.5 7.50002V5.2041' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.5 7.5L10.3696 10.3702' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    </div>
)

export default Clock
