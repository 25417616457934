// Need React for our functional component
import React from 'react'
import style from 'styled-components'
import PropTypes from 'prop-types'

// Making the ADRs Super :wink:
const SupADR = style.sup`
    color:#c3c3c3;
`
/*
 * NOTE: This Component is used elsewhere -- not just TopicViewer and impacts/risk of this component
         exploding the react-render process can be far-reaching.
 */
function DisplayTopicTitle (props) {
  // console.log('props', props)
  const topic = props.topic
  const type = (topic.type || topic.topics.type)
  let title = (topic.title || topic.topics.title)
  let superscript
  // console.log('title', title)

  switch (type) {
    case 'adr':
      const topicTitle = title.split('.')
      if (topicTitle.length > 1) {
        title = topicTitle[1]
        superscript = topicTitle[0]
      }
      break
    default:
      title = title
  }


  return (
    <span>
      {title}
      {superscript ? (<SupADR>{superscript}</SupADR>) : ''}
    </span>
  )
}

DisplayTopicTitle.propTypes = {
  topic: PropTypes.object.isRequired
}

export default DisplayTopicTitle
