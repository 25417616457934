import React, {useState} from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'

//Atoms
import AppLabel from '../atoms/appLabel'
import DropdownArrowSet from '../atoms/glyphs/dropdownArrowSet'
// import GitbabelLogo from '../atoms/GitbabelSmallLogo'
import GitbabelLogo from '../atoms/gitBabelLogo'
import HamburgerMenu from '../atoms/glyphs/nav/hamburgermenu'
import CloseHamburgerMenu from '../atoms/glyphs/nav/closehamburgermenu'

//Molecules
import GlobalSearch from '../molecules/GlobalSearch'
import FilterTopicTitleControl from '../../doc-ops/molecules/FilterTopicTitleControl/FilterTopicTitleControl'
import FilterAppActionBar from '../molecules/FilterAppActionBar'

//Organisms
import LibraryFilterControlBox from '../../doc-ops/organisms/LibraryFilterControlBox'
import WorkspaceDropdownControlBox from '../organisms/WorkspaceDropdownControlBox'

//GraphQL
import { useQuery } from '@apollo/client'
import { GET_WORKSPACE_DROPDOWN_FIELDS } from '../../lib/queries/console'

const AppActionBar = styled.div`
  display: flex;
  width: 100%;
  background-color: #343639;
  padding: 1em 0 1em 1em;
  margin: 0;
  flex-direction: row;
  color: white;
  align-items: center
`

// Will move this to a Atom once the circle issue is resolved
const Avatar = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: green;
`
const Username = styled.p`
  display: flex;
  padding 0 0 0 5px;
`

const MobileDotDisplay = styled.div`
  display: flex;
  margin: 0 1em 0 0;
  @media (min-width:769px) {
    display: none;
  }
`

const SearchDisplay = styled.div`
  display: none;
  align-items: center;
  @media (min-width:769px) {
    display: flex;
    padding: 0 1em 0 0;
  }
`

const AppActionBarRightSide = styled.div`
  display: flex;
// DO NOT REMOVE _auto_ from the end 
  margin: 0 1em  0 auto;
  justify-content: flex-end;
  color: #fff;
`

const Component = (props) => {
  const { loading, error, data } = useQuery(GET_WORKSPACE_DROPDOWN_FIELDS, { variables: { userUuid: props.uuid} })
  const toggleNav = () => { props.setMobileNav(!props.isOpen) }

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>There was an error with GQL</div>
  }

  const workspaces = data.fetchWorkspaceByUser
  const UserWorkspace = workspaces.filter(space => space.workspaces.workspaceType === 'individual')
  const OrgWorkspaces = workspaces.filter(space => space.workspaces.workspaceType === 'organization')

  const UserWorkspaceMarkup = UserWorkspace.map((workspace, index) => {
    return (
        {
          label: 'User Workspace', 
          options: [
            {
            value: `${workspace.workspaceUuid}`, 
            label: `${workspace.workspaces.name}`,
            appEntityUuid: `${workspace.workspaces.appEntityUuid}`,
            billingUserUuid: `${workspace.workspaces.billingUserUuid}`
          }
        ]
        }
    )
  })



  const OrgWorkspaceMarkup = OrgWorkspaces.map((workspace, index) => {
    return (
        {
          label: 'Organization Workspaces', 
          options: [
            {
            value: `${workspace.workspaceUuid}`, 
            label: `${workspace.workspaces.name}`,
            appEntityUuid: `${workspace.workspaces.appEntityUuid}`,
            billingUserUuid: `${workspace.workspaces.billingUserUuid}`
          }
        ]
        }
    )
  })

  UserWorkspaceMarkup.push(...OrgWorkspaceMarkup)
  const WorkspaceDropdownOptions = UserWorkspaceMarkup
  const DropDownDisabled = (WorkspaceDropdownOptions.length > 1) ? false : true

  return (
    <AppActionBar>
      {/* <AppLabel /> */}
      {/* <DropdownArrowSet/> */}
      {/* <GitbabelLogo/> */}
      <WorkspaceDropdownControlBox 
      options={WorkspaceDropdownOptions}
      DropDownDisabled={DropDownDisabled}
      // value={}
      />
      {/* Created the AppActionBarRightSide as a template/placeholder to help positioning */}
      <AppActionBarRightSide>
        <SearchDisplay><GlobalSearch /></SearchDisplay>
        <MobileDotDisplay onClick={toggleNav}>
          {props.isOpen ? <CloseHamburgerMenu /> : <HamburgerMenu />}
        </MobileDotDisplay>
      </AppActionBarRightSide>
    </AppActionBar>
  )
}

export default Component
