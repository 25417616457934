import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { LEGACY } from '../../../core/colors'

const TopicSectionHeader = styled.div`
  display: block;
  font-weight: 700;
  font-size: 1.9em;
  color: ${LEGACY};
`

TopicSectionHeader.propTypes = {
  children: PropTypes.string.isRequired
}

export default TopicSectionHeader
