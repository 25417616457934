import React from 'react'
import styled from 'styled-components'
import { useAuth } from '../../Context/authUserContext'
import axios from '../../lib/utility/axios'

//Atoms
import PlanText from '../atoms/PlanText'
import PlanItem from '../atoms/PlanItem'
import ButtonText from '../atoms/ButtonText'


const UpgradeButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #57B435;
    box-shadow: 4px 4px 0px #DDE0E4;
    border-radius: 10px;
    width: 139px;
    height: 40px;
`

const PlanTable = styled.div`
    display: flex;
    flex-direction: column;
`

const PlanRow = styled.div`
    display: flex;
    flex-direction: row;
`
const ItemBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    width: 160px;
    height: 100px;
    // border: 1px dotted #ff0000;




`

const FreePlan = () => {
    const UserAuthContext = useAuth()
    const SelectedWorkspace = UserAuthContext.state.workspace
    const WorkspaceName = UserAuthContext.state.label

    const handleSubmit = async (e, id) => {
        e.preventDefault()        
            try {
                const checkoutItem = {
                    id: id,
                    workspace: SelectedWorkspace,
                }
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripeCheckout`, checkoutItem).catch(error => {return error})

                //This doesn't work... why?
                if (response.data.status === 500){
                    window.alert('You are not authorized for this transaction. Please contact your workspace billing administrator')
                }

                window.location.href = response.data

            } catch (error) {
                console.log("Error", error)
            }
    }

    return (
    <>
        <PlanText>The <b>{WorkspaceName}</b> workspace is on the <b>Free Plan</b></PlanText>
        <PlanText>Plan includes the following:</PlanText>
        <ul>
        <PlanItem>25 Public Repositories (Indexed)</PlanItem>
        <PlanItem>5 Git-Contributors</PlanItem>
        </ul>
        <PlanText>Gain access to <b>Private</b> repositories, unlimited storage, and
        contributions by upgrading to the <b>Pro Plan</b></PlanText>
        <PlanTable>
           <PlanRow>
                <ItemBox><b>gitBabel Pro - Monthly Edition</b></ItemBox>
                <ItemBox>$99.00 / Monthly</ItemBox>
                <ItemBox><UpgradeButton onClick={(e) => handleSubmit(e, 'price_1Kq0g6DM9lNtKwEj4uPtX72O')}><ButtonText>Upgrade</ButtonText></UpgradeButton></ItemBox>
            </PlanRow>
            <PlanRow>
                <ItemBox><b>gitBabel Pro - Annual Edition</b></ItemBox>
                <ItemBox>$999.00 / Yearly</ItemBox>
                <ItemBox><UpgradeButton onClick={(e) => handleSubmit(e, "price_1Kq0hhDM9lNtKwEj7hGvVz3Z")}><ButtonText>Upgrade</ButtonText></UpgradeButton></ItemBox>
            </PlanRow>
        </PlanTable>
        <PlanText>Are you an open-source project? Message us! `Typeform link here`</PlanText>
    </>
    )
}

export default FreePlan