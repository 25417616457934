import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

//Molecules
import FilterAppActionBar from '../molecules/FilterAppActionBar'

const FilterControlSection = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom:1em;
`

function LibraryFilterControlBox (props) {
  return (
    <div>
      <FilterControlSection>
        <FilterAppActionBar
          header='WORKSPACES'
          options={props.options}
          DropDownDisabled={props.DropDownDisabled}
        />
      </FilterControlSection>
    </div>
  )
}

// LibraryFilterControlBox.propTypes = {
//   selectedCategory: PropTypes.object.isRequired,
//   categoryList: PropTypes.array.isRequired,
//   setSelectedCategory: PropTypes.func.isRequired,
//   selectedOwner: PropTypes.object.isRequired,
//   ownerList: PropTypes.array.isRequired,
//   setSelectedOwner: PropTypes.func.isRequired,
//   selectedRepo: PropTypes.object.isRequired,
//   repoList: PropTypes.array.isRequired,
//   setSelectedRepo: PropTypes.func.isRequired,
//   setSearchTerm: PropTypes.func.isRequired
// }

export default LibraryFilterControlBox
