// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

export const GET_TOPICS_QUERY = gql`{
    topics {
      uuid,
      title,
      type,
      urlSlug,
      repository,
      hasPrimaryHeader,
      blockStats {
        totalHeaders,
        totalCode,
        totalList,
        totalParagraphs
      },
      sections {
        order,
        blocks {
          type,
          depth,
          value,
          lang,
          meta,
          children{ type, value, url,
            children {type, value, url,
              children {type, value, url,
                children {type, value, url}
              }
            }
          }
        }
      },
      ref,
      owner,
      ownerType,
      commitSha,
      sourced,
      isHead
    }
}`

/* Reduced the depth of `sections` on library load */
export const GET_TOPICS_QUERY_FOR_LIBRARY = gql`
 query topicsForLibrary {
  topics {
    uuid,
    title,
    type,
    urlSlug,
    repository,
    owner,
    ownerType,
    updatedAt
  }
}`

export const GET_COLLECTIONS_LIST_QUERY = gql`
  query collectionList { 
    collections {
      uuid,
      title,
      description,
      repository,
      topicCounts{
        type, count
      }
    }
}`

export const GET_TOPIC_BY_UUID_QUERY = gql`
# Write your query or mutation here
query getTopicByUUID($uuid: String!)  {
  me {
      email
  },
  topicByUuid (uuid: $uuid) {
    uuid,
    title,
    type,
    urlSlug,
    branch,
    repository,
    hasPrimaryHeader,
    isReadme,
    isChangelog,
    babelDoc,
    sourceFormat,
    filepath,
    filename,
    blockStats {
      totalHeaders,
      totalCode,
      totalList,
      totalParagraphs
    },
    sections {
      order,
      blocks {
        type,
        depth,
        value,
        lang,
        meta,
        children{ type, value, url,
          children {type, value, url,
            children {type, value, url,
              children {type, value, url}
            }
          }
        }
      }
    },
    toc {
      sectionTitle,
      type,
      children{
        sectionTitle,
        type,
        children {
          sectionTitle,
          type,
          children {
            sectionTitle
            type
          }
        }
      }
    },
    ref,
    owner,
    ownerType,
    commitSha,
    sourced,
    isHead
  }
}
`

export const GET_TOPIC_BY_REPOSITORY_TITLE_QUERY = gql`
# Write your query or mutation here
query getTopicByRepositoryTitle($repository: String!, $urlSlug: String!)  {
  me {
      email
  },
  topicByRepositoryUrl (repository: $repository, urlSlug: $urlSlug) {
    uuid,
    title,
    type,
    urlSlug,
    branch,
    repository,
    hasPrimaryHeader,
    isReadme,
    isChangelog,
    babelDoc,
    sourceFormat,
    filepath,
    filename,
    blockStats {
      totalHeaders,
      totalCode,
      totalList,
      totalParagraphs
    },
    sections {
      order,
      blocks {
        type,
        depth,
        value,
        lang,
        meta,
        children{ type, value, url,
          children {type, value, url,
            children {type, value, url,
              children {type, value, url}
            }
          }
        }
      }
    },
    toc {
      sectionTitle,
      type,
      children{
        sectionTitle,
        type,
        children {
          sectionTitle,
          type,
          children {
            sectionTitle
            type
          }
        }
      }
    },
    ref,
    owner,
    ownerType,
    commitSha,
    sourced,
    isHead
  }
}
`

/*
 *  When writing graphQL queries that have params, one should write it using OperationalNames
 *  https://graphql.org/learn/queries/#operation-name  Apollo requires one to write them this way
 *  also use this as reference -- https://www.apollographql.com/docs/react/essentials/queries/#data
 */
export const GET_COLLECTIONS_DETAIL_QUERY = gql`
  query getCollectionDetail($uuid: String!) {
    collection (uuid: $uuid) {
      uuid,
      title,
      description,
      repository,
      sections {
        header, 
        topics {
          uuid, title, type, urlSlug, repository
          sections {
            order,
            blocks {
              type
            }
          }
        }
      },
      topicCounts{
        type, count
      }
    }
  }
`
export const GET_TOPICS_BY_WORKSPACE_FOR_LIBRARY = gql`
query fetchWorkspaceTopics ($workspaceUuid: ID!) {
  fetchWorkspaceTopics (workspaceUuid: $workspaceUuid) {
    topics {
      uuid,
      title,
      type,
      urlSlug,
      repository,
      owner,
      ownerType,
      ref,
      updatedAt
    }
  }
} 
`

export const GET_COLLECTIONS_FOR_WORKSPACE = gql`
  query fetchWorkspaceCollections ($workspaceUuid: ID!) { 
    fetchWorkspaceCollections (workspaceUuid: $workspaceUuid) {
      uuid,
      title,
      description,
      repository,
      topicCounts{
        type, count
      }
    }
}`
