
import React from 'react'

const Component = () => (
    <div style={{}}>
        <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1.625 20.3789V1.62891' stroke='#1D7EC2' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path fillRule='evenodd' clipRule='evenodd' d='M1.625 16.3127L4.25 15.5019C6.45256 14.8235 8.84067 15.1271 10.8033 16.3352C12.7184 17.5135 15.0405 17.8328 17.2025 17.2152L19.6917 16.5036C20.0942 16.3886 20.3718 16.0206 20.3717 15.6019V4.87774C20.3714 4.58366 20.2333 4.30672 19.9985 4.12959C19.7637 3.95246 19.4595 3.89563 19.1767 3.97607L17.2025 4.54024C15.0415 5.15722 12.7207 4.8379 10.8067 3.66024C8.84374 2.45287 6.45596 2.14924 4.25333 2.82691L1.625 3.63524V16.3127Z' stroke='#1D7EC2' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>

    </div>
)

export default Component
