
import React from 'react'
import styled from 'styled-components'

// Atoms
import GitbabelLogo from '../atoms/gitBabelLogo'
import GitHubLogo from '../atoms/glyphs/github'

const LoginView = () => {
  const getGithubOAuthLink = () => {
    const githubLink = `${process.env.REACT_APP_API_URL}/connect/github`
    return githubLink
  }

  const QuotesArray = [
    {
      id: 1,
      quote: 'The beginning is the most important part of the work.',
      author: 'Plato',
      source: 'The Republic'
    },
    {
      id: 2,
      quote: 'Happiness belongs to the class of things precious and final.',
      author: 'Aristotle',
      source: 'Nicomachean Ethics'
    },
    {
      id: 3,
      quote: "I have not failed, I've just found 10,000 ways that won't work",
      author: 'Thomas Edison',
      source: 'Edison His Life and Inventions'
    },
    {
      id: 4,
      quote: 'At the time, Nixon was normalizing relations with China. I figured that if he could normalize relations, then so could I.',
      author: 'Edgar Codd',
      source: 'Relational Database: A Practical Foundation for Productivity'
    }
  ]

  const WelcomeQuote = QuotesArray[Math.floor(Math.random() * QuotesArray.length)]

  const PageFrame = styled.div`
    background-color: #343639;
     /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media (min-width:600px)  {
      display: flex;
      height: 100vh;
    }
    `

  const Background = styled.div`
    padding: 1em 1em 1em 1em;
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media (min-width:600px)  {
      display: flex;
      background-color: #343639;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    `
  const BoxFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    background-color: white;
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media (min-width:600px)  {
      flex-direction: row;
      max-width: 900px;
      height: fit-content;
     }
  `
  const QuoteBox = styled.div`
    display: none;
    background-color: #F6F5F7;
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media (min-width:600px)  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1em;
      width: 50%;
      height: 50vh;
     }
  `
  const LoginBox = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    @media (min-width:600px)  {
      width: 50%;
      height: 50vh;
     }
  `
  // Could also use a SVG
  const BigQuote = styled.span`
    font-family: Barlow;
    font-size: 2.5em;
    font-weight: 300;
    `
  const Quote = styled.p`
    font-family: Barlow;
    font-size: 1.2em;
    padding: 0 1em 0 1em;
  `

  const Title = styled.h1`
    display: flex;
    flex-direction: row;
    font-family: Rockwell;
    font-size: 3em;
    font-weight: 400;
  `
  const WelcomeText = styled.span`
    display: flex;
    text-align: center;
    font-family: Open Sans;
    color: #6A6E74;
    padding: 1em 1em 2em 1em;
  `
  const LoginButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    font-family: Open Sans;
    width: 175px;
    height: 60px;
    filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.25));
    border-radius: 7px;
    font-size: 1.5em;
    cursor: pointer;
  `

  return (
    <PageFrame>
      <Background>
        <BoxFrame>
          <QuoteBox>
            <Quote><BigQuote>"</BigQuote>{WelcomeQuote.quote}<BigQuote>"</BigQuote></Quote>
            <Quote style={{ textAlign: 'left' }}>-{WelcomeQuote.author}, {WelcomeQuote.source}</Quote>
          </QuoteBox>
          <LoginBox>
            <Title>
              {/* <GitbabelLogo /> */}
              gitBabel
            </Title>
            <WelcomeText>Welcome Back! You may login to gitBabel using our SSO Link below.</WelcomeText>
            <a style={{ textDecoration: 'none' }} href={getGithubOAuthLink()}><LoginButton><GitHubLogo /> Login</LoginButton></a>

          </LoginBox>
        </BoxFrame>
      </Background>
    </PageFrame>
  )
}

export default LoginView
