// Need React for our functional component
import React from 'react';
import PropTypes from "prop-types";

function GlobalSearchMsg(props) {
    return (
       <div>{props.data}</div>
    )
}

GlobalSearchMsg.propTypes = {
    data: PropTypes.string
};

export default GlobalSearchMsg;