
import React from 'react'
/*
 *  The Topic Faq Glyph
 */
const TopicFaqGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/faq/standard24' stroke={strokeColor} strokeWidth='1.5'>
            <path d='M12.75,0.749981392 C8.93932847,0.744505526 5.42568259,2.8069362 3.57279712,6.13681054 C1.71991166,9.46668489 1.81953469,13.539694 3.833,16.775 L0.75,23.25 L7.224,20.166 C11.0101174,22.5209314 15.8706393,22.2278896 19.3464019,19.435138 C22.8221645,16.6423864 24.1550978,11.9590291 22.6708089,7.75459411 C21.18652,3.55015911 17.208735,0.741641192 12.75,0.749981392 Z' id='Shape' />
            <path d='M12.75,15 C12.5428932,15 12.375,15.1678932 12.375,15.375 C12.375,15.5821068 12.5428932,15.75 12.75,15.75 C12.9571068,15.75 13.125,15.5821068 13.125,15.375 C13.125,15.1678932 12.9571068,15 12.75,15' id='Shape' />
            <path d='M9.75,9.75 C9.75,8.09314575 11.0931458,6.75 12.75,6.75 C14.4068542,6.75 15.75,8.09314575 15.75,9.75 C15.75,11.4068542 14.4068542,12.75 12.75,12.75' id='Shape' />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicFaqGlyph
