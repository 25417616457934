// React Include
import React, { Component } from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'

import Loader from '../../../core/molecules/Loader'

import { LEGACY, SPACE_GREY, SLUSH } from '../../../core/colors'

import { GET_COLLECTIONS_DETAIL_QUERY } from '../../../lib/queries/doc-ops'

// [CORE]
// -- Atoms
//  - icons
// import GitRepositoryIcon from '../../core/atoms/icons/gitRepository';
//  - misc
// import StyledLink from '../../../core/atoms/styledLink'
// -- Molecules
// import RepositoryBadge from '../../../core/molecules/RepositoryBadge'

// [DOC-OPS]
// -- Molecules
import TopicDiverseListSummary from '../../molecules/topicDiverseListSummary'
import CollectionDetailHeader from '../../molecules/CollectionDetailHeader'
// -- Organisms
import DiscoveryCollectionDetailContent from '../DiscoveryCollectionDetailContent'

// Need a helper to wrap up all the components into a flex-container
const DetailSectionHeaderFrame = styled.div`
  padding-top:1em;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${SLUSH};
`
const DetailSectionHeader = styled.h3`
  font-size: 1.4em;
  color: ${LEGACY};
  font-weight: 700;
  padding: 0;
  margin: 0;
`

const DetailSectionHeaderTopicLabel = styled.span`
  display: flex;
  font-size: .8em;
  color: ${SPACE_GREY};
  font-weight: 300;
  // push this item left
  margin-left: auto;
  align-self: flex-end;

`

// Render A Collections Full Details which will include a set of Topics
// Use the topicDiverseListSummary component to render the Topcs with their category badges
function DiscoveryCollectionDetail (props) {
  const { loading, error, data } = useQuery(GET_COLLECTIONS_DETAIL_QUERY, { variables: { uuid: props.uuid } })
  if (loading) return <Loader />
  if (error) return `Error!: ${error}`
  // console.log('Library Collection Detail Data: ', data.collection)
  const detailData = data.collection

  // need this thing to translate the block into the component set
  // TODO consider moving into the DiscoveruCollectionDetailContent component
  const collectionDetailListSummaryBlocks = detailData.sections.map((item) => {
    const totalTopics = ((item.topics) ? item.topics.length : null)
    return (
      <div key={item.header}>
        <DetailSectionHeaderFrame>
          <DetailSectionHeader>{item.header}</DetailSectionHeader>
          {/* should hide the Topic label if no topic count of it is is 2-3 */}
          <DetailSectionHeaderTopicLabel>{totalTopics} TOPICS</DetailSectionHeaderTopicLabel>
        </DetailSectionHeaderFrame>

        <TopicDiverseListSummary docs={item.topics} header={item.header} />
      </div>
    )
  })

  /*
     * Create a styledComponent to wrap (helps to have a border set)
     * <div style={{'border':'1px solid #03fc45', 'width':'100%'}}>
     * Noticed that the height / container wasn't fully encapsulating....
     * TODO - Investigate
     */
  return (
    <div style={{ width: '100%' }}>
      <CollectionDetailHeader title={detailData.title} repository={detailData.repository} />
      <DiscoveryCollectionDetailContent description={detailData.description}>
        {collectionDetailListSummaryBlocks}
      </DiscoveryCollectionDetailContent>
    </div>
  )
}

DiscoveryCollectionDetail.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default DiscoveryCollectionDetail
