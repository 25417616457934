
import React from 'react'
/*
 *  The Topic Policy Glyph
 */
const TopicPolicyGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/policy/standard24' stroke={strokeColor} strokeWidth='1.5'>
            <path d='M21.75 18.75H11.25L5.25 23.25V18.75H2.25C1.42157 18.75 0.75 18.0784 0.75 17.25V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V17.25C23.25 18.0784 22.5784 18.75 21.75 18.75Z' />
            <path d='M16.646 9C17.8886 9 18.896 7.99264 18.896 6.75C18.896 5.50736 17.8886 4.5 16.646 4.5C15.4034 4.5 14.396 5.50736 14.396 6.75C14.396 7.99264 15.4034 9 16.646 9Z' />
            <path d='M20.543 12C19.739 10.6083 18.2538 9.7511 16.6465 9.7511C15.0392 9.7511 13.554 10.6083 12.75 12' />
            <path d='M8.25 9.75C9.69975 9.75 10.875 8.57475 10.875 7.125C10.875 5.67525 9.69975 4.5 8.25 4.5C6.80025 4.5 5.625 5.67525 5.625 7.125C5.625 8.57475 6.80025 9.75 8.25 9.75Z' />
            <path d='M3.75 15C3.75 12.5147 5.76472 10.5 8.25 10.5C10.7353 10.5 12.75 12.5147 12.75 15' />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicPolicyGlyph
