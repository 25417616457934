
import React from 'react'

const arrowDown = () => (
    <div style={{}}>
        <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2.56896 2.60547L8 8.39494L13.431 2.60547' stroke='#DDE0E4' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    </div>
)

export default arrowDown
