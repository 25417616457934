
import React from 'react'
/*
 *  The Topic Readme Glyph
 */
const TopicReadmeGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/readme/standard24' strokeWidth='1.5'>
            <g id='Block' fill='#FFFFFF' stroke={strokeColor}>
              <rect id='Rectangle-8' x='0.75' y='0.75' width='22.5' height='22.5' rx='4' />
            </g>
            <path d='M13.75,16.75 L13,16.75 C12.1715729,16.75 11.5,16.0784271 11.5,15.25 L11.5,11.5 C11.5,11.0857864 11.1642136,10.75 10.75,10.75 L10,10.75' id='Shape' stroke={strokeColor} strokeLinecap='round' strokeLinejoin='round' />
            <path d='M11.125,7 C10.9178932,7 10.75,7.16789322 10.75,7.375 C10.75,7.58210678 10.9178932,7.75 11.125,7.75 C11.3321068,7.75 11.5,7.58210678 11.5,7.375' id='Shape' stroke={strokeColor} strokeLinecap='round' strokeLinejoin='round' />
          </g>
        </g>
      </svg>
    </div>)
}

export default TopicReadmeGlyph
