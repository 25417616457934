// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import Text from '../text'

const TextFrame = styled.div`
  flex: 1 0 auto;
  margin: 0 4px 0 0;
`

function TimeEstimate (props) {
  // have a default message
  let timeEstimateText = 'No Estimate'
  if (props.seconds) {
    // do a switch inside of here for different counts
    switch (true) {
      case (props.seconds >= 1800):
        timeEstimateText = '20-30 Mins'
        break
      case (props.seconds >= 900):
        timeEstimateText = '15+ Mins'
        break
      case (props.seconds > 600):
        timeEstimateText = '10-15 Mins'
        break
      case (props.seconds > 300):
        timeEstimateText = '5-10 Mins'
        break
      case (props.seconds > 60):
        timeEstimateText = '1-3 Mins'
        break
      case (props.seconds <= 60):
        timeEstimateText = '15-60 Seconds'
        break
      default:
        break
    }
  }
  return (

    <TextFrame>
      <Text>{timeEstimateText}</Text>
    </TextFrame>

  )
}

TimeEstimate.propTypes = {
  seconds: PropTypes.number.isRequired
}

export default TimeEstimate
