var moment = require('moment')

module.exports = {
  /*
   * Summary
   * getGithubImageUrl is a special function creates a viewable image link to an image
   * referenced in a topic's markdown for an image that is referenced in a relative fashion
   * @params is an object literal containing the following
   * `owner` the github org
   * `repository` the repo
   * `imageRelativeUrl`
   * `ref`
   * `topicFilePath`
   *
   * TODO: Can't stress the importance of getting a UNIT TEST on this because it has edge-cases
   *       that aren't being addressed especially if other pathing gets introduced by customers
   */
  getGithubImageUrl: function (params) {
    // console.log('from image params', params)
    // Need a count of the relative depth of the image directory for the image
    const relativeDirectoryCount = (params.imageRelativePath.match(/\.\.\//g) || []).length

    // TODO Extract out the ref processing into a util/function that is tested
    // slices `master` or whatever branch out of `ref/heads/master`
    // so at 11 it only works for master
    const refLocation = params.ref.slice(11)

    // need org, repo, branch,
    /*
    *  Refer to ADR-020-adding initial image support
    *  Gets interesting here, so imageRelativePath could contain any `depth` of relative paths
    *  which depending on depth count that should be applied to the `topicFilePath` as each depth indicates
    *  where this image is in relation to the doc that references it.  For us, we keep things at one level
    *  with the image folder inside of `docs/`.  This may not be the case and we should support/test for varying levels
    *
    *  path.topicFilePath(slice off relativeDirectoryDepth)+((removeRelative)imageRelativePath())
    */
    // console.log('topic Filepath: ', params.topicFilePath.match(/(\/\w*)/g))
    // since we know our '../' is 3 characters long we can multiple it by depth to generate a slice index
    const sliceIndex = (relativeDirectoryCount * 3)
    // put our slash back on because it gets stripped
    const baseImagePath = '/' + params.imageRelativePath.slice(sliceIndex)
    // console.log('baseImagePath ::', baseImagePath)

    // to handle slicing meta.filepath -- best to regex and find `/docs/guides` as an array
    const filePathList = params.topicFilePath.match(/(\/\w*)/g)
    // we create a new filePathList that is only the base (slice off the pathes based on depth)
    const baseFilePathList = filePathList.slice(0, (filePathList.length - relativeDirectoryCount))
    // console.log('check baseFilePathListings --', baseFilePathList)
    const currentImageLocation = baseFilePathList.join('') + baseImagePath
    const githubUrlImage = `https://gitbabel-topic-contrib.s3.amazonaws.com/${params.owner}/${params.repository}/${refLocation}${currentImageLocation}`
    // console.log('Imageurl -', githubUrlImage)
    return githubUrlImage
  },
  getUrlSlug: function (text) {
    // replace non-alphanumeric with space except underscore ([\W_] is short equivalent for [^a-zA-Z0-9])
    // replace space with '-'
    return text.replace(/[\W_]+/g, ' ').replace(/ /g, '-')
  },
  getRelativeDateFromUnixTimeStamp: function (dateString) {
    // current datetime
    const now = moment()
    // converting unix timestamp to moment object
    const date = moment.unix(Number(dateString) / 1000)
    // determine singular or plural display for time units
    const displayRelativeFormat = (difference, unit) => {
      if (difference === 1) {
        return difference + ' ' + unit + ' ago'
      }

      return difference + ' ' + unit + 's ago'
    }

    // minutes up to an hour
    if (now.diff(date, 'minutes') < 60) {
      return displayRelativeFormat(now.diff(date, 'minutes'), 'minute')
    }

    // hours up to a day
    if (now.diff(date, 'hours') < 24) {
      return displayRelativeFormat(now.diff(date, 'hours'), 'hour')
    }

    // days up to a month
    if (now.diff(date, 'months') < 1) {
      return displayRelativeFormat(now.diff(date, 'days'), 'day')
    }

    // default raw date
    return date.format('MM/DD/YYYY')
  }
}
