import React from 'react'

function dropdownArrowSet() {
  return (
<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 9L3.5 12L1 9" stroke="#A3A9B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1 4L3.5 1L6 4" stroke="#A3A9B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default dropdownArrowSet