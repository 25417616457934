import React from 'react'
import styled from 'styled-components'

// Molecules
import Workspace from '../molecules/WorkspaceSection/Workspace'

const Aligner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`


const ConsolePage = () => {
  return (
    <Aligner>
      <Workspace />
    </Aligner>
  )
}

export default ConsolePage
