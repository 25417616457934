// TODO this is being replaced by `core/molecules/TopicCategoryBadge`
// Replace all references to this component and look to remove

// React Include(s)
import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// [core] Atoms
// - icons
import Icon from '../../core/atoms/Icon'

// Low Level Atom // StyledComponent
const DocOpsAssetBadgeText = styled.span`
  padding: .1em .3em 0 0;
  color:  #343639;
  font-family: 'Open Sans';
  font-weight: 300;
  letter-spacing: -0.09px;
  position: relative;
  font-size: 12px;
  top: -2px;
  left: 6px;
`

const DisplayBadge = styled.div`
    display:flex;
    padding:0 .4em 0 0
`

/* TODO
 * turn this into TopicCategoryBadge
 * adding spacing between the items
 * move into a molecule and have it support a param for single-vs-multi
 * hoist into the Discovery:CollectionListing
 * Critical to understand this is not the `<RepositoryBadge />
 */
function TopicCategoryLabel (props) {
  // console.log('topicCategoryLabel',props)
  const iconStrokeColor = '#000000'
  if (props.count) {
    return (
      <DisplayBadge key={Math.floor((Math.random() * 1000000) + 1)}>
        <Icon glyph={'topic' + props.category} size='small' strokeColor={iconStrokeColor} />
        <DocOpsAssetBadgeText>{props.category.toUpperCase()} ({props.count})</DocOpsAssetBadgeText>
      </DisplayBadge>
    )
  } else {
    return (
      <DisplayBadge key={Math.floor((Math.random() * 1000000) + 1)}>
        <Icon glyph={'topic' + props.category} size='small' strokeColor={iconStrokeColor} />
        <DocOpsAssetBadgeText>{props.category.toUpperCase()}</DocOpsAssetBadgeText>
      </DisplayBadge>
    )
  }
}

TopicCategoryLabel.propTypes = {
  category: PropTypes.string.isRequired,
  count: PropTypes.string
}

export default TopicCategoryLabel
