// React Include
import React, { Component } from 'react'
// Prop-Types
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// CORE
// import StyledLink from "../../core/atoms/styledLink"

// Local Doc-Ops
import TopicViewerSection from '../molecules/topicViewerSection'

// The magical CardFrame
const TopicViewerBlockFrame = styled.div`
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  border-radius: 4px;
  padding:2em;
  margin: 0 1em 3em 0;
  width: auto;
`

/*
 * TopicViewerSectional
   Summary of purpose
    1.) Take the sections of data and generate the base rendered sub-childrens
    2.) Take these children blocks and wrap them into an interface that allows for sections to be render as distinct regions

 */
class TopicViewerSectional extends Component {
  render () {
    console.log('TopicViewerSectional props', this.props)

    // Get the sections from the props
    const sections = this.props.topic.sections

    // Used to get a block-fixture for the storybook in `doc-ops/atoms/topicParagraph
    // console.log('get block for story: ',JSON.stringify(section.blocks));
    let sectionMapped = []
    sectionMapped = sections.map(section => {
      // We need a recursive children-crawler, that can aggregate the inline blocks for paragraph, list

      // prevent Key errors in console, so make our own mini unique key
      const blockKey = 'd' + section.order + '-parent-' + Math.floor(Math.random() * 10000)
      return (
        <TopicViewerBlockFrame key={blockKey}>
          <TopicViewerSection blocks={section.blocks} />
        </TopicViewerBlockFrame>
      )
    }) // <- end the iterator for `sections`
    return sectionMapped
  }
}

TopicViewerSectional.propTypes = {
  topic: PropTypes.object.isRequired
}

export default TopicViewerSectional
