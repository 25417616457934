import React from 'react'

const Markdown = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M23.7879 1H2.21212C1.54269 1 1 1.53119 1 2.18644V13.8136C1 14.4688 1.54269 15 2.21212 15H23.7879C24.4573 15 25 14.4688 25 13.8136V2.18644C25 1.53119 24.4573 1 23.7879 1Z' stroke='black' />
      <path d='M4 12V4H6.32258L8.64516 6.94118L10.9677 4H13.2903V12H10.9677V7.41176L8.64516 10.3529L6.32258 7.41176V12H4ZM18.5161 12L15.0323 8.11765H17.3548V4H19.6774V8.11765H22L18.5161 12Z' fill='black' />
    </svg>
  )
}

export default Markdown
