// Need React for our functional component
import React from 'react'
// Create Necessary presentation components wrappers
import styled from 'styled-components/macro'
// import PropTypes from "prop-types";

const StatusLabel = styled.span`
    background: #BCEEAA;
    border: 1px solid #22384C;
    border-radius: 5px;
    padding: .3em;
    font-family: 'Open Sans';
    font-size: .8em;
    color: #21384D;
    letter-spacing: -0.07px;
`

export default StatusLabel
