import React from 'react'

const Console = (props) => {
  let strokeWidth = 1
  // TODO Color-Constant: LEGACY - D2
  let strokeColor = '#343639'
  if (props.selected) {
    strokeWidth = 1.2
    // TODO Color-Constant: NightsWatch - D3
    strokeColor = '#0d0e11'
  }
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17.25 14.452C17.25 12.96 19.912 12.099 22.729 12.004C22.8608 11.9977 22.9906 12.0377 23.096 12.117C23.1913 12.1875 23.2483 12.2985 23.25 12.417V18.969C23.2248 19.2116 23.0135 19.3916 22.77 19.378C20.8077 19.3438 18.8818 19.9097 17.25 21C15.619 19.9072 13.693 19.3388 11.73 19.371C11.4867 19.3841 11.2757 19.2044 11.25 18.962V12.413C11.2517 12.2945 11.3087 12.1835 11.404 12.113C11.5094 12.0337 11.6392 11.9937 11.771 12C14.588 12.1 17.25 12.96 17.25 14.452Z' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.25 14.452V21.003' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25003 17.253H1.75003C1.19386 17.2464 0.747246 16.7922 0.750033 16.236V4.021C0.745584 3.46346 1.19252 3.00714 1.75003 3H17.75C18.3066 3.00659 18.7534 3.4614 18.75 4.018V9.108' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.66309 21.0031H8.25009' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25 17.2531V21.0031' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 6.75305L6.75 9.00305L3.75 11.2531' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.75 9.00305H13.5' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default Console
