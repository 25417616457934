// React Include(s)
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components'
import Select, { components } from 'react-select'

import { HEDGEWIG, NIGHTS_WATCH, LEGACY, ROBINS_EGG, WHITE, OPALZ } from '../colors'

//Atoms
import DropdownArrowSet from '../atoms/glyphs/dropdownArrowSet'


//LocalStorageHelpers
import { loadWorkspaceFromLocalStorage, setWorkspaceToLocalStorage, setWorkspaceLabelToStorage, WORKSPACE_STATE_LOCAL_STORAGE_KEY, WORKSPACE_LABEL_STATE_LOCAL_STORAGE_KEY } from '../../lib/models/AppState'
import { useAuth } from '../../Context/authUserContext'


const FilterControlboxStandard = styled.dl`
display: flex;
flex-direction:column
margin: 0 2.8em 0 0;
padding: 0;
position: relative;
top:-4px;
min-width: 175px;
max-width: 300px;
`

const FilterHeader = styled.dt`
font-family: 'Open Sans';
font-size: .9em;
color: #A2A2A2;
letter-spacing: -0.11px;
margin: 0;
padding: 0 0 0 0.3em;
`

const FilterText = styled.dd`
font-family: 'Open Sans';
font-size: .9em;
color: ${LEGACY};
letter-spacing: -0.15px;
padding:0 0 0 0;
margin:0;
`

/*
 * Style Overrides - https://react-select.com/styles
 *
 * Overrides primarily were to remove padding on the controller-placeholder
 * and the separator+indicator/icon to open-collapse.
 * This includes `singleValue`, `valueContainer`, `placeholder`, container,
 * indicatorsContainer, indicatorSeparator,dropdownIndicator...
 * The `menu` / `option` defines the look of the dropdown options
 */
const customStyles = {
  /*
   * Custom Option Block Styles
   */
  option: (provided, state) => {
    let backgroundColor = 'white'
    // If select send in this background color
    if (state.isSelected) {
      backgroundColor = '#37BAAD'
    // OnHover send in this background color
    } else if (state.isFocused) {
      backgroundColor = '#3FDDCD'
    }
    return {
      ...provided,
      borderBottom: `1px dotted ${HEDGEWIG}`,
      backgroundColor: backgroundColor,
      padding: 10,
      width: '300px',
    //   color: '#39FF14'
    }
  },
  menu: (provided, state) => {
    const width = '200px'
    // const backgroundColor ='#39FF14'
    return { ...provided, width}
  },
  'option:hover': (provided, state) => {
    const backgroundColor = HEDGEWIG
    return { ...provided, backgroundColor }
  },

  /*
   * Everything below here is used to style the react-select controller frame and indicator
   */
  // This is the main housing for the control, we just override some of the defaults
  control: (provided, state) => {
    // Matching background for now
    const backgroundColor = '#343639'
    const borderRadius = 6
    const border = 1
    const boxShadow ='none'
    // const color = '#39FF14'
    // This took some work to get it this small
    // had to elminate padding all over
    // const minHeight = '20px'

    return { ...provided, backgroundColor, border, borderRadius, boxShadow  }
  },
  singleValue: (provided, state) => {
    const color = '#F8F9FA'
    const lineHeight = '22px'
    const fontWeight = '600'
    const textShadow = '1px 1px 1px #000'

    return {...provided, color, fontWeight, lineHeight, textShadow }
  },
  /*
    * Unmarked class in `react-select`
    * This was hard to track down how to override
    * Defaults a padding
    * Defaults white background
    */
  valueContainer: (provided, state) => {
    const backgroundColor = 'HEDGEWIG'
    const borderRadius = 6
    const border = 1
    const padding = '0 2px'
    return { ...provided, backgroundColor, border, borderRadius, padding }
  },
  /* Label shown in the selection region */
  placeholder: (provided, state) => {
    const padding = 0
    const color = '#F8F9FA'
    const fontSize = '15px'
    const lineHeight = '22px'
    const fontWeight = '500'
    const textShadow = '1px 1px 1px #000'
    return { ...provided, padding, color, fontSize, fontWeight, lineHeight, textShadow }
  },
  input: (provided, state) => {
    const padding = '0'
    return { ...provided, padding }
  },
  // backgroundColor is overriden by Control - but can bleed through from the border-radius in place
  container: (provided, state) => {
    // const backgroundColor = '#39FF14'
    // const backgroundColor = '#ff0000'
    return { ...provided }
  },
  /* Styles for the dropdown indicator is spread across 3-namespaces
   * indicatorSeparator
   * indicatorsContainer
   * dropdownIndicator
   */
  // just hide the separator
  indicatorSeparator: () => ({}),
  // for the indicator keep the padding, but only on the right side
  indicatorsContainer: (provided, state) => {
    const padding = '0 8px 0 0'
    return { ...provided, padding }
  },
  // If we want to mount out own arrow icon
  // https://github.com/JedWatson/react-select/issues/3678
//   dropdownIndicator: (provided, state) => {
//     const padding = '0'
//     return { ...provided, padding }
//   }

}

/* Sample code for a grouping label -- will be used to organize repos into owners
 * Example: https://codesandbox.io/s/34p20?module=/example.js
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}></span>
  </div>
);
*/

const Option = props => {
  /*
   * While exploring how to do background hover, setup the custom option hook
   * TODO: Use this to embed `doc-ops/glpyhs` in here for the category menu
   * Maybe other Glyphs to hook, so consider using the <Glyph /> factory generic
   */
  // console.log('Option Wrapper',props);
  return (
    <div>
      <components.Option {...props} />
    </div>
  )
}

  const CustomComponents = {
    DropdownIndicator: DropdownArrowSet
  };

function WorkspaceSelect (props) {
   const [workspace, setWorkspace] = useState(null)
   const [label, setLabel] = useState(null)

   const UserAuthContext = useAuth()
   
   useEffect(() => {
    //This only has to run once, otherwise the selectWorkspace will update the selected workspace
    const workspaceInStorage = JSON.parse(window.localStorage.getItem(WORKSPACE_STATE_LOCAL_STORAGE_KEY))
    if (workspaceInStorage === null) {
      //We want to store the default workspace and label in storage
      setWorkspaceToLocalStorage(UserAuthContext.state)
      //Set the default locally
      setWorkspace(UserAuthContext.state.workspace)
      setLabel(UserAuthContext.state.label)
    } else {
      //We pull the currently selected workspace and label from localStorage
      setWorkspace(workspaceInStorage)
      setLabel(JSON.parse(window.localStorage.getItem(WORKSPACE_LABEL_STATE_LOCAL_STORAGE_KEY)))
    }
   }, 
    [])

   const selectWorkspace = (e) => {
     const newWorkspace = e.value
     const newLabel = e.label
     const newAppEntity = e.appEntityUuid
     const newBillingUser = e.billingUserUuid
       UserAuthContext.setSelectedWorkspace(newWorkspace, newLabel, newAppEntity, newBillingUser)
       setWorkspace(newWorkspace)
       setLabel(newLabel)
       const shapedData = {
         workspace: newWorkspace,
         label: newLabel,
         appentityuuid: newAppEntity,
         billingUserUuid: newBillingUser
       }
       setWorkspaceToLocalStorage(shapedData)
   }
  // const groupedOptions = [{label:props.header, options:props.options}]


  return (
    <FilterControlboxStandard>
      <FilterHeader>{props.header}</FilterHeader>
      <FilterText>
        {/*
          * menuIsOpen={true} -- Handy debugger to see menus open

        */}
        <Select
          styles={customStyles}
          components={CustomComponents}
          value={props.options.value}
          options={props.options}
          onChange={(e) => { selectWorkspace(e) }}
          placeholder={label}
          isDisabled={props.DropDownDisabled}
        />
      </FilterText>
    </FilterControlboxStandard>
  )
}

WorkspaceSelect.propTypes = {
  header: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired
}

export default WorkspaceSelect
