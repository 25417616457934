// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
// Local / Core Atoms
import StepHeader from '../StepHeader/StepHeader'
import Text from '../../atoms/text'

import LearningTaskList from '../../organisms/LearningTaskList'

const IntroFrameMount = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 68px;
`

const TextFrame = styled.div`
  text-align: left;
  margin: 0 0 28px;
`

/*
 * View Story for followup information
 */
function Intro (props) {
  console.log('Intro - ', props)
  const DESCRIPTION = props.data.description
  return (
    <IntroFrameMount>
      <StepHeader title={props.data.title} type={props.data.type} repository={props.data.repository} />
      <TextFrame>
        <Text>{DESCRIPTION}</Text>
      </TextFrame>
      <LearningTaskList type='learnings' header='Tasks' sections={props.data.tasks[0].sections} progress={props.progress} />
    </IntroFrameMount>
  )
}

// Fill these out
Intro.propTypes = {
  data: PropTypes.object.isRequired,

  progress: PropTypes.object.isRequired

}

export default Intro
