/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requies the `React` import and a `page/` reference
 *
 *  AppPage is loaded by default.  This is the initial work for `Reports` and this view belongs here, but eventually
 *  it should move into its own folder.
 */
import React from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'

// Import our Page
import AppPage from '../pages/appPage'

// [core] Atoms
// - icons
// import GitRepositoryIcon from '../../core/atoms/icons/gitRepository';
// - misc
import StyledLink from '../../core/atoms/styledLink'

// Pull this version of MainSectionFrame from `DiscoveryView` and added the direct:column
const MainSectionFrame = styled.div`
  
  display:flex;
  flex-basis: 90%;
  max-width:1000px;
  // TODO get rid of?
  //padding-right: 1em;
  // debug border
  //border: 1px dotted #333
  flex-direction:column;
  
`

// Building new styles, see if we can get ouselves some basic headers that will work across pages

const PageHeader = styled.h2`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 31px;
    color: #343639;
    letter-spacing: -0.21px;
    padding: 0;
    margin: 0;
`

const HeaderHR = styled.hr`
    width: 100%;
    background-color: #ECECEC;
    border: 3px solid  #ECECEC;
`

const SectionHeader = styled.h3`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 25px;
    color: #343639;
    letter-spacing: -0.17px;
    padding: 0;
    margin: 1.5em 0 0 0;
`

const SubSectionHeader = styled.h4`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    color: #343639;
    letter-spacing: -0.12px;
    padding: 0;
    // left-margin set to indent, same for report p's
    margin: 1em 0 .3em 1.2em;
`

// had to give it a lil left-marin 1.2 to push it in
const ReportDescription = styled.p`
margin: 0 0 .4em 1.3em;
letter-spacing: -0.12px;
line-height: 1.3em;
`

const ReportKeyMetrics = styled.p`
margin: .6em 0 1em 1.5em;
letter-spacing: -0.12px
padding-left:.4em;
border-left: 4px solid #333;
`

// leaving a stub here so I can control the strong when ready
const KeyMetricsStrong = styled.strong`
color: #434343;
`

function ReportView () {
  return (
    <AppPage>
      <MainSectionFrame>
        <PageHeader>Reports</PageHeader>
        <HeaderHR />
        <SectionHeader>Engagement & Contributions</SectionHeader>
        <SubSectionHeader><StyledLink to='/reports/engagement/reference-report'>Reference Engagement</StyledLink></SubSectionHeader>
        <ReportDescription>Referencering is the act of an individual using refering to internal documentation, this metric is tracked based on team/individual's interactions within the platform.</ReportDescription>
        <ReportKeyMetrics> <KeyMetricsStrong>KEY-METRICS:</KeyMetricsStrong> Topic View(s) and Time-Spent</ReportKeyMetrics>
        <SubSectionHeader><StyledLink to='/reports/engagement/contributor-report'>Contributions & Updates</StyledLink></SubSectionHeader>
        <ReportDescription>Knowing which teams/individuals are contributing to the organization knowledge, whether it is new or updated docs and discussions.  Tracking these metrics gives an overview of who is adding value to the organization</ReportDescription>
        <ReportKeyMetrics> <KeyMetricsStrong>KEY-METRICS:</KeyMetricsStrong> Topic Contributions (new/updated) and Percent of Contributions compared to others</ReportKeyMetrics>
        <SectionHeader>Performance & Impact</SectionHeader>
        <SubSectionHeader>Growth, Skills, & Leadership</SubSectionHeader>
        <ReportDescription>Measure and track the growth of leaders and their IC performance towards their learning and career development ladders.</ReportDescription>

        <SubSectionHeader>Impact Report</SubSectionHeader>
        <ReportDescription>Understand the impact that contributors and managers, knowledge contributions have to the overall organizations time-to-deploy, time-to-resolve outages, plus others.</ReportDescription>
        <SectionHeader>Content Analysis & Audits</SectionHeader>
        <SubSectionHeader>Content Scoring</SubSectionHeader>
        <ReportDescription>Measure and track the growth of leaders and their IC performance towards their learning and career development ladders.</ReportDescription>

        <SubSectionHeader>Content Audit</SubSectionHeader>
        <ReportDescription>Measure and track the growth of leaders and their IC performance towards their learning and career development ladders.</ReportDescription>
      </MainSectionFrame>
    </AppPage>
  )
}

export default ReportView
