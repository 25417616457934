
import React from 'react'

// This looks like an existing doc-ops REference icon ... think we can delete this

const Type = () => (
  <div style={{}}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 9H13' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 11H13' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 13H11.5' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 9H6.5' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 11H6.5' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 13H5' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='2.5' y='2.5' width='11' height='4' rx='0.75' stroke='#A3A9B1' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </div>
)

export default Type
