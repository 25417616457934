// React Include(s)
import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// [core] Atoms
// - icons
// import Icon from '../../core/atoms/icon'

// Core Molecules
import TopicCategoryBadge from '../../core/molecules/TopicCategoryBadge'

// Low Level Atom // StyledComponent
const DocOpsAssetBadgeText = styled.span`
  padding: .1em .3em 0 0;
  color:  #343639;
  font-family: 'Open Sans';
  font-weight: 300;
  letter-spacing: -0.09px;
  position: relative;
  font-size: 12px;
  top: -2px;
  left: 6px;
`

const DisplayFrame = styled.div`
    display:flex;
    
`
const DisplayBadge = styled.div`
    display:flex;
    padding:0 .4em 0 0
`

// So need to enable a flexbox frame to host these as columns
function CategoryListBadge (props) {
  // As it stands we don't filter here, we expect the API to send us only item/counts
  // if they aren't ZERO.  If this becomes an issue one will need to add filtering
  const badgesToDisplay = props.assets.map((item) => {
    // item.type/category should always be unique
    return (
      <TopicCategoryBadge key={item.type} category={item.type} count={item.count} />
    )
  })

  // console.log('CollectionCategory-ListOfBadges: ',badgesToDisplay);

  // Return the Perfectly Packaged Badge of Genres
  return (
    <DisplayFrame>
      {badgesToDisplay}
    </DisplayFrame>
  )
}

CategoryListBadge.propTypes = {
  assets: PropTypes.array.isRequired
}

export default CategoryListBadge
