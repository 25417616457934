import React from 'react'

const TopicUncategorizedGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.75 0.750019C8.93933 0.744524 5.42568 2.80696 3.5728 6.13683C1.71991 9.4667 1.81953 13.5397 3.833 16.775L0.75 23.25L7.224 20.166C11.0101 22.521 15.8706 22.2279 19.3464 19.4352C22.8222 16.6424 24.1551 11.959 22.6708 7.75461C21.1865 3.55018 17.2087 0.74166 12.75 0.750019Z' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.75 15C12.5429 15 12.375 15.1679 12.375 15.375C12.375 15.5821 12.5429 15.75 12.75 15.75C12.9571 15.75 13.125 15.5821 13.125 15.375C13.125 15.1679 12.9571 15 12.75 15' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.75 9.75C9.75 8.09315 11.0931 6.75 12.75 6.75C14.4069 6.75 15.75 8.09315 15.75 9.75C15.75 11.4069 14.4069 12.75 12.75 12.75' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>

  )
}

export default TopicUncategorizedGlyph
