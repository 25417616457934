/**
 * Component `RepositoryBadgeText` simple badge
 */

// Got some styling to do initially
import styled from 'styled-components/macro'
import { LEGACY } from '../../colors'

const RepositoryBadgeText = styled.span`
  padding: .1em .3em 0 .2em;
  color:  ${LEGACY};
  font-family: 'Open Sans';
  font-weight: 300;
  letter-spacing: -0.09px;
  position: relative;
  top: -4px;
`

export default RepositoryBadgeText
