import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const Text = styled.div`
  font-size: .875em;
  font-weight: 400;
  font-family: Open Sans;
`

const Component = (props) => {
  switch (props.type) {
    default:
      return (
        <Text>
          {props.children}
        </Text>
      )
  }
}

Component.propTypes = {
  children: PropTypes.string.isRequired

}

export default Component
