import React from 'react'
import ButtonLink from '../../core/atoms/ButtonLink'

import { GREEN_500, SPACE_GREY } from '../../core/colors'

const ActivityButton = (props) => {
  const TEXT = (props.children) ? props.children : 'START'
  let backgroundColor = GREEN_500
  if (props.activityCompleted) {
    backgroundColor = SPACE_GREY
  }

  return (
    <ButtonLink to={props.to} backgroundColor={backgroundColor}>{TEXT}</ButtonLink>
  )
}

export default ActivityButton
