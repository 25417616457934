import React, { useState } from 'react'
import styled from 'styled-components/macro'
// Get our Apollo-React-Hooks Query Tool
// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import { SEARCH_ALL } from '../../../lib/queries/search'
import SearchIcon from '../../atoms/glyphs/search'
import ExitIcon from '../../atoms/glyphs/exit'
import GlobalSearchSavedSearchTerms from '../GlobalSearchSavedSearchTerms'
import GlobalSearchTopicResults from '../GlobalSearchTopicResults'
import GlobalSearchMsg from '../GlobalSearchMsg'
import Cookies from 'js-cookie'

const GlobalSearch = styled.div`
  clear:both;
  color: #222;
  display: block;
  font-family: 'Open Sans';
  font-weight: 300;
  padding:0;
  position: relative;
  font-size: 1em;
`

const InputFrame = styled.div`
  display:flex;
  background-color: #fff;
  padding:.3em 0 0 .5em;
  flex-direction: row;
  font-size:.7em;
  width:200px;
  height: 30px; 
  border-radius:10px;
  border:1px solid #3B3B3B;
  boxShadow: 0 2px 4px 0 rgba(188,188,188,0.50);
`

const Input = styled.input`
  font-family: "Open Sans";
  font-size:13px;
  color: #343639;
  letter-spacing: -0.15px;
  margin: -3px 0 0 0;
  border: none;
  width: 130px;
`

const SearchIconWrapper = styled.div`
  margin: 2px 5px 0 0;
`

const ExitIconWrapper = styled.div`
  cursor: pointer;
  margin: -3px 10px 0 0;
  padding: 10px;
`

const DisplayFrame = styled.div`
  background-color: #fff;
  border-radius:10px;
  border:1px solid #3B3B3B;
  boxShadow: 0 2px 4px 0 rgba(188,188,188,0.50);
  font-size:13px;
  margin: 2px 0 0 0;
  padding: 10px;
  position: absolute;
  width: 188px;
  z-index: 10;
`

function GlobalSearchComponent (props) {
  // name of search cookie
  const cookieName = 'gitbabel_search'

  // retrieve terms from cookie, return array
  function retrieveSavedSearchTermsFromCookie () {
    // console.log('retrieveSearches')
    if (Cookies.get(cookieName) === undefined) {
      return []
    } else {
      if (Cookies.get(cookieName) !== '') {
        return Cookies.get(cookieName).split(',')
      } else {
        return []
      }
    }
  }

  // update terms in cookie
  function updateSavedSearchTermsInCookie (savedSearchTerms) {
    // console.log('updateSearchTerm')
    // if term not found in cookie
    if (Cookies.get(cookieName) === undefined) {
      Cookies.set(cookieName, savedSearchTerms.join(','))
    } else {
      Cookies.set(cookieName, savedSearchTerms.join(','))
    }
  }

  // add saved term
  function addSavedSearchTerm () {
    // console.log('addSaveSearchTerm')
    if (savedSearchTerms.indexOf(searchTerm) === -1
    && searchTerm !== '') {
      const tempSavedSearchTerms = [...savedSearchTerms]
      tempSavedSearchTerms.push(searchTerm)
      setSavedSearchTerms(tempSavedSearchTerms)
      updateSavedSearchTermsInCookie(tempSavedSearchTerms)
    }
  }

  // remove saved term
  function removeSavedSearchTerm (term) {
    // console.log('removeSaveSearchTerm')
    const tempSavedSearchTerms = [...savedSearchTerms]
    const pos = tempSavedSearchTerms.indexOf(term)
    if (pos !== -1) {
      tempSavedSearchTerms.splice(pos, 1)
      setSavedSearchTerms(tempSavedSearchTerms)
      updateSavedSearchTermsInCookie(tempSavedSearchTerms)
    }
  }

  // when user moves off of component, hide display
  function componentBlur () {
    // console.log('handleSearchBlur')
    if (displayFrameActive === false) {
      // console.log('displayFrameActive', displayFrameActive)
      setDisplayFrameVisible(false)
    }
  }

  // flags when user's mouse is on display
  function displayFrameMouseOn () {
    // console.log('handleDisplayOn', true)
    setDisplayFrameActive(true)
  }

  // flags when user's mouse is out of display
  function displayFrameMouseOff () {
    // console.log('handleDisplayOff', false)
    setDisplayFrameActive(false)
  }

  // when search input changes
  function searchInputChange (event) {
    setSearchTerm(event.target.value)
  }

  // when user clicks on input show displayFrame
  function searchInputClick (event) {
    setDisplayFrameVisible(true)
  }

  // when user leaves input save term to cookie
  function searchInputBlur (event) {
    addSavedSearchTerm()
  }

  // allow user to clear input
  function exitSearch () {
    setSearchTerm('')
    setDisplayFrameVisible(true)
  }

  // determine content to display in DisplayFrame
  function displayFrameContent () {
    // if search component is active (click input box)
    if (displayFrameVisible) {
      let component
      // if there is a search term
      if (searchTerm !== '') {
        // if there are search results
        if (data && data.topicsBySearch && data.topicsBySearch.length > 0) {
          component = <GlobalSearchTopicResults data={data ? data.topicsBySearch : []} />
        } else {
          component = <GlobalSearchMsg data='No results' />
        }
      } else {
        // if there are search terms saved in cookie
        if (savedSearchTerms.length > 0) {
          component = <GlobalSearchSavedSearchTerms data={savedSearchTerms} retrieveSearchTermHandler={setSearchTerm} removeSearchTermHandler={removeSavedSearchTerm} />
          // default GlobalSearchMsg
        } else {
          component = <GlobalSearchMsg data='Search for topics using keywords, phrases.' />
        }
      }

      return (
        <DisplayFrame onMouseEnter={displayFrameMouseOn} onMouseLeave={displayFrameMouseOff}>
          {component}
        </DisplayFrame>
      )
    } else {
      return ''
    }
  }

  // display ExitIcon only if searchbox is populated
  function displayExitIcon () {
    if (searchTerm !== '') {
      return (
        <ExitIconWrapper onClick={() => exitSearch()}>
          <ExitIcon />
        </ExitIconWrapper>
      )
    }

    return ''
  }

  // determines whether displayFrame is visible
  const [displayFrameVisible, setDisplayFrameVisible] = useState(false)
  // tracks whether user is on displayFrame
  const [displayFrameActive, setDisplayFrameActive] = useState(false)
  // trackers user input into search
  const [searchTerm, setSearchTerm] = useState('')
  // keeps track of the `saved search terms`
  const [savedSearchTerms, setSavedSearchTerms] = useState(retrieveSavedSearchTermsFromCookie())

  // call search api
  const { loading, error, data } = useQuery(SEARCH_ALL, { variables: { term: searchTerm } })

  return (
    <GlobalSearch onBlur={componentBlur} tabIndex={0}>
      <InputFrame>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input type='text' onChange={searchInputChange} onFocus={searchInputClick} onBlur={searchInputBlur} placeholder='Search Topics' value={searchTerm} />
        {displayExitIcon()}
      </InputFrame>
      {displayFrameContent()}
    </GlobalSearch>
  )
}

export default GlobalSearchComponent
