
// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Import the label we need
import Label from '../../atoms/label'

import TimeEstimate from '../../atoms/TimeEstimate'

// TODO Move this thing
// there is the local icon in here... we want to move this.
import Icon from '../Icon'

const Frame = styled.div`
  padding: 1.8em 0 0 0;
`

// TODO -- When we unravel the `<Icon />` move <TimeEstimate />` out...
const TimeEstimateBadge = (props) => {
    return (<Frame>
          <Label>Estimated Time</Label>
          <Icon type='time'>
            <TimeEstimate seconds={props.seconds} />
          </Icon>
        </Frame>
    )
}

TimeEstimateBadge.propTypes = {
  seconds: PropTypes.number.isRequired
}

export default TimeEstimateBadge