import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Local / Core Atoms
import Header from '../../atoms/header'
import LearningTaskListItem from '../../molecules/LearningTaskListItem'

const ActivityListFrameMount = styled.div`
  margin: 8px 0 0 0;
 // border: 1px solid #ff0000;
`

const ActivityListHeaderFrame = styled.div`
  margin: 0 0 4px 0;
`

const ActivityListBodyFrame = styled.div`
  margin: 0 0 0 4px;
`

/*
 *  Reduced the margin-bottom on card-frame to accomodate the card-count component
 *  when on the intro-card though this margin is needed back because we don't render the card-count
 *  so need to have the props shift for the `<CardContentInnerFrame />` for if we have counts.
 */
const LearningTaskList = (props) => {
  const progressStateMap = props.progress

  let taskSet
  if (props.type === 'learnings') {
    // expand here that there can be more than one section
    taskSet = props.sections[0]
    // move forward with the version that is flattened
  } else {
    return (<p>Error: {props.type}</p>)
  }

  return (
    <ActivityListFrameMount>
      <ActivityListHeaderFrame>
        <Header type={props.type}>{props.header}</Header>
      </ActivityListHeaderFrame>
      <ActivityListBodyFrame>
        {/* loop through list items */}
        {taskSet.topics.map((task, index) => {
          // TODO - finalize here once we have state from top-level VIEW
          // const isActive = (Object.keys(progressStateMap).includes(task.uuid))
          let isActive = false
          let isCompleted = false
          if (progressStateMap && Object.keys(progressStateMap).includes(task.uuid) && progressStateMap[task.uuid].active) {
            isActive = true
          }
          if (progressStateMap && Object.keys(progressStateMap).includes(task.uuid) && progressStateMap[task.uuid].completed) {
            isCompleted = true
            // toggle active false if it is completed -- fail-safe redundancy
            isActive = false
          }
          return (
            <LearningTaskListItem key={'tasks-' + Math.random()} type='reference' title={task.title} active={isActive} completed={isCompleted} />
          )
        })}

      </ActivityListBodyFrame>
    </ActivityListFrameMount>
  )
}

LearningTaskList.propTypes = {
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  sections: PropTypes.array
}

export default LearningTaskList
