// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

import Icon from '../Icon'
import PhaseIcon from '../../../core/atoms/glyphs/sherpa/phase'
import ActivityIcon from '../../atoms/glyphs/activity'

import StyledLink from '../../../core/atoms/styledLink'

const NavList = styled.ul`
  list-style:none;
  display: inline;
`

const NavItemList = styled.li`
 display: inline; 
 padding-right:10px;
`

const BreadCrumbFrame = styled.div`
  display: flex;
  flex-direction: row;
`


const Frame = styled.div`
display: flex;
flex-direction: row;
padding-right: .6em;

`
// place a flex wrapper around our icon
const IconFrame = styled.div`
  display: flex;
`

const Text = styled.div`
display: flex;
padding-left: .2em;
`

const Separator = styled.span`
padding:0 .1em 0 .8em;
  color: #777;
`
const BreadCrumbNav = (props) => {
    return (
        /* display an un-ordered list and see if we can embed icons using flexbox... and make entire block element clickable */

        <BreadCrumbFrame>
            
                <Frame>
                    <IconFrame>
                        <PhaseIcon />
                    </IconFrame>
                    <Text><StyledLink to={props.links.phase.to}>{props.links.phase.label}</StyledLink></Text>
                    <Separator>&gt;</Separator>
                </Frame>
                
                
            
                <Frame>
                    <IconFrame>
                        <ActivityIcon active />
                    </IconFrame>
                    <Text><StyledLink to={props.links.activity.to}>{props.links.activity.label}</StyledLink></Text>
                
                </Frame>
                
                
            
        </BreadCrumbFrame>
        
    )
}

BreadCrumbNav.propType = {
    links: PropTypes.object.isRequired
}

export default BreadCrumbNav