
import React from 'react'
import styled from 'styled-components/macro'
import { HEDGEWIG } from '../colors'

const PageFrame = styled.div`
  background-color: ${HEDGEWIG};
  height: 100vh;
`

const ErrorMessage = styled.p`
  color: #333;
  text-align:center;
`

function ErrorPage (props) {
  const errorMessage = props.errorMessage

  return (
    <>
      <PageFrame>
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      </PageFrame>
    </>
  )
}

export default ErrorPage
