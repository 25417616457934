
import React from 'react'

function Component (props) {
  let color
  switch (props.state) {
    case 'done':
        switch (props.size) {
            case 'medium':
              return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='12' cy='12' r='11.5' fill='white' stroke='#343639'/>
              <path fillRule='evenodd' clipRule='evenodd' d='M16.5 17.4C17.5493 17.4 18.4 16.5493 18.4 15.5C18.4 14.4507 17.5493 13.6 16.5 13.6C15.4506 13.6 14.6 14.4507 14.6 15.5C14.6 16.5493 15.4506 17.4 16.5 17.4Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
              <path fillRule='evenodd' clipRule='evenodd' d='M9.49998 9.8C10.5493 9.8 11.4 8.94934 11.4 7.9C11.4 6.85066 10.5493 6 9.49998 6C8.45063 6 7.59998 6.85066 7.59998 7.9C7.59998 8.94934 8.45063 9.8 9.49998 9.8Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
              <path d='M11.7999 7.79999C12.9929 7.79999 11.7999 11.4 16.2999 11.4V13.5' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
              <path d='M9.5 9.79999V17.4' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
              </svg>
              
                case 'small':
                case 'xsmall':
                case 'tiny':
                default:
                    return <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='10' cy='10' r='9.5' fill='white' stroke='#343639'/>
                    <path fillRule='evenodd' clipRule='evenodd' d='M12.9167 14.5C13.7912 14.5 14.5 13.7911 14.5 12.9167C14.5 12.0422 13.7912 11.3333 12.9167 11.3333C12.0423 11.3333 11.3334 12.0422 11.3334 12.9167C11.3334 13.7911 12.0423 14.5 12.9167 14.5Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
                    <path fillRule='evenodd' clipRule='evenodd' d='M7.08333 8.16667C7.95778 8.16667 8.66667 7.45778 8.66667 6.58333C8.66667 5.70888 7.95778 5 7.08333 5C6.20888 5 5.5 5.70888 5.5 6.58333C5.5 7.45778 6.20888 8.16667 7.08333 8.16667Z' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
                    <path d='M9 6.5C9.99411 6.5 9 9.5 12.75 9.5V11.25' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
                    <path d='M7.08337 8.16666V14.5' stroke='#57B435' strokeLinecap='round' strokeLinejoin='round'/>
                    </svg>
                    
            }
    case 'unassigned':
      color = '#343639'
      break
    case 'active':
    default:
      color = '#A3A9B1'
      break
  }

  let svg
  switch (props.size) {
    case 'medium':
      svg = <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='11.5' fill='white' stroke={color}/>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.2 17.4C17.1941 17.4 18 16.5941 18 15.6C18 14.6059 17.1941 13.8 16.2 13.8C15.2059 13.8 14.4 14.6059 14.4 15.6C14.4 16.5941 15.2059 17.4 16.2 17.4Z' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M13.2 8.39999H15C15.6627 8.39999 16.2 8.93725 16.2 9.59999V13.8' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M13.8 6.60001L12 8.40001L13.8 10.2' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M12 8.39999L15.15 8.39999' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.39998 10.2C9.39409 10.2 10.2 9.39412 10.2 8.40001C10.2 7.40589 9.39409 6.60001 8.39998 6.60001C7.40586 6.60001 6.59998 7.40589 6.59998 8.40001C6.59998 9.39412 7.40586 10.2 8.39998 10.2Z' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M8.40002 10.2V17.4' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
      
      
      break
        case 'small':
        case 'xsmall':
        case 'tiny':
        default:
            svg = <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='10' r='9.5' fill='white' stroke={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M13.5 14.5C14.3284 14.5 15 13.8284 15 13C15 12.1716 14.3284 11.5 13.5 11.5C12.6716 11.5 12 12.1716 12 13C12 13.8284 12.6716 14.5 13.5 14.5Z' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M11 7H12.5C13.0523 7 13.5 7.44772 13.5 8V11.5' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M11.5 5.5L10 7L11.5 8.5' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M10 7L12.625 7' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M7 8.5C7.82843 8.5 8.5 7.82843 8.5 7C8.5 6.17157 7.82843 5.5 7 5.5C6.17157 5.5 5.5 6.17157 5.5 7C5.5 7.82843 6.17157 8.5 7 8.5Z' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M7 8.5V14.5' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
            
            
    }

    return (
        <div style={{}}>
            {svg}
        </div>
    )
}

export default Component
