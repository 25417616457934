
import React, { useState, useEffect } from 'react'

import AppActionBar from '../organisms/appActionBar'
import AppMainSection from '../organisms/appMainSection'
import AppBaseRow from '../templates/appBaseRow'
import AppPanel from '../templates/AppPanel'

// Import our Components
import Sidebar from '../organisms/sidebar'
import NavMenu from '../molecules/NavMenu'

/*
 * This has gone through a view iterations, but this will hold for now
 * Eventually TopcPage may need the ability to replace AppPanel or Sidebar entirely
 *
 * TODO: Add a Storybook for Views/Pages?  TopicPage is unique in that is is specific to only TopicView
 */
// import styled from 'styled-components/macro'

function TopicPage (props) {
  const [mobileNavOpen, setMobileNav] = useState(false)

  useEffect(() => {
    const HandleResize = () => {
      if (window.innerWidth >= 769) {
        setMobileNav(false)
      }
    }
    window.addEventListener('resize', HandleResize)
    HandleResize()
    return () => window.removeEventListener('resize', HandleResize)
  }, [])
  return (
    <>
      <AppActionBar isOpen={mobileNavOpen} setMobileNav={setMobileNav} />
      <AppBaseRow>
        {props.sideNav !== false
          ? <Sidebar isOpen={mobileNavOpen}><NavMenu isOpen={mobileNavOpen} /></Sidebar>
          : ''}
        {/*
                  * AppPanel set flex-direction Column, no left/right padding
                  *  all subsections are in-charge of how they position now...
                */}
        {/* <ContentDisplay mobileNavOpen={mobileNavOpen}> */}
        <AppPanel boxPadding={props.appPanelPadding} mobileNavOpen={mobileNavOpen}>
          <AppMainSection>
            {/* * Handoff to the main-switch route */}
            {props.children}
          </AppMainSection>
        </AppPanel>
        {/* </ContentDisplay> */}

      </AppBaseRow>
    </>
  )
}

export default TopicPage
