import React from 'react'

const Workspace = (props) => {
  const { width, height } = props
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17.25 14.4522C17.25 12.9602 19.912 12.0992 22.729 12.0042C22.8608 11.9978 22.9906 12.0378 23.096 12.1172C23.1913 12.1877 23.2483 12.2986 23.25 12.4172V18.9692C23.2248 19.2117 23.0135 19.3917 22.77 19.3782C20.8077 19.3439 18.8818 19.9098 17.25 21.0002C15.619 19.9073 13.693 19.339 11.73 19.3712C11.4867 19.3842 11.2757 19.2045 11.25 18.9622V12.4132C11.2517 12.2946 11.3087 12.1837 11.404 12.1132C11.5094 12.0338 11.6392 11.9938 11.771 12.0002C14.588 12.1002 17.25 12.9602 17.25 14.4522Z' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.25 14.4521V21.0031' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25003 17.253H1.75003C1.19386 17.2464 0.747246 16.7922 0.750033 16.236V4.021C0.745584 3.46346 1.19252 3.00714 1.75003 3H17.75C18.3066 3.00659 18.7534 3.4614 18.75 4.018V9.108' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.66309 21.0029H8.25009' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25 17.2529V21.0029' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 6.75293L6.75 9.00293L3.75 11.2529' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.75 9.00293H13.5' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
    </svg>

  )
}

export default Workspace
