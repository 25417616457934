// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
// Local / Core Atoms
import Header from '../../../bonvoy/atoms/header'
import Text from '../../../bonvoy/atoms/text'
import StepHeader from '../StepHeader/StepHeader'
import TopicParagraph from '../../../doc-ops/atoms/topicParagraph'
// include prism here
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coy } from 'react-syntax-highlighter/dist/cjs/styles/prism'

const CardFrameMount = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  
`

const CardContentDisplayer = styled.div`
height: 400px;
  overflow: auto;
  padding: 24px 0;
`

const SubHeaderFrame = styled.div`
  margin: 0 0 24px 0;
`

const StepList = styled.ul`
  margin: 0px;
`

const StepItem = styled.li`
  list-style-type: none;
  margin: 0px 0px 0px -30px;
  padding: 0px 0px 8px 0px;
`

function Card (props) {
  console.log('card --', props)

  const meta = {
    owner: props.data.owner,
    filepath: props.data.filepath,
    filename: props.data.filename,
    repository: props.data.repository,
    ref: props.data.ref
  }
  let steps

  if (props.data.steps) {
    steps = props.data.steps.map(step => {
      return <StepItem key={'step-' + Math.random()}>{step.rule}</StepItem>
    })
  }

  const bodyContent = props.data.section.blocks.map((block, i) => {
    const blockKey = 'd' + block.depth + '-parent-' + Math.floor(Math.random() * 10000)
    if (i > 0) {
      switch (block.type) {
        case 'paragraph':

          return (<TopicParagraph key={blockKey} block={block} meta={meta} />)
        case 'code':
          let lang = ''
          if (block.lang) {
            lang = block.lang.toLowerCase()
          }

          return (<SyntaxHighlighter key={blockKey} language={lang} style={coy}>{block.value}</SyntaxHighlighter>)
        case 'list':
          const childBlocks = block.children.map(innerBlock => {
            // console.log('innerBlock', innerBlock)
            const innerBlockKey = 'd' + block.depth + '-inner-' + Math.floor(Math.random() * 10000)
            return (
              <li key={innerBlockKey}>
                <TopicParagraph block={innerBlock.children[0]} meta={meta} />
              </li>
            )
          })
          return (<ul key={blockKey}>{childBlocks}</ul>)
        default:
          console.log('unsupported -', block.type, ':', block.depth)
          // console.warn('unsupported - block typeblock.type')
      }
    }
  })

  return (
    <CardFrameMount>
      <StepHeader title={props.data.section.blocks[0].children[0].value} type={props.data.type} repository={props.data.repository} />
      {/* <SubHeaderFrame>
        <Header type='sub'>{props.data.subtitle
        ?
        props.data.subtitle
        :
        ''
        }</Header>
      </SubHeaderFrame> */}
      <CardContentDisplayer>
        {bodyContent}
      </CardContentDisplayer>

      {(steps && steps.length > 0)
        ? <StepList>
          {steps}
        </StepList>
        : ''}
    </CardFrameMount>
  )
}

// de-blob this card, easier to
Card.propTypes = {

}

export default Card
