// Need React for our functional component
import React from 'react'
import PropTypes from 'prop-types'

// import the constants
import {
  DOC_OPS_GLYPH_GENRE_REFERENCE, DOC_OPS_GLYPH_GENRE_DISCUSSION, DOC_OPS_GLYPH_GENRE_HOWTO,
  DOC_OPS_GLYPH_TOPIC_ADR, DOC_OPS_GLYPH_TOPIC_CHANGELOG, DOC_OPS_GLYPH_TOPIC_FAQ, DOC_OPS_GLYPH_TOPIC_GUIDE,
  DOC_OPS_GLYPH_TOPIC_POLICY, DOC_OPS_GLYPH_TOPIC_README, DOC_OPS_GLYPH_TOPIC_REFERENCE, DOC_OPS_GLYPH_TOPIC_RFC, DOC_OPS_GLYPH_TOPIC_RUNBOOK, DOC_OPS_GLYPH_TOPIC_UNCATEGORIZED
} from '../../../doc-ops/constants'

// import the constants
import { ICON_SIZE_GIANT, ICON_SIZE_LARGE, ICON_SIZE_MEDIUM, ICON_SIZE_SMALL, ICON_SIZE_TINY } from '../../constants'

/// //////
// Genre
// TODO -- decide what we are doing with Genre icons, if we want to keep them (migrate into our new pattern or delete)
/// //////
// Standard
import GenreDiscussionStandard from '../glyphs/doc-ops/genre/standard/genreDiscussionStandard'
import GenreReferenceStandard from '../glyphs/doc-ops/genre/standard/genreReferenceStandard'
import GenreHowToStandard from '../glyphs/doc-ops/genre/standard/genreHowToStandard'
// Large
import GenreDiscussionLarge from '../glyphs/doc-ops/genre/large/genreDiscussionLarge'
import GenreReferenceLarge from '../glyphs/doc-ops/genre/large/genreReferenceLarge'
import GenreHowToLarge from '../glyphs/doc-ops/genre/large/genreHowToLarge'
/// /////
// Topics
/// /////
import TopicAdrGlyph from '../glyphs/doc-ops/topic/topicAdrGlyph'
import TopicChangelogGlyph from '../glyphs/doc-ops/topic/topicChangelogGlyph'
import TopicFaqGlyph from '../glyphs/doc-ops/topic/topicFaqGlyph'
import TopicGuideGlyph from '../glyphs/doc-ops/topic/topicGuideGlyph'
import TopicPolicyGlyph from '../glyphs/doc-ops/topic/topicPolicyGlyph'
import TopicReadmeGlyph from '../glyphs/doc-ops/topic/topicReadmeGlyph'
import TopicReferenceGlyph from '../glyphs/doc-ops/topic/topicReferenceGlyph'
import TopicRfcGlyph from '../glyphs/doc-ops/topic/topicRfcGlyph'
import TopicRunbookGlyph from '../glyphs/doc-ops/topic/topicRunbookGlyph'
import TopicUncategorizedGlyph from '../glyphs/doc-ops/topic/topicUncategorizedGlyph'

function Icon (props) {
  // console.log('Icon Props: ', props)
  // marshall props
  const glyph = props.glyph.toLowerCase()
  const size = props.size.toLowerCase()
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'

  let iconComponent = ''
  const iconConstructor = {
    width: null,
    height: null,
    strokeColor: strokeColor
  }
  switch (size) {
    case ICON_SIZE_GIANT:
      iconConstructor.width = 64
      iconConstructor.height = 64
      break

    case ICON_SIZE_LARGE:
      iconConstructor.width = 32
      iconConstructor.height = 32
      break
    case ICON_SIZE_MEDIUM:
      iconConstructor.width = 24
      iconConstructor.height = 24
      break
    case ICON_SIZE_SMALL:
      iconConstructor.width = 20
      iconConstructor.height = 20
      break
    case ICON_SIZE_TINY:
      iconConstructor.width = 16
      iconConstructor.height = 16
      break
    default:
      console.warn(`Invalid Icon Size Key ${size}`)
  }

  /// console.log('iconConstructor? ', iconConstructor)
  switch (glyph) {
    case DOC_OPS_GLYPH_TOPIC_REFERENCE:
      iconComponent = <TopicReferenceGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_ADR:
      iconComponent = <TopicAdrGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_CHANGELOG:
      iconComponent = <TopicChangelogGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_FAQ:
      iconComponent = <TopicFaqGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_GUIDE:
      iconComponent = <TopicGuideGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_POLICY:
      iconComponent = <TopicPolicyGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_README:
      iconComponent = <TopicReadmeGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_RFC:
      iconComponent = <TopicRfcGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_RUNBOOK:
      iconComponent = <TopicRunbookGlyph {...iconConstructor} />
      break
    case DOC_OPS_GLYPH_TOPIC_UNCATEGORIZED:
      iconComponent = <TopicUncategorizedGlyph {...iconConstructor} />
  }

  // genre icons
  /*
    if (glyph === DOC_OPS_GLYPH_GENRE_REFERENCE) {
      iconComponent = <GenreReferenceLarge />
    } else if (glyph === DOC_OPS_GLYPH_GENRE_DISCUSSION) {
      iconComponent = <GenreDiscussionLarge />
    } else if (glyph === DOC_OPS_GLYPH_GENRE_HOWTO) {
      iconComponent = <GenreHowToLarge />
    }
  } else if (size === ICON_SIZE_MEDIUM) {
    if (glyph === DOC_OPS_GLYPH_GENRE_REFERENCE) {
      iconComponent = <GenreReferenceStandard />
    } else if (glyph === DOC_OPS_GLYPH_GENRE_DISCUSSION) {
      iconComponent = <GenreDiscussionStandard />
    } else if (glyph === DOC_OPS_GLYPH_GENRE_HOWTO) {
      iconComponent = <GenreHowToStandard />
    }
   */

  if (iconComponent === '') {
    console.warn(`<Icon /> has no matching ${size} glyph for ${glyph}`)
  }

  // Return the selected Glpyh Component
  return iconComponent
}

// TODO add this to our docs on propType checking for functions
// https://reactjs.org/docs/typechecking-with-proptypes.html
// https://stackoverflow.com/questions/44582209/proptypes-in-functional-stateless-component
Icon.propTypes = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  strokeColor: PropTypes.string.isRequired
}

export default Icon
