import axios from 'axios'

// This will be needed for all our REST calls to our server to ensure the cookies are sent on the headers
//
const connection = axios.create({
    // baseURL: 'https://some-domain.com/api/',
    timeout: 10000,
    withCredentials: true
    // headers: {'X-Custom-Header': 'foobar'}
  })

  export default connection