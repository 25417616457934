
import React from 'react'
import styled from 'styled-components/macro'
import { HEDGEWIG, SPACE_GREY } from '../colors'

const PageFrame = styled.div`
  background-color: ${HEDGEWIG};
  height: 100vh;
`

const LoadingMessage = styled.p`
  color: #333;
  text-align:center;
`

function AuthLoaderPage (props) {
  return (
    <>
      <PageFrame>
        <LoadingMessage>Authenticating & Loading ....</LoadingMessage>
      </PageFrame>
    </>
  )
}

export default AuthLoaderPage
