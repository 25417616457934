import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

// Atoms
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import Link from '../../atoms/glyphs/link'
import Github from '../../atoms/glyphs/github'

// Moleclues
import Loader from '../Loader'

// Queries
import { GET_APP_ENTITIES, GET_WORKSPACE_APP_ENTITIES } from '../../../lib/queries/console'

const GreenBox = styled.div`
    background-color: #E5FFFC;
    padding: 0 1em 0em 1em;
    margin: 0 0 3em 0;
`

const GreenHighlight = styled.a`
    color: #29877D;
    font-weight: 700;
    `

const GithubSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 0 1em 0;
    `

const AppInstalled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1em 0 1em 0;
`

const GithubItem = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 1.2em;
    `

const AppInstillationLayout = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 1em 1em 1em 1em;
    `

const AppSettings = styled.span`
    display: flex;
    font-family: Open Sans
    .hover {
      cursor: pointer;
    }
    `

const IngestionItem = styled.div`
    display: flex;
    flex-direction: row;
`

const IngestionMethod = styled.div`
    background-color: #BCEEAA;
    border: 1px solid black;
    border-radius: 8px;
    padding: .2em .4em;
    font-size: .7em;
    font-family: 'Open Sans';
    text-align: center;
    margin: 0 0 0 0.5em;
`

const GithubInstallSection = (props) => {
  //Here we set the entityType and UUID for the user. If the ORG_UUID is defined, we know it's an ORG, if there is none, we know it's a user.
  const entityType = (process.env.REACT_APP_ORGANIZATION_UUID) ? ('organization') : ('user')
  const uuid = (process.env.REACT_APP_ORGANIZATION_UUID) ? (process.env.REACT_APP_ORGANIZATION_UUID) : (props.uuid)
  const AppName = (process.env.REACT_APP_GITHUB_APP_NAME) ? (process.env.REACT_APP_GITHUB_APP_NAME) : ('gitBabel Community')
  // console.log(process.env.REACT_APP_ORGANIZATION_UUID, entityType, uuid )

  const { loading, error, data } = useQuery(GET_WORKSPACE_APP_ENTITIES, { variables: { appEntityUuid: props.userdata.state.appentityuuid} })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }

  const appData = data.fetchAppEntities[0]

  // Not fully wired and for now all workspaces are going to just be selected
  const ingestionMethod = (appData.repositorySelection)? appData.repositorySelection.toUpperCase() : 'SELECTED'

  

  const InstallCheck = appData.isInstalled ? (<>YES</>) : (<>NO</>)

  const OpenAppSettings = () => {
    window.open(`https://github.com/settings/installations/${appData.installId}`)
  }
  
  return (
    <GreenBox>
      <GithubSection>
        <GithubItem><div style={{ paddingRight: '.75em' }}><Github /></div> Application Settings </GithubItem>
        <AppInstalled>
          App Installed:
          <AppSettings style={{ paddingLeft: '.75em' }} onClick={() => { OpenAppSettings() }}><GreenHighlight>{AppName}</GreenHighlight></AppSettings>
        </AppInstalled>
        <AppInstillationLayout style={{ backgroundColor: 'white' }}>
          <IngestionItem>App Installed: {InstallCheck}</IngestionItem>
          <IngestionItem>Ingestion: <IngestionMethod>{ingestionMethod}</IngestionMethod></IngestionItem>
        </AppInstillationLayout>
      </GithubSection>
    </GreenBox>
  )
}

export default GithubInstallSection
