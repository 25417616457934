
import React from 'react'
/*
 *  The Topic Runbook Glyph
 */
const TopicRunbookGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (

    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/runbook/standard24' transform='translate(-2.000000, 0.000000)' stroke={strokeColor} strokeWidth='1.5'>
            <path d='M3.25,3 L3.25,21.3 C3.27079649,22.4001491 4.1788273,23.2755105 5.279,23.256 L19.485,23.256 C20.0346274,23.264377 20.4879706,22.8275596 20.5,22.278 L20.5,6.753 C20.5,5.92457288 19.8284271,5.253 19,5.253 L18.25,5.253' id='Shape' />
            <path d='M19,5.253 L5.5,5.253 C4.90274275,5.253 4.32999134,5.01553587 3.90794814,4.59292995 C3.48590495,4.17032403 3.24920366,3.59725672 3.249998,3 L3.249998,3 C3.25165529,1.75853105 4.25852994,0.753 5.5,0.753 L17.5,0.753 C18.3284271,0.753 19,1.42457288 19,2.253 L19,5.253' id='Shape' />
            <polyline id='Shape' points='7 12.003 9.25 14.253 7 16.503' />
            <path d='M12.087,14.253 L16,14.253' id='Shape' />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicRunbookGlyph
