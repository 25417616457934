
import React from 'react'

// THIS NEEDS PORTED TO CORE/GLYPHS

import { SPACE_GREY, BLUE_500, GREEN_500 } from '../../../core/colors'

const Activity = (props) => {
  // console.log('activity icon',props)
  let STROKE_COLOR = SPACE_GREY
  if (props.active) {
    STROKE_COLOR = BLUE_500
  }
  if (props.completed) {
    STROKE_COLOR = GREEN_500
  }
  let PADDING_TOP = ''
  if (props.paddingTop) {
    PADDING_TOP = '.3em'
  }

  return (
    <div style={{ paddingTop: PADDING_TOP }}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.562744 3.06048C0.562744 2.23205 1.23432 1.56048 2.06274 1.56048H15.9377C16.7662 1.56048 17.4377 2.23205 17.4377 3.06048V16.9355C17.4377 17.7639 16.7662 18.4355 15.9377 18.4355H2.06274C1.23432 18.4355 0.562744 17.7639 0.562744 16.9355V3.06048Z' stroke={STROKE_COLOR} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.00024 4.37298L5.62524 8.87298L3.37524 6.62298' stroke={STROKE_COLOR} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.6877 7.18548H14.0627' stroke={STROKE_COLOR} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.00024 11.123L5.62524 15.623L3.37524 13.373' stroke={STROKE_COLOR} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.6877 13.9355H14.0627' stroke={STROKE_COLOR} strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  )
}

export default Activity
