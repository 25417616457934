// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import ActivityIcon from '../../atoms/glyphs/activity'
import Text from '../../atoms/text'
import Label from '../../atoms/label'

/*
 * FrameMount can expect an override on the border
 */
const ActivityStatusFrameMount = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  width: 100%;
`

const StatusFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
`

const OptionsFrame = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
`

const ActivitiesIconFrame = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const IconFrame = styled.div`
display: flex;
  flex: 0 1 20px;
  margin: 0 5px 0 0;
`

const TextFrame = styled.div`
display: flex;
  flex: 1 0 auto;
`

const OptionLabelFrame = styled.div`
  margin: 0 10px;
`

/*
 * View Story for followup information
 */
function ActivityStatus (props) {
  const activityDetails = props.completed + '/' + props.total + ' completed'
  return (
    <ActivityStatusFrameMount>
      <StatusFrame>
        <ActivitiesIconFrame>
          <IconFrame>
            <ActivityIcon />
          </IconFrame>
          <TextFrame>
            <Label type='medium'>Activities</Label>
          </TextFrame>
        </ActivitiesIconFrame>
        <Text>{activityDetails}</Text>
      </StatusFrame>
     
    </ActivityStatusFrameMount>
  )
}

ActivityStatus.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default ActivityStatus
