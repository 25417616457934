
import React from 'react'

const CircleActive = () => (
    <div style={{}}>
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='9' cy='9' r='8' fill='white' stroke='#0C466E' strokeWidth='2' />
        </svg>
    </div>
)

export default CircleActive
