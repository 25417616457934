import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

import FilterTopicTitleControl from '../../molecules/FilterTopicTitleControl'
import FilterTopicSelectControl from '../../molecules/FilterTopicSelectControl'

const FilterControlSection = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom:1em;
`

function LibraryFilterControlBox (props) {
  return (
    <div>
      <FilterControlSection>
        <FilterTopicTitleControl setSearchTerm={props.setSearchTerm} />
        <FilterTopicSelectControl
          header='CATEGORY'
          value={props.selectedCategory}
          options={props.categoryList}
          handleChange={props.setSelectedCategory}
        />
        <FilterTopicSelectControl
          header='OWNERS'
          value={props.selectedOwner}
          options={props.ownerList}
          handleChange={props.setSelectedOwner}
        />
        <FilterTopicSelectControl
          header='REPOSITORIES'
          value={props.selectedRepo}
          options={props.repoList}
          handleChange={props.setSelectedRepo}
        />
        {/* !TODO: Build out GroupBy when ready */}
        {/*
        <FilterControlboxStandard>
          <FilterHeader>GROUP BY</FilterHeader>
          <FilterText>None</FilterText>
        </FilterControlboxStandard>
        */}
      </FilterControlSection>
    </div>
  )
}

LibraryFilterControlBox.propTypes = {
  selectedCategory: PropTypes.object.isRequired,
  categoryList: PropTypes.array.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedOwner: PropTypes.object.isRequired,
  ownerList: PropTypes.array.isRequired,
  setSelectedOwner: PropTypes.func.isRequired,
  selectedRepo: PropTypes.object.isRequired,
  repoList: PropTypes.array.isRequired,
  setSelectedRepo: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired
}

export default LibraryFilterControlBox
