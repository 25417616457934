import React from 'react'
import styled from 'styled-components'

// Atoms
import Spinner from '../atoms/Spinner'

const LoaderFrame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    // background-color: grey;
    `

const Loader = () => {
  return (
    <LoaderFrame>
      <Spinner />
    </LoaderFrame>
  )
}

export default Loader
