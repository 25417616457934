import React from 'react'
import styled from 'styled-components'

const ConsoleFrame = styled.div`
    dislay: flex;
    flex-direction: row;
    padding: 1em 1em 1em 1em;
    background-color: white;
`

export default ConsoleFrame
