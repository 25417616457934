
import React from 'react'
/*
 *  The initial SearchIcon being added for GlobalSearch
 */
const SearchIcon = () => (
  <div style={{}}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6025 15.7665C13.4545 15.7665 15.7665 13.4545 15.7665 10.6025C15.7665 7.75048 13.4545 5.43848 10.6025 5.43848C7.75048 5.43848 5.43848 7.75048 5.43848 10.6025C5.43848 13.4545 7.75048 15.7665 10.6025 15.7665Z" stroke="#727C88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0291 15.4211L23.7101 21.1001C24.4305 21.8209 24.4303 22.9892 23.7096 23.7096C22.9889 24.4301 21.8205 24.4299 21.1001 23.7091L15.4201 18.0291V18.0291C11.6192 20.4968 6.56875 19.6966 3.71679 16.175C0.864822 12.6534 1.13186 7.54685 4.33559 4.3419C7.53932 1.13694 12.6457 0.867943 16.1685 3.71856C19.6912 6.56917 20.4933 11.6194 18.0271 15.4211H18.0291Z" stroke="#727C88" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>

  </div>
)


export default SearchIcon