import React , {useState, useEffect} from 'react'
import styled from 'styled-components'
import axios from '../../lib/utility/axios'
import { useAuth } from '../../Context/authUserContext'
import moment from 'moment'


//Atoms
import PlanItem from '../atoms/PlanItem'
import PlanText from '../atoms/PlanText'
import ButtonText from '../atoms/ButtonText'

const DeactivateButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DE3355;
    box-shadow: 4px 4px 0px #DDE0E4;
    border-radius: 10px;
    width: 139px;
    height: 40px;
   
`

const InvoiceText = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0733333px;
    color: #29877D;
`

const PremiumPlan = (props) => {
    const [invoices, setInvoices] = useState([])
    const [toggleInvoices, setToggleInvoices] = useState(false)
    const UserAuthContext = useAuth()
    const WorkspaceName = UserAuthContext.state.label

    let StartDate = props.data.fetchWorkspaceSubscriptions[0].currentPeriodStart.toString()
    let EndDate = props.data.fetchWorkspaceSubscriptions[0].currentPeriodEnd.toString()
    StartDate = StartDate.slice(0, -3)
    EndDate = EndDate.slice(0, -3)
    StartDate = parseInt(StartDate)
    EndDate = parseInt(EndDate)
    StartDate = moment.unix(StartDate).format('MMMM Do YYYY')
    EndDate = moment.unix(EndDate).format('MMMM Do YYYY')

    useEffect( () => {
        const fetchInvoices = async () => {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices`)
          setInvoices(response.data.data)
        }
        fetchInvoices()
    
      }, [])

    const handleSubmit = () => {
        alert('Please contact Morgan@gitbabel.com if you wish to cancel your subscription')
    }

    const InvoiceDisplay = invoices.map((invoice, index) => {
        return (
            <ul key={index}>
                <li><b>Date:</b> {moment.unix(invoice.created).format('dddd, MMMM Do YYYY')}. <a href={invoice.invoice_pdf} target="_blank" rel="noreferrer noopener">View Invoice</a></li>
            </ul>
        )
    })

    const InvoiceViewText = toggleInvoices ? (<InvoiceText> {'>'} Hide Past Invoice Statements</InvoiceText>) : (<InvoiceText> {'>'} View Past Invoice Statements</InvoiceText>)
    


    return (
        <>
        <PlanText>The <b>{WorkspaceName}</b> workspace is on the <b>Pro Monthly Plan</b></PlanText>
        <PlanText><b>Pro Monthly Plan</b> includes the following:</PlanText>
        <ul>
        <PlanItem>Unlimited Public/Private Repositories</PlanItem>
        <PlanItem>Unlimited Storage</PlanItem>
        <PlanItem>100 Git-Contributors</PlanItem>
        </ul>
        <PlanText>Originally <i>activated</i> on {StartDate}, next charge will be on {EndDate}</PlanText>
        {/* <form onSubmit={handleSubmit}>
            <DeactivateButton><ButtonText>Deactivate</ButtonText></DeactivateButton>
        </form> */}
        <div onClick={() => setToggleInvoices(!toggleInvoices)}>{InvoiceViewText}</div>
        {(toggleInvoices === true) && InvoiceDisplay}
        <PlanText>Are you an open-source project? Message us! `Typeform link here`</PlanText>
        </>
    )
}

export default PremiumPlan