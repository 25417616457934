import elasticlunr from 'elasticlunr'

var index = elasticlunr(function () {
  this.addField('title')
  this.addField('body')
  this.setRef('uuid')
  this.saveDocument(false)
})

export function searchIndex (term) {
  return index.search(term, {
    fields: {
      title: {
        boost: 2
      },
      body: {
        boost: 1
      }
    },
    expand: true
  })
}

export let indexedSearchData = []
export function addDocToSearchIndex (dataSource) {
  let entry = {}
  dataSource.map((record) => {
    const uuid = record.uuid

    entry = {
      uuid: record.uuid,
      title: record.collectionTitle || record.title,
      body: record.description || ''
    }

    // check if record already exists
    const where = indexedSearchData.findIndex((item) => {
      return item.uuid === uuid
    })

    // if record already exists then update
    if (where !== -1) {
      indexedSearchData[where] = entry
    } else {
      indexedSearchData.push(entry)
    }
    
    index.addDoc(entry)

    return
  })
  // console.log('boom', indexedSearchData)
}