// React Include
import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'
import { useAuth } from '../../../Context/authUserContext'
import { LEGACY } from '../../../core/colors'

// [CORE]
// -- Molecules
import RepositoryBadge from '../../../core/molecules/RepositoryBadge'
import OwnerBadge from '../../../core/molecules/OwnerBadge/OwnerBadge'

const TRAILING_MARGIN = '0 2.8em 0 0'
const DefinedList = styled.dl`
  display: flex;
  flex-direction: column;
  margin: ${props => props.noTrailingMargin ? '0' : TRAILING_MARGIN};
  padding: 0;
  position: relative;
  top:-.2em;
`
const DefinedTitle = styled.dt`
  font-size: .9em;
  color: #A2A2A2;
  letter-spacing: -0.11px;
  margin: 0;
  padding: 0;
`

const DefinedDescription = styled.dd`
  font-size: .9em;
  color: ${LEGACY};
  letter-spacing: -0.15px;
  // give a little breathing room between dt/dd
  padding: 0 0 0 0;
  margin:0;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;`

function CollectionDetailHeader (props) {
  const UserAuth = useAuth()
  const org = UserAuth.state.label
  /* wrap up the header which is the title and repo/owner/meta info */
  return (
    <div style={{ display: 'flex', paddingBottom: '.8em', alignItems: 'center'}}>
      {/* flexbox this thing and need a badge panel for owner/config */}
      <h1 style={{ display: 'flex', flexGrow: 1, margin: 0 }}>{props.title}</h1>
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '.5em' }}>
        <DefinedList>
          <DefinedTitle>Owner</DefinedTitle>
          <DefinedDescription>
            <Row>
            <OwnerBadge org={org} removePadding={true}/>
            {org}
            </Row>
            </DefinedDescription>
        </DefinedList>
        <DefinedList noTrailingMargin>
          <DefinedTitle>Configuration</DefinedTitle>
          <DefinedDescription>
            <RepositoryBadge>{props.repository}</RepositoryBadge>
          </DefinedDescription>
        </DefinedList>

      </div>

    </div>
  )
}

CollectionDetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  repository: PropTypes.string.isRequired
}

export default CollectionDetailHeader
