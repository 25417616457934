import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { SPACE_GREY } from '../../core/colors'

// start background 2px down, the SVG tiles we layer in on our Circle/Primitives has a 4px rim (realizing we could maybe squash it)
const ActivityProgress = styled.div`
  display: flex;
  background: transparent url('/activity_rail_bg.png') no-repeat center 4px;
  flex: 0 1 32px;
  margin: 0 8px 0 0;
  //border-bottom: 2px solid ${SPACE_GREY};

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    margin-right: 40px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const Component = (props) => {
  return (
    <ActivityProgress>
      {props.children}
    </ActivityProgress>
  )
}

Component.propTypes = {
  children: PropTypes.element.isRequired
}

export default Component
