
import React from 'react'
/*
 *  The Topic Reference Glyph
 */
const TopicReferenceGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (

    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/reference/standard24' stroke={strokeColor} strokeWidth='1.5'>
            <g id='newspaper'>
              <rect id='Rectangle-path' x='0.75' y='0.75' width='22' height='22' rx='1.5' />
              <path d='M13.5,13.5 L19.5,13.5' id='Shape' />
              <path d='M13.5,16.5 L19.5,16.5' id='Shape' />
              <path d='M13.5,19.5 L17.25,19.5' id='Shape' />
              <path d='M3.75,13.5 L9.75,13.5' id='Shape' />
              <path d='M3.75,16.5 L9.75,16.5' id='Shape' />
              <path d='M3.75,19.5 L7.5,19.5' id='Shape' />
              <rect id='Rectangle-path' x='3.75' y='3.75' width='16.5' height='6' rx='0.75' />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicReferenceGlyph
