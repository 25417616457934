import styled from 'styled-components/macro'

// Is hoisted at the App.js ROOT LEVEL
// Initializes Div that creates Flexbox and sets our application to Full Height
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export default AppContainer
