import React from 'react'
import AppPage from '../pages/appPage';
import SubscriptionForm from '../../stripe/organisms/SubscriptionForm';



const CheckoutView = () => {
  return (
      <AppPage>
          <SubscriptionForm />
      </AppPage>
  )

}
export default CheckoutView

