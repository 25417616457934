import React from 'react'
/*
 *  The GitRepository is our base
 *
 *   Trying different sizes
 *   <svg width="15px" height="15px" viewBox="0 0 15 21" version="1.1">
 *
 *   Removed the normal `<div {}>` wrapper we've been putting around our svg icons, was messing with flex box
 */

// TODO drop in color-theme variable here (currently space-grey based on style guide)
const GitRepository = () => (
  <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='ui/atoms/glyph/repository' fill='#A3A9B1' fillRule='nonzero'>
        <path d='M5,11.8125 L3.75,11.8125 L3.75,10.5 L5,10.5 L5,11.8125 Z M5,7.875 L3.75,7.875 L3.75,9.1875 L5,9.1875 L5,7.875 Z M5,5.25 L3.75,5.25 L3.75,6.5625 L5,6.5625 L5,5.25 Z M5,2.625 L3.75,2.625 L3.75,3.9375 L5,3.9375 L5,2.625 Z M15,1.3125 L15,17.0625 C15,17.784375 14.4375,18.375 13.75,18.375 L7.5,18.375 L3.75,18.375 L1.25,18.375 C0.5625,18.375 0,17.784375 0,17.0625 L0,1.3125 C0,0.590625 0.5625,0 1.25,0 L13.75,0 C14.4375,0 15,0.590625 15,1.3125 Z M13.75,14.4375 L1.25,14.4375 L1.25,17.0625 L3.75,17.0625 L3.75,15.75 L7.5,15.75 L7.5,17.0625 L13.75,17.0625 L13.75,14.4375 Z M13.75,1.3125 L2.5,1.3125 L2.5,13.125 L13.75,13.125 L13.75,1.3125 Z' id='Shape' />
      </g>
    </g>
  </svg>
)

export default GitRepository
