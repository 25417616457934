
import React from 'react'

import {LEGACY, NIGHTS_WATCH} from '../../colors'
/*
 *  Circle Primitive defauts (32x32) -- using as a background for Bonvoy's Icons
 */

const Circle = () => {
  return (<div style={{}}>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="16" fill="white" stroke={LEGACY} strokeWidth="1.5"  strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </div>)
}


export default Circle


