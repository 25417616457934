import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { useAuth } from '../../Context/authUserContext';

//Atoms
import FreePlan from '../molecules/FreePlan';
import PremiumPlan from '../molecules/PremiumPlan'
import GreySkinnyRectangle from '../../core/atoms/skinnyGreyRectangle';
import { GET_WORKSPACE_SUBSCRIPTION } from '../../lib/queries/subscription';

//Molecules
import Loader from '../../core/molecules/Loader';

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`

const BackText = styled.h4`
    padding: 0;
    margin: 0 0 2em 0;
    color: grey;
`
const Heading = styled.h1`
    padding: 0 0 0.5em 0;
    margin: 0;
    font-family: Roboto;
`
const Subheader = styled.span`
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-family: Open Sans;
`

const SubscriptionForm = (props) => {
    let history = useHistory();
    const UserContext = useAuth()

  const { loading, error, data } = useQuery(GET_WORKSPACE_SUBSCRIPTION, { variables: { workspaceUuid: UserContext.state.workspace} })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }

  let WorkspaceIsPremium

 if (data.fetchWorkspaceSubscriptions.length === 0) {
     WorkspaceIsPremium = false
 } else {
  //Comparing timestamps, if the current date is greater than the start date
  //and less than the end date, then we know it is Active
  //!FollowUp: We need to make sure that we only return the most RECENT result for a workspace.
  //Or we will need to loop through and make sure we are pulling the newest result

  const now = Date.now()
  const start = data.fetchWorkspaceSubscriptions[0].currentPeriodStart
  const end = data.fetchWorkspaceSubscriptions[0].currentPeriodEnd
  if (( now > start)  && (now < end)) {
      WorkspaceIsPremium = true
  } else {
      WorkspaceIsPremium = false
  }
 }

//  console.log(WorkspaceIsPremium)
    const back = () => {
        history.push('/console')
    }

  return (
  <>
    <FlexCol>
        <div onClick={back}>
            <BackText> {'<'} Back </BackText>
        </div>
        <Heading>Subscription</Heading>
        <Subheader>Plan Summary</Subheader>
        <GreySkinnyRectangle/>
        {WorkspaceIsPremium ? (<PremiumPlan data={data}/>) : (<FreePlan/>)}
    </FlexCol>
   </>
  )
}
export default SubscriptionForm