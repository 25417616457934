// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// [core]
import { WHITE, SPACE_GREY, LEGACY, SLUSH, KONAMI } from '../../../core/colors'
// - Atoms
// - icons
// import Icon from '../../core/atoms/icon';

// - misc
import StyledLink from '../../../core/atoms/styledLink'
// [core] Molecules
import RepositoryBadge from '../../../core/molecules/RepositoryBadge'

// [doc-ops]
// Local Molecules
import CollectionTopicBadge from '../categoryListBadge'

import { getUrlSlug } from '../../../lib/utils'

// We currently anchor this body wrapper to 48%, this gives us two columns
const ListSummaryBody = styled.div`

  display: flex;
  flex-direction: column;
  // give us the waffle grid we want
  padding: .8em .8em .8em .8em;
  flex-basis: 20%;
  // disable grow, and change CollectionListSummary to distribute-evenly
  // flex-grow:1;
  margin:0 .8em .8em 0;
  min-width: 330px;
  //max-width: 450px
  background-color: ${WHITE};
  // originally #e5e5e5
  border: 1px solid #e5e5e5;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  border-radius: 4px;
`

const SummaryHeader = styled.h3`
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 23px;
    color: ${LEGACY};
    letter-spacing: -0.17px;
`

const SummaryText = styled.p`
    margin: .4em 0 .8em 0;
    font-size: 14px;
    color: ${LEGACY};
    font-weight: 500;
    font-family: "Open Sans"
`

// put the status here, but it affects the padding
const CollectionStatus = styled.span`
  font-weight:bold;
  display:flex;
  align-self: flex-end;
  color: ${SPACE_GREY};
`

const CollectionFooter = styled.div`
  border-top:4px solid #F8F9FA;
  display: flex;
  margin-top: auto;
  padding-top: .5em;
`

// was using to display the latest timestamp (deferred)
const CollectionFooterLabel = styled.span`
  font-style: italic;
  font-size:.8em;
  color: ${SLUSH};
  padding:0;
  margin:0;

`

/* thinking of designing this as it is handed a set of topics and it can handle rendering them properply */
class CollectionListSummaryItem extends Component {
  render () {
    // console.log('CollectionListSummaryItem: ',this.props);
    // should maybe wrap this so a string is generated if empty so react doesn't explode
    const collectionDetailId = this.props.uuid
    const urlSlug = this.props.urlSlug
    return (
      <ListSummaryBody>

        <SummaryHeader><StyledLink to={`/discovery/${urlSlug}-${collectionDetailId}`}>{this.props.title}</StyledLink></SummaryHeader>
        <SummaryText>{this.props.description}</SummaryText>
        <CollectionTopicBadge assets={this.props.assets} />
        <CollectionFooter>
          <RepositoryBadge>{this.props.repository}</RepositoryBadge>
        </CollectionFooter>
      </ListSummaryBody>
    )
  }
}

CollectionListSummaryItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  repository: PropTypes.string.isRequired,
  urlSlug: PropTypes.string
}

export default CollectionListSummaryItem
