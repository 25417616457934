import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Local / Core Atoms
import Label from '../../atoms/label'
import Icon from '../../molecules/Icon'
import Next from '../../atoms/glyphs/next'

import Activity from '../../../core/atoms/glyphs/sherpa/activity'
import Learning from '../../../core/atoms/glyphs/sherpa/learning'
import Workflow from '../../../core/atoms/glyphs/sherpa/workflow'
import StyledLink from '../../../core/atoms/styledLink'
import ProgressBar from '../../../core/atoms/progressbar'

// Bonvoy's NextButton
import NextButton from '../../atoms/NextButton'
import TaskCompletionButton from '../../atoms/TaskCompletionButton'
import PreviousButton from '../../atoms/PreviousButton'

import Circle from '../../../core/atoms/glyphs/circle'

import Rail from '../../templates/rail'
import Block from '../../templates/block'

import Intro from '../../molecules/Intro'
import Outro from '../../molecules/Outro'
import Card from '../../molecules/Card'

// we could get rid of this is the views are passing down the progressStateMap
// just need to link our `views/utils` functions into the actual contentView
import { progressTasksVar } from '../../../cache'

// Parking this here for now -- makes sense to move this up into the view
import { generateStateMapFromCache, generateDefaultStateMapFromApi } from '../../views/utils'

const LearningCountFrameMount = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-top:2em;
  
`

const LearningContentInnerFrame = styled.div`
  background: #fff;
  border: 1px solid #DDE0E4;
  border-radius: 4px;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  margin: 0 0 4px 0;
`

const LearningContent = styled.div`
  display: flex;
  flex-flow: column nowrap;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
  
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const LearningContentFrame = styled.div`
    padding: 0px 36px 36px;
`

// MOVE into a molecule -- the card stepper action-ribbon
// Will be mounted inside of the CardFrame
// wraps up the entire nextSteps container
const NextSteps = styled.div`
    display: flex;
    flex-direction: column;
    margin: 48px 0 0 0;
    border-top: 1px dotted #fcfcfc;
`

// acts as a wrapper to center the single button
// if we are adding back button this fame needs to change to row now
// and host two buttons
const NextFrame = styled.div`
    align-self: center;
    display: flex;
    
`

const NextIconFrame = styled.div`
    margin: 8px 0 0 0;
    align-self: center;
`

const IconList = styled.div`
  display: flex;
  flex-direction: column;
  width: 36px;
  
`

const IconItem = styled.div`
  margin: 0px;
  padding: 0px 0px 8px 0px;
  width: 24px;
`

const RailBlockFrame = styled.div`
  position:relative;
  padding-bottom: 1em;
  
`
const RailBlockOverlay = styled.div`
  z-index:100;
  position: relative;
  top: 8px;
  left: 8px;
`

const RailBlockUnderlay = styled.div`
  position:absolute;
  top:0;
  left: 0;
  z-index:2;
`

const CardCount = styled.p`
  padding: 0 0 44px 0;
  margin: 0;
  font-weight: 300;
  color: #ccc;
  text-align:center;
`

// Began stubbing a Flex Column here for us to add a third rail
const RightRail = styled.div`
  display: flex;
  flex: 0 0 20%;
  flex-direction: column;
  padding: 0 0 0 1em;
`

// wrap up the `IconITem` and `Circle`
const RailIconBlock = (props) => {
  return (
    <RailBlockFrame>
      <RailBlockUnderlay>
        <Circle />
      </RailBlockUnderlay>
      <RailBlockOverlay>
        {props.children}
      </RailBlockOverlay>

    </RailBlockFrame>
)
}

/*
 *  Reduced the margin-bottom on card-frame to accomodate the card-count component
 *  when on the intro-card though this margin is needed back because we don't render the card-count
 *  so need to have the props shift for the `<CardContentInnerFrame />` for if we have counts.
 */

/*
  ? Defining the CardStepper -- It handles the caraousel of rendering a CardFrame and rotating the Intro, Content, Outro
    card states. As well as rendering also the <Rail /> and
    - When rendering the card content there is the `{CardContent}` and `{ActionRibbon}`
  */
const CardStepper = (props) => {
  // console.log('CardStepper --', props)
  // we need the set of taskStatus for this CardStepper
  let taskStatusMap
  // right now only one task and section....

  // if cache is empty default to a generic shape that loads the first activity as active

  const currentUserActivityUuid = props.data.userActivityUuid
  const currentTopicUuid = (props.data.topicUuid) ? props.data.topicUuid : null
  // need the generateStatmap function that gives a basic shape again
  // check if we have a userActivity progress for the selected activity
  // so `localProgress` -- is still an array of all the userActivities
  // We need to get the position of the current activity we are viewing in respect to the cache
  // we need this to run our genator/Map for topics completed
  if (props.localProgress && props.localProgress.length > 0) {
    // so `localProgress` -- is still an array of all the userActivities
  // We need to get the position of the current activity we are viewing in respect to the cache
  // we need this to run our genator/Map for topics completed
    const currentUserActivityPosition = props.localProgress.findIndex(item => {
      return (item.userActivityUuid === currentUserActivityUuid)
    })

    // if no activity, we should again default to the API
    // but if activity found in cache use it
    // one more check, see if data.tasks is there -- means we can map from tasks
    // if no tasks, on data, means we are viewing a topic/card the first time
    // and
    if (currentUserActivityPosition === -1 && props.data.tasks) {
      taskStatusMap = generateDefaultStateMapFromApi(props.data)
    } else if (currentUserActivityPosition === -1 && props.data.topicUuid) {
      // don't do anything, no learning-tasks displaying
      // taskStatusmap needs to be a default here of the first topic as active .....
      // this just sorta works because by default we set the first topic to active by default...
      taskStatusMap = {}
    } else {
      taskStatusMap = generateStateMapFromCache(props.localProgress[currentUserActivityPosition])
    }
  } else {
    // if there is no local cache/state we want to use the api data, keep in mind we should
    // check to make sure there are sections/topics in this data before calling this
    // should also check here, if we are in topic-viewing mode, as need to do a default make first thing active
    if (props.data.topics) {
      // don't do anything, no learning-tasks displaying
      // taskStatusmap needs to be a default here of the first topic as active .....
      // taskStatusMap

      taskStatusMap = generateDefaultStateMapFromApi(props.data)
    }
  }

  // setup icons for rail
  const icons = props.rail.map(x => {
    // console.log('working through icons ', x, 'taskStatusMap:', taskStatusMap)
    // set the default
    let state = x.state
    // state gets abit complicated here
    // we lverage that currentTopicUuid should only be here (not null) if we are viewing a topic in the stepper
    // there-fore it is safe to scan through a taskStatusMap to see for each one there state...
    if (taskStatusMap && currentTopicUuid && x.uuid) {
      // check if this topic-id is in this statusMap as done
      if (taskStatusMap[x.uuid] && taskStatusMap[x.uuid].completed) {
        state = 'completed'
      }
    }
    switch (x.type) {
      case 'activity':
        return (<RailIconBlock key={'icon-' + Math.random()}><IconItem key={'icon-' + Math.random()}><Activity state={state} size='small' /></IconItem></RailIconBlock>)
      case 'workflow':
        return (<RailIconBlock key={'icon-' + Math.random()}><IconItem key={'icon-' + Math.random()}><Workflow state={state} size='small' /></IconItem></RailIconBlock>)
      case 'reference':
      default:
        return (<RailIconBlock key={'icon-' + Math.random()}><IconItem key={'icon-' + Math.random()}><Learning state={state} size='small' /></IconItem></RailIconBlock>)
    }
  })

  // Hoist our card-content into place
  let CardContent
  // temporary till we move into ActionRibbon
  // but need a place to hoist the button inside of switch
  // we can send it the wrong props
  let TheNextButton
  let ThePreviousButton

  switch (props.type) {
    // get defensive here, if props.data is missing or not the right shape render a place-holder
    case 'content':
      CardContent = <Card data={props.data} />
      TheNextButton = <NextButton to={props.nextURL} userActivityUuid={props.data.userActivityUuid} topicUuid={props.data.topicUuid} totalSections={props.data.totalSections} currentSection={props.data.sectionId} />
      ThePreviousButton = <PreviousButton to={props.previousURL} />
      break
    case 'completed':
      CardContent = <Outro activityTitle={props.data.activityTitle} />
      TheNextButton = <TaskCompletionButton to={props.nextURL} userActivityUuid={props.data.userActivityUuid} />
      break
    case 'landing':
    default:
      // Generate a stateMap Stub
      // TODO FOLLOWUP-- this needs a check to find the userActivityUuid in the cacheProgress

      // lot our cache

      // console.error('what!' , props.data)
      // pass in progress as its own prop and not part of data anymore ....
      CardContent = <Intro data={props.data} progress={taskStatusMap} />
      // Inside of landing card we just need to send the nextUrl
      TheNextButton = <NextButton to={props.nextURL} />
      break
  }

  return (
    <LearningCountFrameMount>

      <Rail>
        {/* replace with correct icons */}
        <IconList>{icons}</IconList>

      </Rail>
      <Block>
        <LearningContentInnerFrame>
          {/* TODO understand how this works on intro vs... rest of cards */}
          <ProgressBar fill={(props.data.sectionId / props.data.totalSections) * 100} />

          <LearningContent>
            <LearningContentFrame>
              {CardContent}

              {props.nextURL
                ? <NextSteps>
                  <NextFrame>
                    {ThePreviousButton}
                    {TheNextButton}

                  </NextFrame>

                </NextSteps>
                : ''}
            </LearningContentFrame>
          </LearningContent>
        </LearningContentInnerFrame>

        {props.data.sectionId
          ? <CardCount>{props.data.sectionId} / {props.data.totalSections}</CardCount>
          : ''}

      </Block>

    </LearningCountFrameMount>

  )
}

CardStepper.propTypes = {
  rail: PropTypes.array,
  data: PropTypes.object,
  type: PropTypes.string,
  nextURL: PropTypes.string,
  localProgress: PropTypes.array
}

export default CardStepper
