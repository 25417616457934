import styled from "styled-components"

const ButtonText = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.16px;
    color: #F8F9FA;
`

export default ButtonText