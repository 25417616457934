// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

// use gql function to generate a graphql template literal
export const GET_WORKSPACE_SUBSCRIPTION = gql`
query fetchWorkspaceSubscriptions ($workspaceUuid: ID!) {
    fetchWorkspaceSubscriptions (workspaceUuid: $workspaceUuid) {
        workspaceUuid,
        status,
        stripeCustomerId,
        stripeSubscriptionId,
        currentPeriodStart,
        currentPeriodEnd
    }
}
`