// Need React for our functional component
import React from 'react'
// Create Necessary presentation components wrappers
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// [core] Molecules
import RepositoryBadge from '../../core/molecules/RepositoryBadge/RepositoryBadge'
import TopicCategoryBadge from '../../core/molecules/TopicCategoryBadge'
import Icon from '../../core/atoms/Icon'

// Wraps our summary section with a flexbox so we can display as rows, believe each child component
// enables `flex-direction: column`
const TopicSummaryMetaFrame = styled.div`
  display: flex;
  flex-direction: row;
`

// Changed this to label, when ready make a version that can act as a Toggler to open paneling
// TODO color-theme variable here for padding, also this is duplicated inside of `core/molecule/repositoryBadge`
const TopicSectionCountLabel = styled.div`
  padding: .1em .2em 0 .3em;
  font-family: 'Open Sans';
  color: #6A6E74;
  letter-spacing: -0.09px;
  position:relative;
  top: -2px;
`

// TODO update so that it can take multiple params and change its visual appearance based on props
function TopicSummaryBadge (props) {
  // console.log('Topic Summary Meta (props): ', props)
  // Add logic to check for sections and default to removal
  // also handle the logic for adding a dividers between objects on presentation side
  let sectionLabel = ''
  if (props.sections && props.sections.length > 0) {
    sectionLabel = `${props.sections.length} Sections`
  }

  // Check if `category` was specified, if so we send back a SummaryMeta with the visual badge
  if (props.category) {
    return (
      <TopicSummaryMetaFrame>
        <TopicCategoryBadge category={props.category} />

        <RepositoryBadge>{props.repository}</RepositoryBadge>
        <TopicSectionCountLabel>{sectionLabel}</TopicSectionCountLabel>
      </TopicSummaryMetaFrame>
    )
  } else {
    return (
      <TopicSummaryMetaFrame>
        <RepositoryBadge>{props.repository}</RepositoryBadge>
        <TopicSectionCountLabel>{sectionLabel}</TopicSectionCountLabel>
      </TopicSummaryMetaFrame>
    )
  }
}

TopicSummaryBadge.propTypes = {
  sections: PropTypes.array.isRequired,
  repository: PropTypes.string.isRequired,
  category: PropTypes.string
}

export default TopicSummaryBadge
