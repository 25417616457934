
import styled from 'styled-components/macro'

/*
 * This is a special div that gives us a window-portal to have content / panels scrollable in an overflow panel
 *
 * TODO: not sure if this thing stays in organisms, maybe move into into a template...
 * it is used also by TopicPage as a portal for the scroll-able-content section..
 */
/* this is hooked into `appPage` right now, and for the Discovery we want to center as well as the Library, so lets fix this
  to be our centering-frame for the doc-ops stuff */
const AppMainSection = styled.div`
  display: flex;
  // Questions here about flex shortcut...
  flex: 1 1 auto;
  // This give us the ability to keep navigation mounted and allow us to scroll the central AppPanel
  overflow: auto;
  // debug border
  //border: 1px solid #ff0000;

  // Use to center an AppPanel container
  // Disabled this feature flag for removal
  //justify-content: center;
`

export default AppMainSection
