// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

import Emoji from 'react-emoji-render'

// Atoms
// Local / Core Atoms
import Header from '../../../bonvoy/atoms/header'
import Text from '../../../bonvoy/atoms/text'

const OutroFrameMount = styled.div`
  background: transparent url('card_outro_bg.png') no-repeat;
  display: flex;
  flex-direction: column;
  margin: 24px 68px;
  padding: 48px 0 0 0;
`

const HeaderFrame = styled.div`
  margin: 0 0 78px 0;
  text-align: center;
`

const TextFrame = styled.div`
  text-align: center;
`

/*
 * View Story for followup information
 */
function Outro (props) {
  return (
    <OutroFrameMount>
      <HeaderFrame><Header type='outro'>Completed: {props.activityTitle}</Header></HeaderFrame>
      <TextFrame>
        <Text><Emoji text="ok, small applause :clap:  We're just getting started :sunglasses:" /></Text>
      </TextFrame>
    </OutroFrameMount>
  )
}

Outro.propTypes = {
  activityTitle: PropTypes.string.isRequired

}

export default Outro
