import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/client'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

// Queries
import { GET_WORKSPACE_REPOS, UPDATE_REPOSITORY_MUTATION } from '../../../lib/queries/console'

// Atoms
import GitRepository from '../../atoms/glyphs/repository'
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import QuestionMark from '../../atoms/glyphs/questionMark'
import ConsoleFrame from '../../atoms/consoleFrame'
import ConnectedRepo from '../../atoms/glyphs/doc-ops/repository/repoConnected'
import DisconnectedRepo from '../../atoms/glyphs/doc-ops/repository/repoDisconnected'

// Moleclues
import Loader from '../Loader'

const TableColumn = styled.td`
    padding: .5em 0 .5em 0;
    border-bottom: 1px solid #E3E3E3;
    width: 175px;
`
const TableColumnTitle = styled.td`
    padding: .5em .5em .5em 0;
    border-bottom: 1px solid #E3E3E3;
    width: 40%;
    font-weight: 700;
`

const RepoSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 0em 1em 0em;
    background-color: white;
    `

const RepoItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `

const RepoBreakdownLayout = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    padding: 1em 0 1em 0;
    `

const BlueHighlight = styled.span`
    color: #24A5FF;
    `
const DesktopTableView = styled.div`
    display: none;
    @media (min-width:641px)  {
      display: block;
    }
    `

const MobileTableCardView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width:641px)  {
      display: none;
    }
    `
const MobileRepoCard = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 90%;
    border-bottom: 1px solid #ccc;
`

const MobileOptionsDisplay = styled.div`
    display: flex;
    flex-direction: row;
`

const MobileOptionColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 0 0 1em 0;
`

const MobileOptionItem = styled.span`
    font-weight: 700;
    padding: 1em 0 1em 0;
`

const RepoConfig = (props) => {
  const workspaceUuid = props.userdata.state.workspace


  // uuid: String!, $org: String!, $deleted: Boolean!)
  const [updateRepository, { mutationData, mutationLoading, mutationError }] = useMutation(UPDATE_REPOSITORY_MUTATION)


  // const { loading, error, data } = useQuery(GET_REPOS, { variables: { org: props.me.org } })
    const { loading, error, data } = useQuery(GET_WORKSPACE_REPOS, { variables: { workspaceUuid: workspaceUuid } })
    // gitBabel uuiD: 'e42d711e-85fe-11ec-a8a3-0242ac120002'

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }


  
  

  const Repos = data.fetchRepos
  const RepoTotal = data.fetchRepos.length

  let NoRepos
  if (RepoTotal === 0){
    return (
      <div>You have no ingested repositories</div>
    )
  }

  let active = 0
  let connected = 0
  for (let i = 0; i < RepoTotal; i++) {
    if (data.fetchRepos[i].repos.active === true) {
      active++
    }
    if (data.fetchRepos[i].repos.connected !== null) {
      connected++
    }
  }


  const RepoTable = Repos.map((repo, index) => {
    const RepoVisibility = repo.repos.private ? ('Private') : ('Public')
    const RepoDescription = repo.repos.description ? (repo.repos.description) : ('No Description Provided')
    let RepoConnected
    switch (repo.repos.connected) {
      case null:
        RepoConnected = <><DisconnectedRepo height={24} width={24} strokeColor='#a3a9b1' />Not Connected</>
        break
      case "\".gitbabel/config.json\"":
        RepoConnected = <a target='_blank' href={`https://github.com/${repo.repos.org}/${repo.repos.name}/blob/master/.gitbabel/config.json`} rel='noopener noreferrer'><ConnectedRepo height={24} width={24} strokeColor='green' /></a>
    }
    return (
      <tr key={index}>
        <TableColumn>{repo.repos.name}</TableColumn>
        <TableColumn>{RepoDescription}</TableColumn>
        <TableColumn>{repo.repos.org}</TableColumn>
        <TableColumn>{RepoVisibility}</TableColumn>
        <TableColumn>
          <Toggle
            id={index.toString()}
            defaultChecked={repo.repos.active}
            onChange={() => {
              const payload = {
                uuid: repo.repos.uuid,
                org: repo.repos.org,
                active: !repo.repos.active
              }

              const mutationObject = { variables: payload }
              updateRepository(mutationObject)
              // Mutation here using the payload
            }}
          />
          <label htmlFor='Active Toggle' />
        </TableColumn>
        <TableColumn>{RepoConnected}</TableColumn>
        {NoRepos}
      </tr>
    )
  })

  const RepoCard = Repos.map((repo, index) => {
    const RepoVisibility = repo.repos.private ? ('Private') : ('Public')
    let RepoConnected
    switch (repo.repos.connected) {
      case null:
        RepoConnected = <DisconnectedRepo height={24} width={24} strokeColor='red' />
        break
      case "\".gitbabel/config.json\"":
        RepoConnected = <ConnectedRepo height={24} width={24} strokeColor='green' />
    }
    return (
      <MobileRepoCard key={index}>
        <h1>{repo.repos.name}</h1>
        <h3>{repo.repos.org}</h3>
        <span>{repo.repos.description}</span>
        <MobileOptionsDisplay>
          <MobileOptionColumn>
          <MobileOptionItem>Visibility</MobileOptionItem>
          {RepoVisibility}
          </MobileOptionColumn>
          <MobileOptionColumn>
          <MobileOptionItem>Enabled</MobileOptionItem>
          <Toggle
          id={index.toString()}
          defaultChecked={repo.repos.active}
          onChange={() => {
            const payload = {
              uuid: repo.uuid,
              org: repo.org,
              active: !repo.active
            }

            const mutationObject = { variables: payload }
            updateRepository(mutationObject)
            // Mutation here using the payload
          }}
        />          
        </MobileOptionColumn>
        <MobileOptionColumn>
          <MobileOptionItem>Connected: </MobileOptionItem>
        {RepoConnected}
         </MobileOptionColumn>
        </MobileOptionsDisplay>
      </MobileRepoCard>
    )
  })

  return (
    <ConsoleFrame>
      <RepoItem><div style={{ paddingRight: '.75em' }}><GitRepository /></div> Repository Configurations</RepoItem>
      <GreySkinnyRectangle />
      <RepoBreakdownLayout>
        <div>Indexed: {active}/{RepoTotal}</div>
        <div>Connected: {connected}/{RepoTotal}
          {/* <QuestionMark /> */}
        </div>
      </RepoBreakdownLayout>
      {/* <BlueHighlight> > See Details</BlueHighlight> */}
      <DesktopTableView>
        <table>
          <thead>
            <tr>
              <TableColumnTitle>Name</TableColumnTitle>
              <TableColumnTitle>Description</TableColumnTitle>
              <TableColumnTitle>Owner</TableColumnTitle>
              <TableColumnTitle>Visibility</TableColumnTitle>
              <TableColumnTitle>Enabled</TableColumnTitle>
              <TableColumnTitle>Connected</TableColumnTitle>
            </tr>
          </thead>
          <tbody>
            {RepoTable}
          </tbody>
        </table>
      </DesktopTableView>
      <MobileTableCardView>
        {RepoCard}
      </MobileTableCardView>
    </ConsoleFrame>
  )
}

export default RepoConfig
