// Need React for our functional component
import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import ExitIcon from '../../atoms/glyphs/exit'

const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 5x 0;
`

const Term = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 185px;
`

const Header = styled.h3`
    margin: 0 0 10px 0;
    padding: 0;
`

const ExitIconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    padding: 10px;
`

function GlobalSearchSavedSearchTerms(props) {
    // console.log('props', props)
    function createTableDataRows (terms) {
        const rows = terms.map((term) => {
            if (term !=='') {
                return (
                    <Row key={Math.floor(Math.random() * 10000)}>
                        <Term onClick={() => props.retrieveSearchTermHandler(term)}>{term}</Term>
                        <ExitIconWrapper onClick={() => props.removeSearchTermHandler(term)}>
                            <ExitIcon />
                        </ExitIconWrapper>
                    </Row>
                    )
            }
        })
        return rows
    }

    const results = createTableDataRows(props.data)

    return (
       <div>
           <Header>Recent Searches</Header>
           {results}
        </div>
    )
}

GlobalSearchSavedSearchTerms.propTypes = {
    data: PropTypes.array,
    removeSearchTermHandler: PropTypes.func,
    retrieveSearchTermHandler: PropTypes.func
};

export default GlobalSearchSavedSearchTerms;