const _ = require('lodash')

const whiteListedTags = ['sub']

// remove < / >
function stripTag(string) {
    return string.replace(/</g, '').replace(/>/, '').replace(/\//g,'').replace(/ /g, '')
}

function findMatchingEndTag(searchArray, startPos, tagName) {
    // defaults to 0,  no matching tag
    let pos = 0
    for (let i = startPos; i < searchArray.length; i++ ) {
        const current = searchArray[i]
        if (current.type === 'html') {
            const isEndTag = current.value.indexOf('</') != -1 ? true : false
            if (isEndTag) {
                const currentTag = stripTag(current.value)
                if (currentTag === tagName) {
                    // returns position of matching
                    pos = i
                    break
                }
            }
        }
    }

    // assumes we have no found matching tag
    return pos
}

function analyzeChildren(sectionChildren) {
    const sanitizedChildren = []

    sectionChildren.forEach((child, index) => {
        if (child.type === 'html') {
            const isBeginTag = child.value.indexOf('</') === -1 ? true : false
            if (isBeginTag) {
                const tag = stripTag(child.value)

                // only process if tag is part of whiteListed
                if (whiteListedTags.indexOf(tag) !== -1) {
                    // slice between html tags
                    let matchingTagPos = findMatchingEndTag(sectionChildren, index+1, tag) 
                    
                    // if no matching tag is found, do nothing, json will not include this entry
                    // found matching tag, create a new json with the "text" as a child of html entry
                    if (matchingTagPos > 0) {
                        // takes entries in between tag to create children
                        let children = sectionChildren.slice(index+1, matchingTagPos)
                        // console.log('children', children)
                        // children = analyzeChildren(children)
                        
                        sanitizedChildren.push({
                            type: 'html',
                            value: tag,
                            children: children
                        })
                        // console.log('before sectionChildren', sectionChildren)
                        // console.log('index+1', index+1)
                        // console.log('matchingTagPos', matchingTagPos)

                        // remove children objects from origin json
                        for (let n = 0; n < (matchingTagPos-index); n++) {
                            // console.log('n', n)
                            _.pullAt(sectionChildren, index+1)
                        }

                        // console.log('removedChildren', removedChildren)
                        // console.log('after sectionChildren', sectionChildren)
                    }
                }
            }
        } else {
            sanitizedChildren.push(child)
        }
    })

    return sanitizedChildren
}

function analyzeSectionsForTags(dataJson) {
    // console.log('dataJson', dataJson)
    const sanitizedJson = []

    // check children for html
    dataJson.forEach(section => {
        if (section.type === 'paragraph') {
            sanitizedJson.push({
                type: section.type,
                children: analyzeChildren(section.children)
            })
        } else {
            sanitizedJson.push(section)
        }
    })
    // console.log('sanitizedJson', sanitizedJson)
    return sanitizedJson
}

module.exports = {
    analyzeSectionsForTags: analyzeSectionsForTags
}