// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Core Imports
import { WHITE } from '../../../core/colors'

import Label from '../../atoms/label'
import Header from '../../atoms/header'
import Separator from '../../atoms/separator'
import ActivityEstimate from '../../molecules/ActivityEstimate'
import Icon from '../Icon'
import PhaseIcon from '../../../core/atoms/glyphs/sherpa/phase'
import BreadCrumbNav from '../BreadCrumbNav/BreadCrumbNav'

const SectionHeaderFrameMount = styled.div`
`

const SectionHeaderWBorderFrameMount = styled.div`
  border: 1px solid #DDE0E4;
  border-radius: 4px;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  flex-basis: 100%;
  margin: 0px 0;
  padding: 20px 36px 20px 28px;
  background-color: #fcfcfc;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    padding-right: 58px;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const SectionDetails = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 8px 0 0 0;
`

const LabelFrame = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const PhaseIconFrame = styled.div`
  margin: 4px 4px 0 0;
`

/*
 * Currently Section header is built to be used in both Top/Bottom regions of Phaser & Sherpa
 * The shape needs to change depending on when/where it is being rendered
 *
 * Delineate between Top/Bottom by specifying the `style=border`
 *
 * =Phaser=
 *  - Top  breadCrumb:(Current Phase)
 *  - Bottom (Next Phase) + aggregate of Phase?
 * =Sherpa=
 *  - Top BreadCrumb:(Current Phase & Current Activity)
 *  - Bottom (Next Activity)
 */
function SectionHeader (props) {
  let details
  let locator
  // This is best described as PhaseSummary and should get packaged as a component
  // Has some visual issues
  if (props.details) {
    details = (
      <SectionDetails>
        <ActivityEstimate>20</ActivityEstimate>
        <Separator />
        <Icon type='time'>{props.timeEstimate}</Icon>
      </SectionDetails>
    )
  }

  if (props.breadCrumbLinks) {
    locator = (
      <BreadCrumbNav links={props.breadCrumbLinks} />
    )
  } else {
    locator = (
      <LabelFrame>
        <PhaseIconFrame>
          <PhaseIcon />
        </PhaseIconFrame>
        <Label type='medium'>{props.label}</Label>
      </LabelFrame>
    )
  }

  let content
  // Border happens when it is mounted to the bottom
  if (props.style === 'border') {
    content = (
      <SectionHeaderWBorderFrameMount>
        {locator}
        <Header type='section'>{props.header}</Header>
        {details}
      </SectionHeaderWBorderFrameMount>
    )
  } else {
    content = (
      <SectionHeaderFrameMount>
        {locator}
        <Header type='section'>{props.header}</Header>
        {details}
      </SectionHeaderFrameMount>
    )
  }

  return content
}

SectionHeader.propTypes = {
  label: PropTypes.string,
  header: PropTypes.string.isRequired,
  style: PropTypes.string,
  details: PropTypes.bool,
  timeEstimate: PropTypes.string,
  breadCrumbLinks: PropTypes.object
}

export default SectionHeader
