// React Include
import React, { useState } from 'react'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'

// Got some styling to do initially
import styled from 'styled-components/macro'
import Loader from '../../core/molecules/Loader'
import AppPage from '../../core/pages/appPage'

import { GET_TOPICS_BY_WORKSPACE_FOR_LIBRARY, GET_TOPICS_QUERY_FOR_LIBRARY } from '../../lib/queries/doc-ops'

import LibraryListing from '../organisms/libraryListing'

import { searchIndex, addDocToSearchIndex } from '../../lib/search'

import { useAuth } from '../../Context/authUserContext'

// TODO
// Pull this version of MainSectionFrame from `DiscoveryView` (also in ReportView) and added the direct:column
// Think this now makes is consistent across all views, package into the `core/templates`
const MainSectionFrame = styled.div`
  
  display:flex;
  flex-grow: 1;
  //flex-basis: 90%;
  //max-width:1000px;
  // TODO get rid of?
  //padding-right: 1em;
  // debug border
  //border: 1px dotted #333
  flex-direction:column;
  
`

function LibraryView (props) {
  /*
     *  NOTE: Eventually we will have filtering mounted here and it will be wrapped around the document filtering below in
     *        how docs are handed down to the topicGenre blocks
     *
     *  1. Inside this View we have access to the router props, once we hand-off to Organism, we will no longer have it
     *     so need to marshall our router-props to Organism.  This should be a pattern going-forward to marshall router props
     *     into values for the rest of the children nodes.
     */
  console.log('Library [CATEGORY ALL, GROUPBY Genre]:', this)
  // unpackage the react-router splat params from `match` key
  // const routerParams = props.match
  // get our section (collection, topics, stacks)
  // !todo delete the extra section code
  // the `:section` slug was removed...
  // const section = routerParams.params.section;
  // const detailId = (routerParams.params.detailId)? routerParams.params.detailId: '';
  // console.log('[Libray Router]')
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState({ value: 'all', label: 'all' })
  const [selectedOwner, setSelectedOwner] = useState({ value: 'all', label: 'all' })
  const [selectedRepo, setSelectedRepo] = useState({ value: 'all', label: 'all' })

  const UserAuthContext = useAuth()
  const workspace = UserAuthContext.state.workspace

  // const { loading, error, data } = useQuery(GET_TOPICS_QUERY_FOR_LIBRARY)
  const { loading, error, data } = useQuery(GET_TOPICS_BY_WORKSPACE_FOR_LIBRARY, { variables: { workspaceUuid: workspace} })

  // console.log('GraphQL GET_TOPICS_QUERY -->', data)
  // TODO: UI WORK -- create a real loader
  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) return `Error!: ${error}`

  // console.log('payload', data)
  // Marshall our data to send down into our panel
  // topics (raw)
  const topics = data.fetchWorkspaceTopics
  // console.log('show me topics', topics)

  // create filter dropdown options
  const categoryList = [{ value: 'all', label: 'all' }]
  const ownerList = [{ value: 'all', label: 'all' }]
  const repoList = [{ value: 'all', label: 'all' }]
  const filterDeDup = []
  topics.forEach((topic) => {
    // console.log(topic.topics.type)
    if (filterDeDup.indexOf('cat-' + topic.topics.type) === -1 &&
        topic.type !== '') {
      filterDeDup.push('cat-' + topic.topics.type)
      categoryList.push({
        value: topic.topics.type,
        label: topic.topics.type
      })
    }
    if (filterDeDup.indexOf('owner-' + topic.topics.owner) === -1 &&
        topic.topics.owner !== '') {
      filterDeDup.push('owner-' + topic.topics.owner)
      ownerList.push({
        value: topic.topics.owner,
        label: topic.topics.owner
      })
    }
    if (filterDeDup.indexOf('repo-' + topic.topics.repository) === -1 &&
        topic.topics.repository !== '') {
      filterDeDup.push('repo-' + topic.topics.repository)
      repoList.push({
        value: topic.topics.repository,
        label: topic.topics.repository
      })
    }
  })

  // add topics to search
  addDocToSearchIndex(topics)

  // filter topics based on search results
  let filteredTopics = topics
  if (searchTerm !== '') {
    // console.log('searchTerm', searchTerm)
    const searchResults = searchIndex(searchTerm)
    // console.log('searchResults', searchResults)
    filteredTopics = searchResults.map((record) => {
      // console.log('record', record)
      for (const topic of topics) {
        // console.log('topic', topic)
        if (record.ref === topic.uuid) {
          return topic
        }
      }
    })
  }

  // filter by dropdowns
  if (selectedCategory.value !== 'all') {
    filteredTopics = filteredTopics.filter((topic) => {
      return (topic.topics.type === selectedCategory.value)
    })
  }

  if (selectedOwner.value !== 'all') {
    filteredTopics = filteredTopics.filter((topic) => {
      return (topic.topics.owner === selectedOwner.value)
    })
  }

  if (selectedRepo.value !== 'all') {
    filteredTopics = filteredTopics.filter((topic) => {
      return (topic.topics.repository === selectedRepo.value)
    })
  }

  // To handle mounting
  return (
    <AppPage>
      <MainSectionFrame>
        <LibraryListing
          selectedCategory={selectedCategory}
          categoryList={categoryList}
          setSelectedCategory={setSelectedCategory}
          selectedOwner={selectedOwner}
          ownerList={ownerList}
          setSelectedOwner={setSelectedOwner}
          selectedRepo={selectedRepo}
          repoList={repoList}
          setSelectedRepo={setSelectedRepo}
          topics={filteredTopics}
          setSearchTerm={setSearchTerm}
        />
      </MainSectionFrame>
    </AppPage>
  )
}

export default LibraryView
