import React from 'react'

const CloseButton = (props) => {
  // const FILL_COLOR = (props.fillColor ? props.fillColor : white)

  return (

    <div onClick={props.onHandleClick}>
      <svg width='36' height='36' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12.998 23.25C19.2112 23.25 24.248 18.2132 24.248 12C24.248 5.7868 19.2112 0.75 12.998 0.75C6.78478 0.75 1.74799 5.7868 1.74799 12C1.74799 18.2132 6.78478 23.25 12.998 23.25Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.49799 16.5L17.497 7.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.498 16.5L8.49701 7.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  )
}

export default CloseButton
