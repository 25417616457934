import React from 'react'

const Logout = () => {
  return (
    <svg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.75 11.004H16.5' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.75 14.754L16.5 11.004L12.75 7.25403' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.30603 15.6C5.43039 19.9642 10.2691 22.3036 15.0089 21.2579C19.7487 20.2123 23.1541 16.0543 23.2451 11.2014C23.3361 6.34852 20.0891 2.0657 15.3919 0.843055C10.6946 -0.37959 5.77157 1.77663 3.48503 6.05805' stroke='#343639' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default Logout
