/*
 * ProgressState is an interface to build a testable layer between how we manage and implement
 * state storage and local-storage
 * - Specifically used to track UserActivity & UserContentTask interactions inside of react-client
 *
 *
 * Should export a few functions
 * - Should be useable inside of `cache.js` to help hydrate the reactive-variable with local-storage data
 * - Should be useable from NextButton to handle writing data to local-storage
 * - For different states/events it needs to mungie the data representation and store updates back into reactive-var
 */

export const PROGRESS_STATE_LOCAL_STORAGE_KEY = 'progressTasks'

export const loadProgressStateFromLocal = () => {
  // load and parse
  // returns object or null
  let localStoragePayload = JSON.parse(window.localStorage.getItem(PROGRESS_STATE_LOCAL_STORAGE_KEY))
  // if there is a fail comes back as null, we should enabled and empty cache
  if (localStoragePayload === null) {
    localStoragePayload = []
  }
  return localStoragePayload
}

// handle Content-Section/TopicView <-- insert topicId into `sectionView` for that activity/content-task/topic
// params -- needs the event interactions (userActivityUuid, topicUuid, currentSection, totalSections)
// params -- mutableState is the actual state from reactiveVar/cache.js
// will ultimately update the userProgression object with active/completed flags for each Topic as sections are completed
export const handleContentSectionView = (mutableState, userActivityUuid, topicUuid, currentSection, totalSections) => {
  if (!userActivityUuid || !topicUuid || !currentSection || !totalSections) {
    return null
  }
  // console.log('STATE:', mutableState)
  // console.log('Param [userActivityUuid]:', userActivityUuid)

  // 1. Find UserActivityRecord matchup in cache
  // 1.2 Find for that activityRecord the `topic`
  // -- 2. if we can't locate these matchups, we generate stubs
  //       else we have the matches and we can modify the records
  // 3.1 we put our stub into mutableState (todo-- may need updates for multi-activity)
  // 3.2 We edit the userActivity directly which has a pointer ref shared from mutator

  // usng our userActivityID -- should be able to find the activity in cache
  // that we need to update
  // update our mutator
  // 1. first lookup progress record by uuid
  const userActivity = mutableState.find(item => {
    return (item.userActivityUuid === userActivityUuid)
  })

  // if userActivity is undefined/empty, means we don't have a record for it
  // this is an issue for now, but we should eventually pre-populate the records
  // but for now we will generate and empty place=holder and mount the current sectionId as viewed
  // console.log('what is userStateAtivity?', userActivity)
  let sectionProgressRecord
  // For 1.2, we don't bother looking for Topic if we don't have the activity, generate the entire stub
  if (!userActivity) {
    // 1. Build a new userActivity Record to story along with the topic/section
    // 2. ALSO, append any existing userActivityRecords from mutableState back onto this object
    // just because we don't have this activity, doesn't mean there are potentially other activities in current state
    // merge the arrays together
    //
    sectionProgressRecord = [currentSection]
    // don't forget we need to create the user activity we are missing
    // -- NOTE we now check to see if there is just one section, mark this as completed and active:false...
    const userActivityStub = {
      userActivityUuid: userActivityUuid,
      // by default start an activity as true once you start digging into it
      active: true,
      completed: false,
      topics: [
        {
          uuid: topicUuid,
          sectionsViewed: sectionProgressRecord,
          // if more than one section mark as active, if _only one_ set to inactive
          active: ((totalSections > 1)),
          // if there is only one section then mark as completed...
          completed: ((totalSections === 1))
        }
      ]

    }

    // 3.1 todo still need to merge activities in... think we need ...mutableState still
    mutableState = [...mutableState, userActivityStub]
  } else {
    // 1.2 check if Topics exist, if not we will need to generate, otherwise if it is here we
    //     need a pointer to so we can get the sectionsViewed we need now
    // console.log('confused by this rendering--', userActivity)
    const currentTopic = userActivity.topics.find(topicItem => {
      return (topicItem.uuid === topicUuid)
    })

    if (!currentTopic) {
      /// / capture our current Section into a new topic.sections
      // sectionProgressRecord = [currentSection]
      // console.log('have topics? ', userActivity.topics)

      // Similar to creating a full-activity+topic-view (see above), if it is a single section
      // default to (active:false, completed:true) otherwise set active:true and completed: false
      userActivity.topics.push({
        uuid: topicUuid,
        sectionsViewed: [currentSection],
        // if more than one section mark as active, if _only one_ set to inactive
        active: ((totalSections > 1)),
        // If it is a single section topic default to completed
        completed: ((totalSections === 1))
      })

      // this is special else -- here they could of completed the tasks
      // either they looking at last section
      // note here, if it is last section we should check if this activity is complete... but this misses
      // single section topics from above as well...
    } else {
      sectionProgressRecord = [...currentTopic.sectionsViewed, currentSection]
      // 3.2 mutate the record (userActivity is a pointer back to mutableState)
      currentTopic.sectionsViewed = sectionProgressRecord
      // if our progressRecord length mathces totalSctions means we viewed everything for this topic
      if (sectionProgressRecord.length === totalSections) {
        currentTopic.completed = true
        currentTopic.active = false
      }
    }
  }
  return mutableState
}

// handle OutroView <-- when we are on the OutroCard track that as finishing the activity potentially -- could redirect maybe ..
// Wired to the `Done/Completed` button on the OutroCard for a ContentTask, this initial implementation is somewhat No-Op in that it
// will just mark the userActivity completed.  It won't do any checking that each Topic was viewed...
export const handleContentTaskCompletion = (mutableState, userActivityUuid) => {
  // first find out userActivity in the mutableState -- do the lookup by index since we will want to edit in place
  const userActivityPosition = mutableState.findIndex(item => {
    return (item.userActivityUuid === userActivityUuid)
  })
  mutableState[userActivityPosition].active = false
  mutableState[userActivityPosition].completed = true
  return mutableState
}

// pass it in the mutated value
// it will write to local-storage and update the cache/reactiveVar
export const presistProgressStateLocalStorage = (valueSet) => {
  // When writing to localStorage need to `stringify` the json-objects
  window.localStorage.setItem(PROGRESS_STATE_LOCAL_STORAGE_KEY, JSON.stringify(valueSet))
}
