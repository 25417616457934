// Do some styling
import styled from 'styled-components/macro'
// Get our COLORS
import { HEDGEWIG } from '../../colors'

// Set a Padding inside of the AppPanel frame
// Used on <AppPage /> but not <TopicPage />
const BOX_PADDING = '1em 1em 1em 1em'

/*
 * The Entry Point into the MainApp Panel
 * Set our Panel to use `column` and to grow the full-width
 * NOTE: Set the default bg-color SLUSH
 */
const AppPanel = styled.div`
  display: ${props => props.mobileNavOpen ? 'none' : 'flex'};
  flex-grow:1;
  flex-direction: column;
  background-color: ${HEDGEWIG};
  padding: ${props => props.boxPadding ? props.boxPadding : BOX_PADDING};
  //DEBUG: yellow border
  //border:2px dashed #ff0000;
`

export default AppPanel
