import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const LabelLarge = styled.div`
  color:  #343639;
  padding:0 0 .2em 0;
  display: block;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase; 
  font-family: Open Sans;
`

const LabelMedium = styled.div`
  color:  #343639;
  padding:0 0 .2em 0;
  display: block;
  font-weight: 600;
  font-size: .875em;
  text-transform: uppercase;
  font-family: Open Sans;
`

const LabelSmall = styled.div`
  color:  #343639;
  display: block;
  padding:0 0 .2em 0;
  font-weight: 300;
  font-size: .75em;
  text-transform: uppercase;
  font-family: Open Sans;
`

const Component = (props) => {
  switch (props.type) {
    case 'large':
      return (
        <LabelLarge>
          {props.children}
        </LabelLarge>
      )
    case 'medium':
      return (
        <LabelMedium>
          {props.children}
        </LabelMedium>
      )
    case 'small':
    default:
      return (
        <LabelSmall>
          {props.children}
        </LabelSmall>
      )
  }
}

Component.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default Component
