import React from 'react'
import AppPage from '../pages/appPage'

// Import our Console View
import ConsolePage from '../pages/consolePage'

const ConsoleView = () => {
  return (
    <AppPage>
      <ConsolePage />
    </AppPage>
  )
}

export default ConsoleView
