// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

/*
 *  When writing graphQL queries that have params, one should write it using OperationalNames
 *  https://graphql.org/learn/queries/#operation-name  Apollo requires one to write them this way
 *  also use this as reference -- https://www.apollographql.com/docs/react/essentials/queries/#data
 */
export const SEARCH_ALL = gql`
  query searchAll($term: String!) {
    topicsBySearch (term: $term) {
        uuid,
        title,
        type,
        urlSlug,
        repository
    }
  }
`
