import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const ActivityDescriptionFrame = styled.div`
  flex: 1 0 auto;
  max-width: 770px;
`

const Component = (props) => {
  return (
    <ActivityDescriptionFrame>
      {props.children}
    </ActivityDescriptionFrame>
  )
}

// ? NOTE
// was PropTypes.element.isRequired, but didn't like it if there were more than one react
// component in Children which can happen depending on API data.... probably a better check
Component.propTypes = {
  children: PropTypes.array.isRequired
}

export default Component
