/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requies the `React` import and a `page/` reference
 *
 *  AppPage is currently mounted at the top-level
 */
// React Include
import React, { useEffect, useRef } from 'react'

// Get our Link from Router
import { Link } from 'react-router-dom'

import { GET_TOPIC_BY_REPOSITORY_TITLE_QUERY } from '../../lib/queries/doc-ops'

// From ApolloClient load the useQuery react-hook helper
import { useQuery, useMutation } from '@apollo/client'

import { CREATE_EVENT_QUERY } from '../../lib/queries/events'

import Loader from '../../core/molecules/Loader'
import AppPage from '../../core/pages/appPage'

// Core ../../core/.../
import TopicPage from '../../core/pages/topicPage'
import BackArrowIcon from '../../core/atoms/glyphs/backArrow'

// Local doc-ops/../
// todo rename to TopicViewerSectional -- better description
import TopicViewerSectional from '../organisms/topicViewerSectional'
import TopicViewerContinuous from '../organisms/topicViewerContinuous'
import TopicTableContents from '../organisms/topicTableContents'
import TopicGitSummary from '../molecules/topicGitSummary'

// Got some styling to do initially
import styled from 'styled-components/macro'

import { InView } from 'react-intersection-observer'

import * as moment from 'moment'

import _ from 'lodash'

import { useLastLocation } from 'react-router-last-location'

// Need something to serve as the parent Flexbox enabler
// DO NOT PUT PADDING IN HERE, it will wreck the inner flexbox expansion
// and trigger a scrollbar
// Will wireup position: static to `fixed` once scrolling starts
const TopicFloatingContainer = styled.div`
  // background-color: #FFFFFF;
  // border-bottom: 1px solid #E5E5E5;
  // box-shadow: 0px 5px 2px 0 rgba(229,229,229,0.50);
  margin-bottom: 1em;
  width:100%;
  display: flex;
  position: static;
`

const TopicFloatingFirstAction = styled.div`
  display: flex;
  //background-color:#eee
  padding-top:1.9em;
  justify-content:center;
  flex: 0 1 40px;
`
const TopicFloatingHeaderColumn = styled.div`
  display:flex;
  //background-color:#ccc
  flex: 1 0 auto;
`

const TopicFloatingNextAction = styled.div`
  display: flex;
  //background-color:#999
  flex: 0 1 10%;
`

// CSS Wrapper that contains our two-columns
const TopicViewerFrame = styled.div`
  display: flex;
  //justify-content:center;
  padding: 0 .5em;
  // background-color: green;
`

const TopicContents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 0;
  
  
`

const TopicSidebar = styled.div`
  display: flex;
  // flex: 0 1 250px;
  flex-direction: column;
  padding-right: 1em;
  // background-color: purple;
  width: 300px;
`

// Created as a wrapper because can't place `position:fixed` directly on the flexbox containers, messes
// up the flow of page.
const EnableScroll = styled.div`
  position:fixed;
`

const TopicView = (props) => {
  let topicStartTime = moment()
  const sessionId = 'sess-' + moment().utc().unix()
  const PLATFORM_ID = process.env.REACT_APP_PLATFORM_ID || ''

  // Friendly namespace/scope for our data.topics
  let topicSelected = {}
  // we have `useRef()` to help keep some variables in scope POST
  // hook:unmount(()=>{()=>},[]})
  // We will marshall the topicData into the ref once the data has been fetch from graphQL
  const currentTopicViewRef = useRef()
  const topicSectionTrackingRef = useRef([])

  // only refers back from within app
  const lastLocation = useLastLocation()
  let referrer = ''
  if (lastLocation && lastLocation.pathname) {
    referrer = lastLocation.pathname
  }

  // TODO see if we can use the componentMount to assign our useRef just to be in accordance
  // with standard React Patterns-- maybe trying useLayoutEffect which might wait till post dom(graphql load)
  /*
  useLayoutEffect(()=>{
    //console.log('updates --',topicSelected);
  }) */

  // ensure that event tracking is only sent once
  let hasSentOnMountTracking = false
  function onMountTracking () {
    // unmarshall our Refs
    // console.log('currentTopicViewRef', data)
    // console.log('hasSentOnMountTracking', hasSentOnMountTracking)
    if (currentTopicViewRef.current && hasSentOnMountTracking === false) {
      const currentTopic = currentTopicViewRef.current.selectedTopic
      const currentUser = currentTopicViewRef.current.currentUser
      topicStartTime = moment()

      const eventObj = {
        mutation: CREATE_EVENT_QUERY,
        variables: {
          params: {
            eventTimestamp: moment().format(),
            eventType: 'topic-viewer-mount',
            userUuid: currentUser,
            // for front-end send core-ui for source
            sourceId: 'core-ui',
            platformId: PLATFORM_ID,
            context: {
              sessionId: sessionId,
              // change ref
              ref: currentTopic.ref || '',
              // add commitSha
              commitSha: currentTopic.commitSha || '',
              // track if the view was for the Head commit
              isHead: currentTopic.isHead || '',
              topicUuid: currentTopic.uuid || '',
              topicUrlSlug: currentTopic.urlSlug || '',
              repository: currentTopic.repository || '',
              referrer: referrer
            }
          }
        }
      }

      // console.log('onMountTracking', eventObj)
      // React Apollo Mutator -- Event API call
      createEvent({ variables: eventObj.variables })
      hasSentOnMountTracking = true
    }
  }

  function onUnMountTracking () {
    // unmarshall our Refs
    const currentTopic = currentTopicViewRef.current.selectedTopic
    const currentUser = currentTopicViewRef.current.currentUser

    // traverse the sections in tracking and check if all are marked `true`
    // indicating all sections were viewed, or not.
    let topicPageView = 'full' // default to full, and if any section missed set partial
    for (let ctr = 0; ctr < topicSectionTrackingRef.current.length; ctr++) {
      if (topicSectionTrackingRef.current[ctr].viewed === false) {
        topicPageView = 'partial'
        break
      }
    }

    // approximate time spent on page based on onMount and onUnMount time
    const topicEndTime = moment()
    const timeSpentInMs = topicEndTime.diff(topicStartTime, 'milliseconds')
    // console.log('timeSpentInMs', timeSpentInMs)

    const eventObj = {
      mutation: CREATE_EVENT_QUERY,
      variables: {
        params: {
          eventTimestamp: moment().format(),
          eventType: 'topic-viewer-unmount',
          userUuid: currentUser,
          // for front-end send core-ui for source
          sourceId: 'core-ui',
          platformId: PLATFORM_ID,
          context: {
            sessionId: sessionId,
            // change ref
            ref: currentTopic.ref || '',
            // add commitSha
            commitSha: currentTopic.commitSha || '',
            // track if the view was for the Head commit
            isHead: currentTopic.isHead || '',
            topicUuid: currentTopic.uuid || '',
            topicUrlSlug: currentTopic.urlSlug || '',
            repository: currentTopic.repository || '',
            referrer: referrer,
            // just harcode to FULL for now
            topicPageView: topicPageView,
            topicSections: topicSectionTrackingRef.current,
            timeSpentInMs: timeSpentInMs
          }
        }
      }
    }

    // console.log('eventObj', eventObj)
    // React Apollo Mutator -- Event API call
    createEvent({ variables: eventObj.variables })
  }

  // Component MOUNT and UNMOUNT
  // https://dev.to/prototyp/react-useeffect-explained-with-lifecycle-methods-296n
  useEffect(() => {
    /* componentDidMount code + componentDidUpdate code */
    window.addEventListener('beforeunload', onUnMountTracking)

    return () => {
      /* componentWillUnmount code */
      window.removeEventListener('beforeunload', onUnMountTracking)
      onUnMountTracking()
    }
  // the [] here means to call it only on unmount
  }, [])

  const getSectionId = (heading) => {
    return heading.toLowerCase().replace(/[\W_]+/g, '-')
  }

  const getPageView = (inView, entry) => {
    // no-op
  }

  const onScrollEvent = (inView, entry) => {
    // entry is each element in the scrollable, and inView is a (true||false) if it is visible
    // on initial mount, all sections are processed, so those that are visible default to true
    // console.log('scroll check ', entry,':',inView);

    if (inView && entry.target.firstChild) {
      topicSectionTrackingRef.current.forEach((section) => {
        if (section.id === getSectionId(entry.target.firstChild.innerText)) {
          section.viewed = true
        }
      })
      // console.log('entry.target.firstChild.innerText', entry.target.firstChild.innerText)
    }
  }

  // unpackage the react-router splat params from `match` key
  const routerParams = props.match
  // get our section (collection, topics, stacks)
  const topicId = (routerParams.params.topicId) ? routerParams.params.topicId : ''
  const repository = (routerParams.params.repository) ? routerParams.params.repository : ''
  const topicUrlSlug = (routerParams.params.topicUrlSlug) ? routerParams.params.topicUrlSlug : ''
  // console.log('[TopicView Router] --  topicId: ',topicId, ' repository:',repository, ' topicUrlSlug:',topicUrlSlug)

  // Setup our API Calls (fetch Topic, and prepare the Mutator for events)
  const { loading, error, data } = useQuery(GET_TOPIC_BY_REPOSITORY_TITLE_QUERY, { variables: { repository: repository, urlSlug: topicUrlSlug } })

  // call tracking after data has loaded
  useEffect(() => {
    /* componentDidUpdate code */

    // only need to be fired once
    onMountTracking()
  }, [data])

  // const [currentTopic, setCurrentTopic] = useState(data? data.topicByRepository:'');
  const [createEvent, { eventData }] = useMutation(CREATE_EVENT_QUERY)

  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) return `Error!: ${error}`
  // console.log('[TopicViewer Data:',data);
  // marshall our `data` into some helpful name spaces
  topicSelected = data.topicByRepositoryUrl

  // Map our sections into this block post data load
  // populate topSections for tracking `viewed` true/false of content blocks
  topicSelected.sections.map((section) => {
    // console.log('section', section)
    // we iterate over each section and check for headers
    if (section.blocks &&
        section.blocks.length > 0 &&
        section.blocks[0].type === 'heading' &&
        section.blocks[0].children &&
        section.blocks[0].children.length > 0) {
      // console.log('section? ', section.blocks[0].children)
      // There are some cases where a header's text-value is wrapped
      // inside of a `linkReference` common in Create-A-Changelog
      let sectionTextLabel = ''
      if (section.blocks[0].children[0] && section.blocks[0].children[0].type === 'text') {
        sectionTextLabel = section.blocks[0].children[0].value
      }
      if (section.blocks[0].children[1] && section.blocks[0].children[1].type === 'text') {
        sectionTextLabel = section.blocks[0].children[1].value
      }
      // console.log('key-extract- ', sectionTextLabel)
      const sectionId = getSectionId(sectionTextLabel)
      // avoiding duplication
      const found = _.findIndex(topicSectionTrackingRef.current, function (o) { return o.id === sectionId })
      // console.log('found', found)
      if (found === -1) {
        topicSectionTrackingRef.current.push({
          id: getSectionId(sectionTextLabel),
          viewed: false
        })
      }

      // console.log('topicSectionTrackingRef', topicSectionTrackingRef)
    }
  })

  // Store a REF of these values for our `unmount`
  currentTopicViewRef.current = {
    selectedTopic: topicSelected,
    currentUser: data.me.email
  }

  // Here we need to dispatch to either a continuous-block-viewer container or to a sectional-block-system
  // determining does this all go to another component, or do we handle logic here...
  // BUT for ADR lets allow this to branch out into TopicViewerSectional
  const continuousLayout = true

  let contentLayout = ''
  if (continuousLayout) {
    // we'll pass the entire topic to this component so it can handle rendering
    contentLayout = <TopicViewerContinuous onScrollEvent={onScrollEvent} topic={topicSelected} />
  } else {
    // NEXT UP we are linking topic={}
    // then we need to fix the `molecules/TopicViewSection` component to hand back a bare-styled
    // set of components, that way topivViewsectional can handle wrapping each section block,
    // and the above continuos uses VERY similar code, but a little different

    // <TopicViewerSectional topic={topicSelected} key={topicSection.order} sectionData={topicSection} />
    // contentLayout = topicSelected.sections.map(topicSection => (
    // <TopicViewerSectional topic={topicSelected} key={topicSection.order} sectionData={topicSection} />
    // );
    contentLayout = <TopicViewerSectional topic={topicSelected} />
  }

  // console.log('TopicViewer -- topicSelected', topicSelected)
  return (
    <AppPage>
      <div style={{ width: '100%' }}>
        <TopicViewerFrame>
          <TopicContents>
          <h1>{topicSelected.title}</h1>
            {/* InView is the intersection-observer vendor lib we use */}
            <InView onChange={(inView, entry) => getPageView(inView, entry)}>
              {({ inView, ref, entry }) => (
                <span ref={ref}>
                  {contentLayout}
                </span>
              )}
            </InView>
          </TopicContents>
          <TopicSidebar>
            {/* <EnableScroll> */}
              <TopicTableContents toc={topicSelected.toc} />
              <TopicGitSummary branch={topicSelected.ref} repository={topicSelected.repository} status='Merged' pr='1190' />
            {/* </EnableScroll> */}
          </TopicSidebar>
        </TopicViewerFrame>
      </div>
    </AppPage>
  )
}

// export default TopicView
export default TopicView
