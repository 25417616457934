
import React from 'react'
/*
 *  The Topic Guide Glyph
 */
const TopicGuideGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' version='1.1'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='ui/atoms/glyphs/topic/guide/standard24' transform='translate(0.000000, -1.000000)' stroke={strokeColor} strokeWidth='1.5'>
            <path d='M2.25,21.75 C1.42157288,21.75 0.75,21.0784271 0.75,20.25 L0.75,3.865 C0.752745167,2.97419969 1.47419969,2.25274517 2.365,2.25 L21.643,2.25 C22.5293821,2.25274509 23.2472549,2.97061795 23.25,3.857 L23.25,20.135 C23.2472548,21.0258003 22.5258003,21.7472548 21.635,21.75 L2.25,21.75 Z' id='Shape' />
            <path d='M23.25,6.75 L0.75,6.75' id='Shape' />
            <polyline id='Shape' points='16.5 11.25 19.5 14.25 16.5 17.25' />
            <polyline id='Shape' points='7.5 11.25 4.5 14.25 7.5 17.25' />
            <path d='M13.5,11.25 L10.5,17.25' id='Shape' />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default TopicGuideGlyph
