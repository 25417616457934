// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

export const EVENT_CONTEXT_TOPIC_SECTION_JSON_INPUT = gql`
  input EventContextTopicSectionJsonInput {
    id: String,
    viewed: Boolean
  }
`

export const EVENT_CONTEXT_JSON_INPUT = gql`
  input EventContextJsonInput {
    branch: String,
    topicUuid: String,
    topicUrlslug: String,
    repository: String,
    referrer: String,
    searchTerm: String,
    searchLocation: String,
    topicPageView: String,
    topicSections: [EventContextTopicSectionJsonInput]
  }
`

export const EVENT_INPUT = gql`
  input EventInput {
      eventTimestamp: String,
      eventType: String,
      userUuid: String,
      context: EventContextJsonInput
  }
`

export const CREATE_EVENT_QUERY = gql`
  mutation createEvent($params: EventInput) {
    event(params: $params) {
      id
    }
  }
`
