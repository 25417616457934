import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import { useQuery } from '@apollo/client'
import { useAuth } from '../../../Context/authUserContext';


// Atoms
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import Chevron from '../../atoms/glyphs/closedChevron'
import ConsoleFrame from '../../atoms/consoleFrame'

const OverViewHeadline = styled.div`
    display: flex;
    flex-direction: row;
`

const OverviewSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25em 0 0.25em 0;
    font-family: Roboto;
    font-size: 20px;
    `
const OverviewItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2em 0 2em;
    font-family: Open Sans;
    // align-items: flex-start;
    `
const OverviewLabel = styled.span`
    `
const OverviewDynamic = styled.span`
    color: #A3A9B1;
    `

const SectionName = styled.span`
    width: 40%;
    font-size: 2em;
    padding: 0 0 .75em 0;
    `

const AccountSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
`

const CallToAction = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Upgrade = styled.span`
    color: #24A5FF
    padding: 1em 0 1em 0;
    font-family: Open Sans;
    font-weight: 900;
    `

const UsageSection = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-size: 18px;
`

const UsageRow = styled(OverViewHeadline)`
`
const UsageItem = styled.span`
    display: flex;
    flex-direction: column;
    padding: 1em 1em 1em 1em;
`

const ResetItem = styled.span`
    font-family: Open Sans;
    font-size: 10px;
    color: #A3A9B1;
`
const UpgradeButton = styled.button`
  display: flex;
  align-items: center;
  bottom: 13px;
  right: 100px;
  position: relative;
  background: #BCEEAA;
  border: 1px solid #BCE3BC;  
  box-sizing: border-box;
  border-radius: 8px;
  width: 70px;
  height: 25px;
`

const Overview = (props) => {
  const UserAuthContext = useAuth()

  let history = useHistory();
  const handleClick = () => {
    history.push('/subscription')
  }

  // This method will break if/when we allow users to rename their personal workspaces.
  let WorkspaceType
  if (UserAuthContext.state.label === 'Personal Workspace') {
    WorkspaceType = 'Personal'
  } else {
    WorkspaceType = 'Organization'
  }

  let WorkspaceAdmin
  if (UserAuthContext.state.billingUserUuid === UserAuthContext.state.userUuid) {
    WorkspaceAdmin = true
  } else {
    WorkspaceAdmin = false
  }

  return (
    <>
      <OverViewHeadline>
        <SectionName>Overview</SectionName>
        <AccountSection>
          <OverviewItem>
            <OverviewLabel>Type:</OverviewLabel>
            <OverviewDynamic>{WorkspaceType}</OverviewDynamic>
          </OverviewItem>
          <OverviewItem>
            <OverviewLabel>Plan:</OverviewLabel>
            <OverviewDynamic>Pro Workspace</OverviewDynamic>
          </OverviewItem>
          {/* Should ONLY show if the user is the matches the billing_user_id for the workspace */}
          {WorkspaceAdmin ? (<UpgradeButton onClick={handleClick}>Upgrade</UpgradeButton>) : (null)}
        </AccountSection>
      </OverViewHeadline>
      {/* <UsageSection>
        Current Montly Usage
        <GreySkinnyRectangle />
        <UsageRow>
          <UsageItem style={{ paddingLeft: 0 }}>
            <OverviewLabel>Topics Processed*</OverviewLabel>
            <OverviewDynamic>500/10,000</OverviewDynamic>
          </UsageItem>
          <UsageItem>
            <OverviewLabel>API Requests*</OverviewLabel>
            <OverviewDynamic>5,132/10,000</OverviewDynamic>
          </UsageItem>
          <UsageItem>
            <OverviewLabel>Owners</OverviewLabel>
            <OverviewDynamic>32/100</OverviewDynamic>
          </UsageItem>
        </UsageRow>
        <ResetItem><i>* resets in X days on XX/XX/XXXX</i></ResetItem>
      </UsageSection> */}

      {/* <CallToAction> <div style={{ paddingRight: 5 }}><Chevron /></div> <Upgrade>Upgrade to Pro</Upgrade></CallToAction> */}
    </>
  )
}

export default Overview
