// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// [core] Atoms
// - icons
// import Icon from '../../../core/atoms/icon'

// - misc
// import StyledLink from '../../../core/atoms/styledLink'
// [core] Molecules
// import RepositoryBadge from '../../core/molecules/repositoryBadge'

// [doc-ops]
// Local Molecules
// import TopicCategoryListing from '../molecules/topicCategoryListing';
import CollectionListSummaryItem from '../CollectionListSummaryItem'

import { getUrlSlug } from '../../../lib/utils'

// Frame creates our initial flexbox pattern for a series of rows
// the internal blocks are SummaryBody and SummaryHeader, which Header is simple, but Body is column based
// Put a flex-basis on here so I can get a 2 column-layout going (these require another div wrapper around them)
// that does a flex-wrap + justify-content to get these to go to columns, could go three columns if necessary
const ListSummaryFrame = styled.div`
 display: flex;
 flex-direction: row;
 padding: 1em 0 0 0;
 flex-wrap: wrap;
`

// Renders the summary (Title, Description, Doc-Ops-Count) of a list of Collection(s)
class CollectionListSummary extends Component {
  render () {
    // Don't have api data yet so lets stub some fixtures
    const collectionData = this.props.collectionData

    // build a map of items
    const listItems = collectionData.map((item) => {
      return (
        <CollectionListSummaryItem
          key={item.uuid} uuid={item.uuid}
          title={item.title}
          description={item.description}
          assets={item.topicCounts}
          repository={item.repository}
          urlSlug={getUrlSlug(item.title)}
        />
      )
    })

    return (

      <ListSummaryFrame>
        {listItems}
      </ListSummaryFrame>

    )
  }
}

CollectionListSummary.propTypes = {
  collectionData: PropTypes.array.isRequired
}

export default CollectionListSummary
