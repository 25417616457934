import React, { useState } from 'react'
import styled from 'styled-components/macro'

const ImageContainer = styled.div`
 // Adjusting the width to 80-90% creates a gap/border..
 width: 99%;
 // allows the responsive image to center
 margin: 0 auto;
 padding: 0;
 text-align:center;

 // setting min-width and on each parent wrapper we can create an over-lapping affect
 // default mobile sizes image around 505px, so you want to see a change that is how you manipulate
//min-width: 505px
max-width: 1100px;


/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 	
  @media (min-width:641px)  { 	
    
  }	
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {	
    
  }

`

const TopicResponsiveImage = (props) => {
  // const FILL_COLOR = '#333'
  // const FILL_COLOR = 'white'
  const [dimensions, setDimensions] = useState({ height: null, width: null })

  function onImgLoad (event) {
    setDimensions({ height: event.target.offsetHeight, width: event.target.offsetWidth })
  }

  // Handle rendering based on the state:dimensions
  // 1. If dimensions are null, we place the image container on page raw (onLoad) won't fire
  // 2. Once image loads, the onload is called and we set the width/height in state
  //    - this triggers a re-render which we can then control the `width=100%`
  // -- TODO add an image loader into the intial load that sits-ontop of the image so we can still get onLoad
  //    but also provide a loading animation... for large images this is going to look jerky
  if (!dimensions.height && !dimensions.width) {
    return (
      <ImageContainer>
        <img src={props.src} onLoad={onImgLoad} width='100%' />
      </ImageContainer>
    )
  } else {
    if (dimensions.width < 800) {
      return (
        <ImageContainer>
          <img src={props.src} onLoad={onImgLoad} />
        </ImageContainer>
      )
    } else {
      return (
        <ImageContainer>
          <img src={props.src} onLoad={onImgLoad} width='100%' />
        </ImageContainer>
      )
    }
  }
}

export default TopicResponsiveImage
