import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// http://pxtoem.com/
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
const IntroHeader = styled.div`
  display: block;
  font-weight: 700;
  font-size: 4.00em;
`

const OutroHeader = styled.div`
  display: block;
  font-weight: 700;
  font-size: 2.50em;
`

const SubHeader = styled.div`
  display: block;
  font-weight: 600;
  font-size: 1.5em;
`

const SectionHeader = styled.div`
  display: block;
  font-weight: 700;
  font-size: 2.25em;
`

const ActivityHeader = styled.div`
  display: block;
  font-weight: 700;
  font-size: 1em;
`
const Assessments = styled.div`
  color: blue;
  font-size: .75em;
`

const Learnings = styled.div`
  color: green;
  font-size: .75em;
`

const Component = (props) => {
  switch (props.type) {
    case 'sub':
      return (
        <SubHeader>
          {props.children}
        </SubHeader>
      )

    case 'intro':
      return (
        <IntroHeader>
          {props.children}
        </IntroHeader>
      )

    case 'outro':
      return (
        <OutroHeader>
          {props.children}
        </OutroHeader>
      )

    case 'activity':
      return (
        <ActivityHeader>
          {props.children}
        </ActivityHeader>
      )

    case 'assessments':
      return (
        <Assessments>
          {props.children}
        </Assessments>
      )

    case 'learnings':
      return (
        <Learnings>
          {props.children}
        </Learnings>
      )

    case 'section':
    default:
      return (
        <SectionHeader>
          {props.children}
        </SectionHeader>
      )
  }
}

Component.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Component
