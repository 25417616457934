// TODO -- Deprecate this Icon

// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import ClockIcon from '../../atoms/glyphs/clock'
import RepoIcon from '../../atoms/glyphs/repository'
import TypeIcon from '../../atoms/glyphs/type'
import Text from '../../atoms/text'

const IconFrameMount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const IconFrame = styled.div`
  flex: 0 1 16px;
  margin: 0 4px 0 0;
`

const TextFrame = styled.div`
  flex: 1 0 auto;
`

function Icon (props) {
  let DisplayIcon
  switch (props.type) {
    case 'repo':
      DisplayIcon = RepoIcon
      break
    case 'type':
      DisplayIcon = TypeIcon
      break
    case 'time':
      DisplayIcon = ClockIcon
      break
    default:
      break
  }

  return (
    <IconFrameMount>
      <IconFrame>
        <DisplayIcon />
      </IconFrame>
      <TextFrame>
        <Text>{props.children}</Text>
      </TextFrame>
    </IconFrameMount>
  )
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
}

export default Icon
