// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

// TODO
// activities.uuid comes to us userActivityUuid, we should be explicit about this in here it is important to know
// -- aded it so we could inpsect the userActivities
// and topics.uuid is the actual topicUuid being mounted
export const USER_LEARNING_QUERY = gql`
{
  userLearning {
    phases {
      title,
      activities {  
        uuid,
        title,
        status,
        description,
        tasks {
          type
          ... on ContentTask{
           description,
           sections {
             header,
             topics {
               uuid,
               title,
               repository,
               owner,
               type, 
               sections {
                 order,
                 blocks {
                   type,
                   depth,
                   value,
                   lang,
                   meta,
                   children{ type, value, url,
                     children {type, value, url,
                       children {type, value, url,
                         children {type, value, url}
                       }
                     }
                   }
                 }
               }
             }
           }
          }
        }
      }
    }
  }
}
`
