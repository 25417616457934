
import React from 'react'
/*
 *  The initial Filter for input boxes
 */
const Filter = () => (
  <div style={{}}>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="ui/atoms/glyphs/filter-icon" stroke="#727C88" strokeWidth="1.5">
                <g id="filter-icon">
                    <g id="filter-text">
                        <path d="M14.247,5.25 L21.747,5.25 C22.5754271,5.25 23.247,5.92157288 23.247,6.75 L23.247,21.75 C23.247,22.5784271 22.5754271,23.25 21.747,23.25 L8.247,23.25 C7.41857288,23.25 6.747,22.5784271 6.747,21.75 L6.747,15.75" id="Shape"></path>
                        <path d="M12.747,9.75 L20.25,9.75" id="Shape"></path>
                        <path d="M10.497,14.25 L20.25,14.25" id="Shape"></path>
                        <path d="M10.5,18.75 L20.25,18.75" id="Shape"></path>
                        <path d="M8.25,9.75 L8.25,6.75 L12.439,2.561 C12.7422412,2.25756458 12.8329219,1.80137934 12.6687827,1.4050378 C12.5046435,1.00869627 12.1179852,0.750194238 11.689,0.75 L1.811,0.75 C1.38201484,0.750194238 0.995356518,1.00869627 0.831217284,1.4050378 C0.667078051,1.80137934 0.757758769,2.25756458 1.061,2.561 L5.25,6.75 L5.25,12.75 L8.25,9.75 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  </div>
)


export default Filter;
