
import React from 'react'

function Component (props) {
  let color
  switch (props.state) {
    case 'completed':
      color = '#57B435'
      break
    case 'unassigned':
      color = '#343639'
      break
    case 'active':
    default:
      color = '#A3A9B1'
      break
  }

  let svg
  switch (props.size) {
    case 'medium':
      svg = <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.75 2.24902C0.75 1.4206 1.42157 0.749023 2.25 0.749023H21.75C22.5784 0.749023 23.25 1.4206 23.25 2.24902V21.749C23.25 22.5775 22.5784 23.249 21.75 23.249H2.25C1.42157 23.249 0.75 22.5775 0.75 21.749V2.24902Z' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 4.49902L7.5 10.499L4.5 7.49902' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.25 8.24902H18.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 13.499L7.5 19.499L4.5 16.499' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.25 17.249H18.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
      break
    case 'small':
      svg = <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.625 2.12415C0.625 1.29572 1.29657 0.624146 2.125 0.624146H17.875C18.7034 0.624146 19.375 1.29572 19.375 2.12415V17.8741C19.375 18.7026 18.7034 19.3741 17.875 19.3741H2.125C1.29657 19.3741 0.625 18.7026 0.625 17.8741V2.12415Z' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 3.74915L6.25 8.74914L3.75 6.24915' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.875 6.87415H15.625' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 11.2491L6.25 16.2491L3.75 13.7491' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M11.875 14.3741H15.625' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            </svg>
      break
    case 'xsmall':
    case 'tiny':
    default:
      svg = <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.5 1.99933C0.5 1.1709 1.17157 0.499329 2 0.499329H14C14.8284 0.499329 15.5 1.1709 15.5 1.99933V13.9993C15.5 14.8278 14.8284 15.4993 14 15.4993H2C1.17157 15.4993 0.5 14.8278 0.5 13.9993V1.99933Z' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 2.99933L5 6.99933L3 4.99933' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.5 5.49933H12.5' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 8.99933L5 12.9993L3 10.9993' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.5 11.4993H12.5' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
            </svg>
  }

  return (
    <div style={{}}>
      {svg}
    </div>
  )
}

export default Component
