// React Include
import React from 'react';
// Got some styling to do initially
import styled from 'styled-components/macro';
// Get our Link from Router
import { Link } from "react-router-dom";


// TODO: determine how we will apply the `active` class in some cases, but ultimate we need a selected on the li
// https://github.com/styled-components/styled-components/issues/184
const StyledLink = styled(Link)`
  color: #222;
  text-decoration: none;


  &:hover {
    text-decoration: none;
  }
  &.active {
    color: red;
  }
`;

export default StyledLink