import styled from 'styled-components/macro'

/* An initial Flex box to set a row.. This is mainly to facilitate the intial AppContainer which defines the 100vh
   And the subsequent <div> then sets a row-full width which lays the foundation for flowing everything 100% in the browser
   TODO: see if we can collapse this into <AppContainer /> so that it is always included
*/
const AppBaseRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis:100%;
`
export default AppBaseRow
