/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requies the `React` import and a `page/` reference
 *
 *  AppPage is loaded by default.  This is the initial work for `Reports` and this view lives in `core/`, but eventually
 *  it should move into its own folder.
 *
 *  This view was specifically wired up so in the demo I can show some of the proposed Report designs from Sketch without
 *  having to leave the confines of the app.
 */
import React from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'

// Import our Page
import AppPage from '../pages/appPage'

// Mount Prototype Image Stubs
// CRA/Webpack has a built in pattern to allow us to mount images inside of the app (:warning: this increases the bundle)
// https://create-react-app.dev/docs/adding-images-fonts-and-files
import ReportEngagementReferencerStub from '../../images/slice-reports-referencer-engagement.jpg'
import ReportEngagementContributorStub from '../../images/slice-reports-contributor-engagement.jpg'

// [core] Atoms
// - icons
// import GitRepositoryIcon from '../../core/atoms/icons/gitRepository';
// - misc
import StyledLink from '../../core/atoms/styledLink'

// Pull this version of MainSectionFrame from `DiscoveryView` and added the direct:column
const MainSectionFrame = styled.div`
  
  display:flex;
  flex-basis: 90%;
  max-width:1000px;
  // TODO get rid of?
  //padding-right: 1em;
  // debug border
  //border: 1px dotted #333
  flex-direction:column;
  
`

// Building new styles, see if we can get ouselves some basic headers that will work across pages
const PageHeader = styled.h2`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 31px;
    color: #343639;
    letter-spacing: -0.21px;
    padding: 0;
    margin: 0;
`

const HeaderHR = styled.hr`
    width: 100%;
    background-color: #ECECEC;
    border: 3px solid  #ECECEC;
`

function ReportView (props) {
  // marshall our out `:reportDetailSelection` from the url/route
  const selectedReport = props.match.params.reportDetailSelection
  let reportLabel = ''
  let prototypeImageStub = ''
  if (selectedReport === 'reference-report') {
    reportLabel = 'Reference Engagement'
    prototypeImageStub = ReportEngagementReferencerStub
  } else if (selectedReport === 'contributor-report') {
    reportLabel = 'Contributor Engagement'
    prototypeImageStub = ReportEngagementContributorStub
  } else {
    console.error('ERROR: Reports is missing A ROUTE MATCH / LABEL ')
  }
  return (
    <AppPage>
      <MainSectionFrame>
        <PageHeader>Reports: {reportLabel}</PageHeader>
        <HeaderHR />

        <img src={prototypeImageStub} alt='Reports Image' width='883' />
      </MainSectionFrame>
    </AppPage>
  )
}

export default ReportView
