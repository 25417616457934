import React from 'react'
// Get our ReactRouter, which everything is bound here in app
import { Route, Redirect } from 'react-router-dom'

// Get our Apollo-React-Hooks Query Tool
// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'

import { GET_ME_QUERY } from '../lib/queries/auth'

import AuthLoaderPage from '../core/pages/authLoaderPage'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Source: https://reacttraining.com/react-router/web/example/auth-workflow
function PrivateRoute ({ component: Component, ...rest }) {
  const { loading, error, data, client } = useQuery(GET_ME_QUERY)
  if (loading) return (<AuthLoaderPage />)
  // console.log('Private Route -- Authentication --', data)
  if (error) {
    console.error(error)
    return `Error!: ${error}`
  }

  return (<Route
    {...rest}
    render={props =>
      data.me.isActive ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )}
          />)
}

export default PrivateRoute
