
import React from 'react'
/*
 *  The initial ExitIcon being added for GlobalSearch
 */
const ExitIcon = () => (
  <div style={{}}>
    <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
        <path d="M9.36279 10H6.46191L4.65283 7.09912L2.86279 10H0.0253906L3.104 5.25195L0.209473 0.719727H2.98975L4.66553 3.58887L6.27783 0.719727H9.14062L6.20166 5.44873L9.36279 10Z" fill="#B4B4B4"/>
    </svg>
  </div>
)


export default ExitIcon
