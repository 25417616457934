import React from 'react'
import styled from 'styled-components'

import { WHITE, BABEL_BLUE } from '../colors'

const BigButton = styled.a`
  font-family: Open Sans;
  font-size: 1em;
  letter-spacing: .3px;
  line-height: 30px;
  /* identical to box height */
  //letter-spacing: -0.16px;

  font-weight: 900;
  //text-shadow: 0 2px 4px rgba(0,0,0,0.50);
  color: ${WHITE};

  /* blue / BabelingBlue - Base */
  background-color: ${BABEL_BLUE};
  border-radius: 10px;
  width: 8em;
  //max-height: 30px;
  padding: .5em .3em .4em .3em;
  display: block;
  box-shadow: 4px 4px 0px #DDE0E4;
  text-decoration: none;
  text-align: center;
  

  &:hover {
    text-decoration: none;
  }
`

export default BigButton
