// React Include
import React, { Component } from 'react'
// Got some styling to do initially
import styled from 'styled-components/macro'

// get our image utility
import { getGithubImageUrl } from '../../lib/utils'

import TopicResponsiveImage from './topicResponsiveImage'

const TopicParagraphStyles = styled.p`
  font-family: 'Open Sans';
  font-size: 1em;
  color: #6B7B8A;
  letter-spacing: -0.09px;
  line-height: 22px;
  padding: 0 0.25em 0 0.25em;
  // &:first-of-type {
  //   background: red;
  //   padding-top: 0.3em;
  // }
  &:last-of-type {
    // background: blue;
    padding-bottom: 0.3em;
  }
`

class TopicParagraph extends Component {
  render () {
    // console.log('<TopicParagraph /> ', this.props)
    const block = this.props.block
    const meta = this.props.meta

    /*
     *  IMPORTANT: We interupt the Paragraph renderer to check if only 1 block.children
     *    exists, because UnifiedJS parsing puts a standalone image inside of a Paragraph
     *    which if it is stand-alone we want to wrap it in a responsive frame
     *    There is a potential the image is inline which we support below in the `block.children.map()`
     */
    // Check if paragraph contains only an image if so return it directly
    if (meta.owner && block.children && block.children.length === 1 && block.children[0].type === 'image') {
      const imageBlock = block.children[0]
      // console.log('IT BE ONE & it image ', block.children)
      // Check to see if relative-image-url -- github based
      // ELSE it is an external image -- most likely
      const relativeDirectoryCount = (imageBlock.url.match(/\.\.\//g) || []).length
      // console.log('check for relative count ', relativeDirectoryCount)
      // So we have an image HURAY!
      // Check if it is a local/relative image in the git-repo, we need to build an external ref to it using an api-key
      let imageUrl = ''
      if (relativeDirectoryCount >= 1) {
        // console.log('have a github image so need to rebuild', imageBlock.url)

        const imageParams = {
          owner: meta.owner,
          repository: meta.repository,
          ref: meta.ref,
          topicFilePath: meta.filepath,
          imageRelativePath: imageBlock.url
        }

        imageUrl = getGithubImageUrl(imageParams)
      } else if (imageBlock.url && imageBlock.url != '' && imageBlock.url.startsWith('http')) {
        imageUrl = imageBlock.url
      } else {
        // something went sideways
        console.warn('<TopicParagraph /> of Image had `url` issues ')
        return ('')
      }

      // Return a standalone responsive image
      return (<TopicResponsiveImage src={imageUrl} />)
    }

    // Used to get a block-fixture for the storybook in `doc-ops/atoms/topicParagraph
    // console.log('get block for story: ',JSON.stringify(block));
    const childBlocks = block.children.map(innerBlock => {
      // prevent Key errors in console, so make our own mini unique key
      const innerBlockKey = 'd' + block.depth + '-inner-' + Math.floor(Math.random() * 10000)

      // Inside of a P tag we can have all these various inline items
      if (innerBlock.type === 'text') {
        return (innerBlock.value)
      } else if (innerBlock.type === 'strong') {
        return (<span key={innerBlockKey} style={{ fontWeight: 'bold' }}>{innerBlock.children[0].value}</span>)
      } else if (innerBlock.type === 'inlineCode') {
        return (<span key={innerBlockKey} style={{ fontFamily: 'Courier', color: '#37BAAD', backgroundColor: '#fff' }}>{innerBlock.value}</span>)
      } else if (innerBlock.type === 'link') {
        return (<a key={innerBlockKey} href={innerBlock.url} style={{ color: '#29877D' }}>{innerBlock.children[0].value}</a>)

      // SPECIAL CASE HERE FOR HANDLING IMAGES THAT NEEDS FINALIZED
      } else if (innerBlock.type === 'image') {
        console.warn('<TopicParagraph /> inline-image is currently not supported', innerBlock)
        // NOTE: realizing we can move some of this into codex, and check if a topic has image links to git (in fact we can verify they are right)
        // as part of the check and we can store in topic a flag/count of images (helpful to know potentially)
        // count how many occurences of '../' are in the url string

        /*
         * TODO / FOLLOWUP -- inline images will need a special ImageContainer to be handed over, we
         * currently don't have examples of it in our markdown, so making this a followup but leaving the code
         * here so when ready just have to finalize the container
         *
         *
        const relativeDirectoryCount = (innerBlock.url.match(/\.\.\//g) || []).length
        // console.log('check for relative count ', relativeDirectoryCount)
        // So we have an image HURAY!
        // Check if it is a local/relative image in the git-repo, we need to build an external ref to it using an api-key
        if (relativeDirectoryCount >= 1) {
          // console.log('have a github image so need to rebuild', innerBlock.url)
          // console.log('have meta? ', meta)

          const imageParams = {
            owner: meta.owner,
            repository: meta.repository,
            ref: meta.ref,
            topicFilePath: meta.filepath,
            imageRelativePath: innerBlock.url
          }

          const githubImageUrl = getGithubImageUrl(imageParams)

          // return (<TopicResponsiveImage src={githubImageUrl} />)
          return ('')
        } else if (innerBlock.url && innerBlock.url != '' && innerBlock.url.startsWith('http')) {
          console.log('have external image so blink link yo')
          return ('')
        } else {
          // console.log('need an else?')
          return ('')
        }
        // else if it is an ABS url -- starts with http, just link it as a viewable asset
        */
      } else if (innerBlock.type === 'html') {
        console.log('value', innerBlock.value)

        if (innerBlock.children) {
          let string = ''
          innerBlock.children.forEach(child => {
            if (child.type === 'text') {
              string += child.value
            }
          })

          const htmlText = '<' + innerBlock.value + '>' + string + '</' + innerBlock.value + '>'

          return <span key={innerBlock.value + Math.random()} dangerouslySetInnerHTML={{ __html: htmlText }} />
        } else {
          return ''
        }
      } else {
        console.warn('<TopicParagraph /> have a non-matched paragraph innerBlock ', innerBlock)
        return ('')
      }
    })

    // return the rolled up paragraph block
    return (
      <TopicParagraphStyles>{childBlocks}</TopicParagraphStyles>
    )
  }
}

export default TopicParagraph
