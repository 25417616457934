
import React from 'react'

// Got some styling to do initially
import styled from 'styled-components/macro'

function Component (props) {
  
  const ProgressBarFrame = styled.div`
  // background: #1D7EC2;
  height: 4px;
  position: relative;
  width: 100%;

  &:after {
    content:'';
    position: absolute;
    background: #1D7EC2;
    top:0; 
    bottom:0;
    left:0; 
    width:${props.fill}%; /* Specify the width.. */
}
`

  return (
    <ProgressBarFrame />
  )
}

export default Component
