export const WORKSPACE_STATE_LOCAL_STORAGE_KEY = 'workspaceSelected'
export const WORKSPACE_LABEL_STATE_LOCAL_STORAGE_KEY = 'workspaceLabel'
export const WORKSPACE_APPENTITYUUID_STATE_LOCAL_STORAGE_KEY = 'workspaceAppEntityUuid'
export const WORKSPACE_BILLINGUSERID_STATE_LOCAL_STORAGE_KEY = 'workspaceBillingUserId'


//Anytime we are updating the selected workspace, we set the worksapace and label
export const setWorkspaceToLocalStorage = (workspace) => {
    // console.log(workspace)
    window.localStorage.setItem(WORKSPACE_STATE_LOCAL_STORAGE_KEY, JSON.stringify(workspace.workspace))
    window.localStorage.setItem(WORKSPACE_LABEL_STATE_LOCAL_STORAGE_KEY, JSON.stringify(workspace.label))
    window.localStorage.setItem(WORKSPACE_APPENTITYUUID_STATE_LOCAL_STORAGE_KEY, JSON.stringify(workspace.appentityuuid))
    window.localStorage.setItem(WORKSPACE_BILLINGUSERID_STATE_LOCAL_STORAGE_KEY, JSON.stringify(workspace.billingUserUuid))



}

//Load the workspace
export const loadWorkspaceFromLocalStorage = () => {
    let selectedWorkspace = JSON.parse(window.localStorage.getItem(WORKSPACE_STATE_LOCAL_STORAGE_KEY))
    let selectedLabel = JSON.parse(window.localStorage.getItem(WORKSPACE_LABEL_STATE_LOCAL_STORAGE_KEY))
    let selectedAppEntity = JSON.parse(window.localStorage.getItem(WORKSPACE_APPENTITYUUID_STATE_LOCAL_STORAGE_KEY))
    let selectedBillingUserUuid = JSON.parse(window.localStorage.getItem(WORKSPACE_BILLINGUSERID_STATE_LOCAL_STORAGE_KEY))


    //If they aren't set (login or storage cleared), set them to null, front end will check for this and set a default
    if (selectedWorkspace === null) {
        selectedWorkspace = null
        selectedLabel = null
        selectedAppEntity = null
        selectedBillingUserUuid = null
    }
    //Otherwise we return the current workspace
    return selectedWorkspace, selectedLabel, selectedAppEntity, selectedBillingUserUuid
}

