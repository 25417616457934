/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import Loader from '../../core/molecules/Loader'
import AppPage from '../../core/pages/appPage'

import SherpaLayout from '../templates/sherpaLayout'

import CardStepper from '../organisms/CardStepper'

import { ACTIVITY_AND_PHASES_QUERY } from '../../lib/queries/sherpa'

import { getUrlSlug } from '../../lib/utils'

import * as _ from 'lodash'

import { progressTasksVar } from '../../cache'

function SherpaContentView (props) {
  // todo rename these params to indicate they are urlSlugs
  const phaseId = props.match.params.phaseId
  const activityId = props.match.params.activityId

  console.log('-----------------------')
  console.log(`Sherpa RouteParams: \nphaseId: ${phaseId} \nactivityId: ${activityId}`)
  console.log('-----------------------')

  const { loading, error, data } = useQuery(ACTIVITY_AND_PHASES_QUERY, {
    // TODO: errors if fetchPolicy is not 'no-cache'
    fetchPolicy: 'no-cache'
  })

  // TODO: UI WORK -- create a real loader
  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) {
    console.error(error)
    return `Error! : ${error}`
  }

  console.log('UserLearning API Data', data)

  const cachedProgress = progressTasksVar()

  let currentActivity
  const currentTopics = []
  let nextTopic
  // we only set nextActivity if we at the end of the current activity / ContentTask
  const nextActivity = null
  let nextURL
  let prevTopic // < todo need to track this down for previous button

  let previousURL
  let pageData

  // Bunch of Route-2-GraphQL matching mode
  // The API could help on some of this maybe -- has to be some common ground
  // Same time, we should move some of this into a `util` for now.

  // 1. from the urlSlug from routes match to the getUrl(phase.title)
  // find current phase
  const currentPhase = data.userLearning.phases.find(phase => {
    // console.log('getUrlSlug(phase.title)', getUrlSlug(phase.title))
    // getUrlSlug from the phase, we should put lower-case as a flag to make this easier to read
    return getUrlSlug(phase.title.toLowerCase()) === phaseId.toLowerCase()
  })

  // 2. from the urlSlug from routes match to the getUrl(activity.title)
  // find current activity -- matches the urlSlug
  if (currentPhase) {
    currentActivity = currentPhase.activities.find(activity => {
      return getUrlSlug(activity.title.toLowerCase()) === activityId.toLowerCase()
    })

    if (currentActivity) {
      console.log('currentActivity', currentActivity)

      console.log('next', nextActivity)

      // content
      pageData = {

        userActivityUuid: currentActivity.uuid,
        activityTitle: currentActivity.title

      }

      console.info('Sherpa [pageData]:', pageData)

      // Restructure this block so that error messaging is built and handled at render
      // these end up render an empty white page that is confusing...
    } else {
      return 'Error!: can not find activity'
    }
  } else {
    return 'Error!: can not find phase'
  }

  // set back button
  const backObj = {
    title: 'Back to ' + currentActivity.title,
    phaserUrl: '/learning/' + phaseId,
    activityUrl: '/learning/' + phaseId + '/' + activityId,
    url: '/learning/' + phaseId + '/' + activityId
  }

  // display rail
  const railObj = [{
    type: 'activity',
    state: 'active'
  }]

  // CardStepper won't render the next button with out the url
  nextURL = '/onboarding'

  return (
    <SherpaLayout type='content' backto={backObj} currentPhase={currentPhase} currentActivity={currentActivity} nextActivity={nextActivity}>
      <CardStepper type='completed' data={pageData} rail={railObj} nextURL={nextURL} previousURL={previousURL} localProgress={cachedProgress} />
    </SherpaLayout>
  )
}

export default SherpaContentView
