import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components'

// Local Doc-Ops imports
import FilterIcon from '../../../core/atoms/glyphs/filter'

// TODO -- Moveinto Molecules as we wire these things up potentially, depends
// on how the plan is to connect their click handles back to `state`

const FilterControlboxTextInput = styled.dl`
    display: flex;
    flex-direction:column
    margin: 0 2.8em 0 0;
    padding: 0;
`

//
// -- Text Filtering
const FilterTextWithInput = styled.dd`
font-family: "Open Sans";
font-size: 22px;
color: #343639;
letter-spacing: -0.15px;
padding:0;
margin:0;
`

const InputFrame = styled.div`
 display:flex;
 background-color: #fff;
 padding:.3em 0 0 .5em;
 flex-direction: row;
 font-size:.7em;
 width:200px;
  height: 30px; 
  border-radius:10px;
  border:1px solid #3B3B3B;
  boxShadow: 0 2px 4px 0 rgba(188,188,188,0.50);
`

function FilterTopicTitleControl (props) {
  // TODO - place inside of a Molecule with the FilterControlTopicTitleFilter... ugh names
  // Simple style applier, moveed into a <StyledComponent /> not sure if we need to do something speicla
  const inputStyle = { border: 'none', marginLeft: '.5em', fontSize: '.9em', position: 'relative', top: '-2px' }

  function handleChange (event) {
    props.setSearchTerm(event.target.value)
  }

  return (
    <FilterControlboxTextInput>
      <FilterTextWithInput>
        {/* TODO */}
        {/* Rebuild a div box to be the frame and have icon, then embed a simple white input into it */}
        <InputFrame>
          <FilterIcon />
          <input type='text' placeholder='Filter Topics by Title' style={inputStyle} onChange={handleChange} />
        </InputFrame>
      </FilterTextWithInput>

    </FilterControlboxTextInput>
  )
}

FilterTopicTitleControl.propTypes = {
  setSearchTerm: PropTypes.func.isRequired
}

export default FilterTopicTitleControl
