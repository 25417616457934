/*
 * Colors
 */

// Grey Tones from L3<>Base<>D3
export const HEDGEWIG = '#f8f9fa' // L3
export const KONAMI = '#edeff0' // L2
export const SLUSH = '#dde0e4' // L1
export const SPACE_GREY = '#a3a9b1' // Base
export const ARCHER_GREY = '#6a6e74' // D1
export const LEGACY = '#343639' // D2
export const NIGHTS_WATCH = '#0d0e11' // D3

// Blues
export const BLUE_500 = '#1D7EC2' // D1
export const ROBINS_EGG = '#dcf0ff' // L3
export const DREAMY_SKY = '#afddfd' // L2
export const OPALZ = '#76c1f5' // L1
export const BABEL_BLUE = '#24a5ff' // Base

// Greens
export const GREEN_500 = '#57B435' // D1
// Base
export const WHITE = '#ffffff'
export const BLACK = '#000000'
