import React from 'react'

const RepoConnected = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17.848 12.306C18.0421 11.9904 18.0504 11.5945 17.8697 11.2711C17.689 10.9476 17.3475 10.7471 16.977 10.747H13.5V1.747L6.15196 13.689C5.95765 14.0044 5.94928 14.4003 6.13006 14.7237C6.31084 15.0471 6.65248 15.2473 7.02296 15.247H10.5V24.247L17.848 12.306Z' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>

  )
}

export default RepoConnected
