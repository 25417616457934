import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const Separator = styled.div`
  font-color: #343639;
  font-size: .75em;
  font-weight: 400;
  margin: 0 8px;
`

const Component = (props) => {
  return (
    <Separator>|</Separator>
  )
}

Component.propTypes = {
  
}

export default Component
