// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import Icon from '../../atoms/Icon'
import GitRepositoryIcon from '../../atoms/glyphs/doc-ops/repository/gitRepository'
import RepositoryBadgeLabel from '../../atoms/RepositoryBadgeLabel/RepositoryBadgeLabel'

// --- PORTED FROM doc-ops/atom/TopicCategoryLabel ------
// Low Level Atom // StyledComponent
const DocOpsAssetBadgeText = styled.span`
  padding: .1em .3em 0 0;
  color:  #343639;
  font-family: 'Open Sans';
  font-weight: 300;
  letter-spacing: -0.09px;
  display: flex;
  font-size: 12px;
  align-items: center;
`

const DisplayBadge = styled.div`
    display:flex;
    padding:0 .4em 0 0
`

const IconAligner = styled.div`
    display: flex;
    padding: 0 .5em 0 0;
`
// -------------------------------

/* TODO -- notes transfer from TopicCAtegoryLabel
 * turn this into TopicCategoryBadge
 * adding spacing between the items
 * move into a molecule and have it support a param for single-vs-multi
 * hoist into the Discovery:CollectionListing
 * Critical to understand this is not the `<RepositoryBadge />
 */

function TopicCategoryBadge (props) {
  // console.log('topicCategoryLabel',props)
  if (props.count) {
    return (
      <DisplayBadge key={Math.floor((Math.random() * 1000000) + 1)}>
        <IconAligner><Icon glyph={'topic' + props.category} size='small' strokeColor='#000' /></IconAligner>
        <DocOpsAssetBadgeText>{props.category.toUpperCase()} ({props.count})</DocOpsAssetBadgeText>
      </DisplayBadge>
    )
  } else {
    return (
      <DisplayBadge key={Math.floor((Math.random() * 1000000) + 1)}>
        <IconAligner><Icon glyph={'topic' + props.category} size='small' strokeColor='#000' /></IconAligner>
        <DocOpsAssetBadgeText>{props.category.toUpperCase()}</DocOpsAssetBadgeText>
      </DisplayBadge>
    )
  }
}

TopicCategoryBadge.propTypes = {
  category: PropTypes.string.isRequired,
  count: PropTypes.string
}

export default TopicCategoryBadge
