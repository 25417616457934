// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import GitRepositoryIcon from '../../atoms/glyphs/doc-ops/repository/gitRepository'
import RepositoryBadgeLabel from '../../atoms/RepositoryBadgeLabel/RepositoryBadgeLabel'

/*
 * FrameMount can except an override on the border
 */
const RepositoryFrameMount = styled.div`

  display: flex;
  border-right: ${props => props.includeBorder ? '1px solid #EDEFF0' : 'none'};
  align-items: center;

`

// TODO: make the <div> generic and not use the
function RepositoryBadge (props) {
  return (
    <RepositoryFrameMount includeBorder={props.includeBorder}>
      <GitRepositoryIcon />
      <RepositoryBadgeLabel>{props.children}</RepositoryBadgeLabel>
    </RepositoryFrameMount>
  )
}

// https://reactjs.org/docs/typechecking-with-proptypes.html
// For single-children, https://reactjs.org/docs/typechecking-with-proptypes.html#requiring-single-child
// https://stackoverflow.com/questions/44582209/proptypes-in-functional-stateless-component
RepositoryBadge.propTypes = {
  children: PropTypes.string.isRequired,
  includeBorder: PropTypes.bool
}

export default RepositoryBadge
