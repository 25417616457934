/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import Loader from '../../core/molecules/Loader'
import AppPage from '../../core/pages/appPage'

import SherpaLayout from '../templates/sherpaLayout'

import CardStepper from '../organisms/CardStepper'

import { ACTIVITY_AND_PHASES_QUERY } from '../../lib/queries/sherpa'

import { getUrlSlug } from '../../lib/utils'
// commenting temporarily till we patch the YAML issue in topic.sections
import { buildPreviousNextUrl } from './utils'

import * as _ from 'lodash'

import { progressTasksVar } from '../../cache'

function SherpaContentView (props) {
  // todo rename these params to indicate they are urlSlugs
  const phaseId = props.match.params.phaseId
  const activityId = props.match.params.activityId
  // this taskID is actually the __TOPIC__ -url-slug ...
  const taskId = props.match.params.taskId
  // Exception being sectionId
  const sectionId = props.match.params.sectionId || 1

  console.log('-----------------------')
  console.log(`Sherpa RouteParams: \nphaseId: ${phaseId} \nactivityId: ${activityId}`)
  console.log('-----------------------')

  const { loading, error, data } = useQuery(ACTIVITY_AND_PHASES_QUERY, {
    // TODO: errors if fetchPolicy is not 'no-cache'
    fetchPolicy: 'no-cache'
  })

  // TODO: UI WORK -- create a real loader
  if (loading) return <><AppPage><Loader /></AppPage></>
  if (error) {
    console.error(error)
    return `Error! : ${error}`
  }

  // console.log('UserLearning API Data', data)

  const cachedProgress = progressTasksVar()

  let currentActivity
  let currentTopics = []
  let nextTopic
  // we only set nextActivity if we at the end of the current activity / ContentTask
  let nextActivity = null
  let nextURL
  let prevTopic // < todo need to track this down for previous button

  let previousURL
  let pageData
  let cardStepperUrls

  // Bunch of Route-2-GraphQL matching mode
  // The API could help on some of this maybe -- has to be some common ground
  // Same time, we should move some of this into a `util` for now.

  // 1. from the urlSlug from routes match to the getUrl(phase.title)
  // find current phase
  const currentPhase = data.userLearning.phases.find(phase => {
    // console.log('getUrlSlug(phase.title)', getUrlSlug(phase.title))
    // getUrlSlug from the phase, we should put lower-case as a flag to make this easier to read
    return getUrlSlug(phase.title.toLowerCase()) === phaseId.toLowerCase()
  })

  // 2. from the urlSlug from routes match to the getUrl(activity.title)
  // find current activity -- matches the urlSlug
  if (currentPhase) {
    currentActivity = currentPhase.activities.find(activity => {
      return getUrlSlug(activity.title.toLowerCase()) === activityId.toLowerCase()
    })

    if (currentActivity) {
      // console.log('currentActivity', currentActivity)

      // set next phase/activity component
      // activity land page - next is next activity
      // NOTE: This is built to handle only tasks that are ContentTasks
      // and even then we only support 1 ContentTask per activity
      // more task.sections could be the `sections of a collection`
      // so this little iterate helps unravel some of that so we can
      // normalize on currentTopics ...
      currentActivity.tasks.forEach(contentTask => {
        if (contentTask.sections) {
          contentTask.sections.forEach(section => {
            currentTopics.push(section.topics)
          })
        } else {
          // not sure if this is connected and needs evalutted
          console.warn('check here if this is firing')
          currentTopics.push(contentTask)
        }
      })

      currentTopics = currentTopics.flat()
      // console.log('currentTopics', currentTopics)

      // figure out current task position in order to figure out nextActivity
      // We should also extract currentTask and currentTaskPosition
      const currentTopicPos = currentTopics.findIndex(topic => {
        // `taskId` is wrong :|
        return getUrlSlug(topic.title.toLowerCase()) === taskId.toLowerCase()
      })
      // console.log('currentTopicPos', currentTopicPos)
      const nextTopicPos = currentTopicPos + 1
      const currentTopic = currentTopics.find(topic => {
        return getUrlSlug(topic.title.toLowerCase()) === taskId.toLowerCase()
      })
      // console.log('currentTopic', currentTopic)

      // For the currentTopic build out the blocks-of-content for sections
      // individual page content for current task
      // !important this is filtering out the yaml blocks and other things
      // cause an issue when trying to build a back to previous topic-section
      const currentTopicSections = []
      currentTopic.sections.forEach(section => {
        // console.log('section', section)
        if (section.blocks[0].type === 'heading' && section.blocks[0].depth === '2') {
          currentTopicSections.push(section)
        }
      })

      // console.log('generated -- currentTopicSections', currentTopicSections)
      // store the current topic content we plan to display
      // sections start at 0, so sectionIds are at 1 by default, so need the -1 offset for array
      const currentSection = currentTopicSections[parseInt(sectionId) - 1]

      // console.log('currentSection', currentSection)

      let previousActivity
      //! Prev Activity is currently always returning undefined? Is this intended?

      // the first position for topics is 0, so if we are at front of content-task
      // we won't bother mapping back to previousTopic
      if (currentTopicPos > 0) {
        const prevTopicPos = currentTopicPos - 1
        // console.log('prevPos:', prevTopicPos)
        prevTopic = currentTopics[prevTopicPos]
      }

      // access the current phase, another one of these simple plucks we could abstract out
      // destructure into a helper potentially
      const currentPhase = data.userLearning.phases.find(phase => {
        return getUrlSlug(phase.title.toLowerCase()) === phaseId.toLowerCase()
      })

      // this if seems off, not sure currentTasks.length ... think this need modified..
      // to further support activities ...
      // and nextTaskPos is setup up top and not commented
      // looks like it it ultimate get nextTopc, but we need previous ow as weel
      // TODO look at this logic
      // written as while nextTopicPosition is less then total current topics
      // but in reverse ...

      // want to flip this and do nextTopicPoc < currentTopics.length
      // so while we still have topics available in this activity's ContentTask move them over
      if (currentTopics.length > nextTopicPos) {
        nextTopic = currentTopics[nextTopicPos]
      } else {
        /*
         * Reach into currentPhase, and find next activity,
         * return the next activity if available, nextTopic should go `null`
         */

        // current activity tasks completed > next activity
        // in currentPhase look up the INDEX for current activity
        const currentActivityPos = currentPhase.activities.findIndex(activity => {
          return getUrlSlug(activity.title.toLowerCase()) === activityId.toLowerCase()
        })

        // locate next activity in phases
        const nextActivityPos = currentActivityPos + 1
        if (currentPhase.activities.length > nextActivityPos) {
          nextActivity = currentPhase.activities[nextActivityPos]
        }

        // idea here is there is no nextTopic
        nextTopic = null
      }

      // commented out as temporary till we fix the YAML section
      // The CardStepper has a previous/next button that allows users to go back/forward between the Topic/Tasks
      // All the way to `completed`.  This logic was abstracted into a function that could be tested
      cardStepperUrls = buildPreviousNextUrl(phaseId, activityId, sectionId, currentTopic, prevTopic, nextTopic)

      // -------
      // Objective: we build `nextURL` and `previousURL` here which lays the foundation for the `to=` on
      // each button --
      // -----
      // 1. If we have a sectionId less than currentTopic.totalSections -- we are iterating through Topic/Sections
      //    The next/previous urls can be pointing at next Topic, next Section or the Previous Section, previous Topic
      //    IMPORTANT -- The elses from here are for when we are at the last Card in a topic or the Topic is single card
      if ((sectionId < currentTopicSections.length)) {
        nextURL = `/learning/${phaseId}/${activityId}/${taskId}/${parseInt(sectionId) + 1}`

        // we are in here only when incrementing though a multi-section card, so the previouUrl should be
        // the previos topicSection
        // exception though if sectionId is 1 ... we should use the prevTopic
        if (parseInt(sectionId) === 1 && prevTopic) {
          // just had a prevTopic with too many sections, like yaml is being parked ...
          // const totalSections = prevTopic.sections.length + 1
          // !IMPORTANT -- Patch our section/BlocketSet fix here...
          const totalSections = 1 // TEMP WHILE WE FIX THIS block/section processing....
          // !IMPORTANT - this should not be 1
          // previous Topic
          // previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${totalSections}`
          previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${(prevTopic.sections.length) + -1}`
        } else {
          // if we are not card 1, we can just default to -1 to go back one BlockSet
          // current topic
          previousURL = `/learning/${phaseId}/${activityId}/${taskId}/${parseInt(sectionId) + -1}`
        }
      // 2. We are at the last card of a Topic (orSingleCardTopic) and if we have a nextTopic
      // What we do now, if they section exceeds, check if we have a nextTopc
      // if so we build next button to direct to that topic at section 1
      // Understand we come in here when on first load somehow
      } else if (nextTopic) {
        // console.log('CardStepper Next | currentTasks > nextTaskPost', currentTopics.length, ' - ', nextTopicPos)
        // TODO when this button click=()=> update this current Topic completed
        nextURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(nextTopic.title.toLowerCase())}/1`
        // previous url is easy here, the prevTopics doesn't change
        // still have the section bug here though when we move into new topic
        if (parseInt(sectionId) === 1 && prevTopic) {
          // just default to one
          // just had a prevTopic with too many sections, like yaml is being parked ...
          // const totalSections = prevTopic.sections.length + 1
          const totalSections = 1 // TEMP WHILE WE FIX THIS block/section processing....
          // previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${totalSections}`
          previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${(prevTopic.sections.length) + -1}`
        } else {
          previousURL = `/learning/${phaseId}/${activityId}/${taskId}/${parseInt(sectionId) + -1}`
        }
      // 3. We are on the last Section (or single section Topic)
      //    - Previous Section should be the the previos section, unless this singleCard then previous
      //      should put to last card in the previous topic.  CHECK if previous Topics for number of sections (etc)
      //          -- KNOWN BUG
      //
      //    - Next -- should go to <outro /> using `complete` ... if there are no more topics
      } else {
        // Currently we are going to default to setting nextURL to the <Outro /> card in `<CardStepper />`
        // when we are on the lastCard in a section and there are no more Topics available
        nextURL = `/learning/${phaseId}/${activityId}/complete`

        // There also a case where we don't have nextActivity

        // we are in here only when incrementing though a multi-section card, so the previouUrl should be
        // the previous topicSection
        // exception though if sectionId is 1 ... we should use the prevTopic
        if (parseInt(sectionId) === 1 && prevTopic) {
          // just had a prevTopic with too many sections, like yaml is being parked ...
          // const totalSections = prevTopic.sections.length + 1
          // !IMPORTANT -- Patch our section/BlocketSet fix here...
          const totalSections = 1 // TEMP WHILE WE FIX THIS block/section processing....
          // previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${totalSections}`
          previousURL = `/learning/${phaseId}/${activityId}/${getUrlSlug(prevTopic.title.toLowerCase())}/${(prevTopic.sections.length) + -1}`
        } else {
          // if we are not card 1, we can just default to -1 to go back one BlockSet
          previousURL = `/learning/${phaseId}/${activityId}/${taskId}/${parseInt(sectionId) + -1}`
        }
      }
      // console.log('How long is the previous section?', prevTopic.sections.length)
      console.log('The current section ID:', sectionId)

      // console.log('previousActivity', previousActivity)
      // console.log('nextActivity', nextActivity)
      // console.log('previous topic sections', prevTopic.sections)
      // console.log('prevTopic', prevTopic)
      // console.log('nextTopic', nextTopic)

      // content
      pageData = {
        topicUuid: currentTopic.uuid,
        userActivityUuid: currentActivity.uuid,
        title: currentTopic.title ? currentTopic.title : '',
        subtitle: (currentSection) ? currentSection.blocks[0].value : '',
        type: currentTopic.type,
        repository: currentTopic.repository,
        body: (currentTopic.instructions) ? currentTopic.instructions : undefined,
        steps: (currentTopic.steps) ? currentTopic.steps : undefined,
        sectionId: parseInt(sectionId),
        totalSections: currentTopicSections ? currentTopicSections.length : 0,
        section: currentSection,
        // need this meta to display images
        owner: currentTopic.owner,
        filepath: currentTopic.filepath,
        filename: currentTopic.filename,
        ref: currentTopic.ref

      }

      // console.info('Sherpa [pageData]:', pageData)

      // Restructure this block so that error messaging is built and handled at render
      // these end up render an empty white page that is confusing...
    } else {
      return 'Error!: can not find activity'
    }
  } else {
    return 'Error!: can not find phase'
  }

  // set back button
  const backObj = {
    title: 'Back to ' + currentActivity.title,
    phaserUrl: '/learning/' + phaseId,
    activityUrl: '/learning/' + phaseId + '/' + activityId,
    url: '/learning/' + phaseId + '/' + activityId
  }

  // display rail
  const railObj = currentTopics.map(topic => {
    return {
      type: topic.type,
      state: (getUrlSlug(topic.title.toLowerCase()) === taskId.toLowerCase()) ? 'active' : 'unassigned',
      uuid: topic.uuid
    }
  })

  return (
    <SherpaLayout type='content' backto={backObj} currentPhase={currentPhase} currentActivity={currentActivity} nextActivity={nextActivity}>
      <CardStepper type='content' data={pageData} rail={railObj} nextURL={cardStepperUrls.nextURL} previousURL={cardStepperUrls.previousURL} localProgress={cachedProgress} />
    </SherpaLayout>
  )
}

export default SherpaContentView
