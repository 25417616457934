/*
 *  SUMMARY
 *
 *  <TopicListingTable /> a molecule that contains all the necessary Table components to render the TopicListingTable
 */
// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'
// get our colors
import { LEGACY, NIGHTS_WATCH } from '../../../core/colors'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'
import { GET_ME_QUERY } from '../../../lib/queries/auth'

// Core UI Imports
import StyledLink from '../../../core/atoms/styledLink'
import RepositoryBadge from '../../../core/molecules/RepositoryBadge/RepositoryBadge'
import TopicCategoryBadge from '../../../core/molecules/TopicCategoryBadge'
import OwnerBadge from '../../../core/molecules/OwnerBadge/'
import TopicBranch from '../../../core/atoms/glyphs/doc-ops/repository/gitBranch'

// Local Doc-Ops imports
import TopicCategoryLabel from '../../atoms/topicCategoryLabel'
import StatusLabel from '../../atoms/statusLabel'
import DisplayTopicTitle from '../../atoms/displayTopicTitle'

import { getRelativeDateFromUnixTimeStamp } from '../../../lib/utils'

const TopicListingTableFrame = styled.table`
    width:100%;
    border-collapse: collapse;
`
const TableHeader = styled.th`
    text-align:left;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: .5em;
    font-family: 'Open Sans';
    font-weight:normal;
    letter-spacing: -0.09px;
    color: ${NIGHTS_WATCH};
`

// Need a basic column to set some padding (top/bottom)
const TableColumn = styled.td`
    padding: .5em 0 .5em 0;
    border-bottom: 1px solid #E3E3E3;
    color: ${LEGACY};
    width: 175px;
`
const TableColumnTitle = styled.td`
    padding: .5em .5em .5em 0;
    border-bottom: 1px solid #E3E3E3;
    width: 40%;
`

function TopicListingTable (props) {
  const { loading, error, data } = useQuery(GET_ME_QUERY)
  if (loading) {
    return <div>Loading</div>
  }
  if (error) {
    return <div>There was an error with GQL</div>
  }
  const org = data.org

  const topics = props.topics

  // can invoke with `{emptyTable()}` within the jsx below if our props.data is empty
  const EmptyTable = () => { 
    //This topic can link to how to injest our topics at some point, need a valid URL to target though
    const emptyTopic = {
      topics: {
        title: 'You have no topics',
        type: 'Changelogc'
      }
   
    }
    return (
    <tr>
      <TableColumnTitle><StyledLink><DisplayTopicTitle topic={emptyTopic}/></StyledLink></TableColumnTitle>
    </tr>
    
    ) }

  // In the event Topics is Empty return something sensible
  if (topics.length === 0) {
    return EmptyTable()
  }

  // generator of the table data rows for each topic
  function createTableDataRows (topics) {
    const topicRows = topics.map((topic) => {
      //This will BREAK if the input isn't initially 'refs/heads/'
      // We could instead do the slice on the second /
      const topicRef = topic.topics.ref.slice(11)
      const topicUrl = `/topic/${topic.topics.repository}/${topic.topics.urlSlug}`
      // topic.updateAt comes back as unix timestamp
      // "1572447877224"
      return (
        <tr key={topic.topics.uuid + Math.floor(Math.random() * 10000)}>
          <TableColumnTitle><StyledLink to={topicUrl}><DisplayTopicTitle topic={topic} /></StyledLink></TableColumnTitle>
          <TableColumn><TopicCategoryBadge category={topic.topics.type} /></TableColumn>
          <TableColumn><OwnerBadge {...org}>{topic.topics.owner}</OwnerBadge></TableColumn>
          <TableColumn><RepositoryBadge>{topic.topics.repository}</RepositoryBadge></TableColumn>
          <TableColumn><TopicBranch/> {topicRef}</TableColumn>
          <TableColumn>{getRelativeDateFromUnixTimeStamp(topic.topics.updatedAt)}</TableColumn>
        </tr>
      )
    })
    return topicRows
  }

  // Invoke and generate the TableDataRows
  const tableRows = createTableDataRows(topics)

  return (
    <div>
      <TopicListingTableFrame>
        <thead>
          <tr>
            <TableHeader>Title</TableHeader>
            <TableHeader>Category</TableHeader>
            <TableHeader>Owner</TableHeader>
            <TableHeader>Repository</TableHeader>
            <TableHeader>Default Branch</TableHeader>
            <TableHeader>Updated</TableHeader>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </TopicListingTableFrame>
    </div>
  )
}

// https://reactjs.org/docs/typechecking-with-proptypes.html
// For single-children, https://reactjs.org/docs/typechecking-with-proptypes.html#requiring-single-child
// https://stackoverflow.com/questions/44582209/proptypes-in-functional-stateless-component
TopicListingTable.propTypes = {
  topics: PropTypes.array.isRequired

}

export default TopicListingTable
