import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components/macro'

import { ARCHER_GREY, SPACE_GREY } from '../../../core/colors'

// get our utils
import { getUrlSlug } from '../../../lib/utils'

// Local / Core Atoms
import ActivityIcon from '../../atoms/glyphs/activity'

import Header from '../../atoms/header'
import Text from '../../atoms/text'
import Separator from '../../atoms/separator'
import LearningTaskList from '../LearningTaskList'

// replacing with button
import StartBtn from '../../atoms/glyphs/start'
import ActivityButton from '../../atoms/ActivityButton'

import Label from '../../atoms/label'
import Icon from '../../molecules/Icon'
import ArrowDownIcon from '../../atoms/glyphs/arrowDown'
import ArrowRightIcon from '../../atoms/glyphs/arrowRight'
import CircleActive from '../../atoms/glyphs/circleActive'
import CircleInactive from '../../atoms/glyphs/circleInactive'

import TimeEstimateBadge from '../../molecules/TimeEstimateBadge'

import Rail from '../../templates/rail'
import Block from '../../templates/block'

import * as _ from 'lodash'

const ActivityBlockFrameMount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`

const StatusIconFrame = styled.div`
  align-content: center;
  align-items: center;
  text-align: center;
  width: 24px;
`

const StatusIconFrameWUpNext = styled.div`
  align-content: center;
  align-items: center;
  text-align: center;
  margin: 32px 0 0 0;
  width: 24px;
`

const ActivityDescriptionInnerFrame = styled.div`
  border: 1px solid #DDE0E4;
  border-radius: 4px;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  margin: 0 0 36px 0;
`

const LabelFrame = styled.div`
  margin: 0 0 8px 0;
`

const ActivityDescription = styled.div`
  display: flex;
  flex-flow: column nowrap;

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width:641px) {
    flex-flow: row nowrap;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }	
  @media(min-width: 801px) {

  }
`

const ActivityInfo = styled.div`
  background: #fff;
  flex: 0 1 70%;
  padding: 24px;
`

const ActivityInfoHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 8px 0;
`

const DescIconFrame = styled.div`
  flex: 0 1 16px;
  margin: 0 8px 0 0;
`

const DescHeader = styled.div`
  flex: 1 0 auto;
`

const ActivityDescBody = styled.div`
  margin: 0 0 0 24px;
`

const ActivityBlockAction = styled.div`
  background: #f9fcff;
  display: flex;
  flex: 1 0 30%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

const ActivityBlockTaskDetailed = styled.div`
  margin: 16px 0 0 0;
`

const ActivityBlockTaskSummary = styled.div`
  display: flex;
  margin: 16px 0 0 0;
  flex-flow: row nowrap;
`

const ActivityDescriptionControl = styled.div`
  cursor: pointer;
`

// Considering a CSS solution, though it pixelates the border, so a layered SVG might be best
// if we stay with html -- https://stackoverflow.com/questions/36489414/css-rounded-border-looks-too-pixelated#:~:text=There's%20really%20nothing%20you%20can,in%20the%20monitor%20anti%2Daliasing.
const HTMLCircle = styled.div`
height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  border:1px solid ${ARCHER_GREY};
  box-shadow: 0 0 0 1px ${ARCHER_GREY};
  display: inline-block;
  text-align:center;
  
`

const EstimatedTimeFrame = styled.div`
  padding: 1.8em 0 0 0;
`

const ActivityBlock = (props) => {
  const [displayDetails, setDisplayDetails] = useState(props.displayDetails || false)

  // Determine which ICON we send into the `templates/rail`
  let statusIcon
  // check first if the activityCompleted flag is sent, should act as an override for now
  if (props.activityCompleted) {
    statusIcon = (<HTMLCircle><ActivityIcon completed paddingTop /></HTMLCircle>)
  } else if (props.data.status === 'active') {
    if (props.upnext) {
      // this don't look right, evaluate....
      statusIcon = (<StatusIconFrameWUpNext><CircleActive /></StatusIconFrameWUpNext>)
    } else {
      statusIcon = (<HTMLCircle><ActivityIcon active paddingTop /></HTMLCircle>)
    }
    // can we move the header piece of the block?
  } else {
    if (props.upnext) {
      statusIcon = (<StatusIconFrameWUpNext><CircleInactive /></StatusIconFrameWUpNext>)
    } else {
      statusIcon = (<StatusIconFrame><CircleInactive /></StatusIconFrame>)
    }
  }

  // from props.data get our activity-title and slugify it so we have an activityId we can map into app-router
  const activityRouteId = getUrlSlug(props.data.title).toLowerCase()

  // Here this is something to fiugre out if we neeed still
  // marshall an array of task we will send to <ActivityList />`
  // console.log('PHASER-data>', props.data)
  const activityLearnings = _.filter(props.data.tasks, task => {
    return task.type === 'learning'
  })

  const activityAssessments = _.filter(props.data.tasks, task => {
    return task.type === 'assessment'
  })

  let descIcon
  let tasks

  // Whether the collapse-toggle is opened/closed
  // displayDetails means open it so render the Task lists
  // goal here is to build `deskIcon` and `tasks` as component variables we can drop into single render
  if (displayDetails) {
    descIcon = (
      <ActivityDescriptionControl onClick={() => setDisplayDetails(!displayDetails)}>
        <ArrowDownIcon />
      </ActivityDescriptionControl>
    )

    // put our tasks into a variable for rendering
    // props.data[0].sections[0]
    tasks = (
      <ActivityBlockTaskDetailed>
        <LearningTaskList type='learnings' header='Tasks' sections={activityLearnings[0].sections} progress={props.taskProgress} />
      </ActivityBlockTaskDetailed>
    )
  // else-closed, so render the one-line task summary component
  } else {
    const learningHeader = (activityLearnings.length > 1) ? activityLearnings.length.toString() + ' Tasks' : activityLearnings.length.toString() + ' Task'
    const assessmentHeader = (activityAssessments.length > 1) ? activityAssessments.length.toString() + ' Assessments' : activityAssessments.length.toString() + ' Assessment'

    descIcon = (
      <ActivityDescriptionControl onClick={() => setDisplayDetails(!displayDetails)}>
        <ArrowRightIcon />
      </ActivityDescriptionControl>
    )

    // place our task list into a variable to render
    tasks = (
      <ActivityBlockTaskSummary>
        {activityLearnings.length > 0
          ? <Header type='learnings'>{learningHeader}</Header>
          : ''}
        {activityLearnings.length > 0 && activityAssessments.length > 0
          ? <Separator />
          : ''}
        {activityAssessments.length > 0
          ? <Header type='assessments'>{assessmentHeader}</Header>
          : ''}
      </ActivityBlockTaskSummary>
    )
  }

  const GB_ACADEMY_DESCRIPTION = 'Let’s take a walk through gitBabel and better understand how it works.'
  const activityDescription = (props.data.description) ? props.data.description : GB_ACADEMY_DESCRIPTION
  const activityTimeEstimate = '2-3 mins'

  // Handle Activity Button State
  // if has progress could be resume ... completed is ?
  let BUTTON_TEXT = 'START'
  if (props.activityCompleted) {
    BUTTON_TEXT = 'VIEW'
  } else {
    BUTTON_TEXT = 'START'
  }
  // active or completed?
  const BUTTON_STATUS = 'active'

  return (
    <ActivityBlockFrameMount>
      <Rail>
        {statusIcon}
      </Rail>
      <Block>
        {props.upnext &&
          <LabelFrame>
            <Label type='medium'>UpNext</Label>
          </LabelFrame>}
        <ActivityDescriptionInnerFrame>
          <ActivityDescription>
            <ActivityInfo>
              <ActivityInfoHeader>
                <DescIconFrame>
                  {descIcon}
                </DescIconFrame>
                <DescHeader>
                  <Header type='activity'>{props.data.title}</Header>
                </DescHeader>
              </ActivityInfoHeader>
              <ActivityDescBody>
                <p>{activityDescription}</p>
                {tasks}
              </ActivityDescBody>
            </ActivityInfo>
            <ActivityBlockAction>

              <ActivityButton activityCompleted={props.activityCompleted} to={`/learning/welcome-tour/${activityRouteId}`}>{BUTTON_TEXT}</ActivityButton>
              {/* hard coded for now, connect to api once ready */}
              <TimeEstimateBadge seconds={600} />
            </ActivityBlockAction>
          </ActivityDescription>
        </ActivityDescriptionInnerFrame>
      </Block>
    </ActivityBlockFrameMount>
  )
}

ActivityBlock.propTypes = {
  displayDetails: PropTypes.bool,
  activityCompleted: PropTypes.bool,
  upnext: PropTypes.bool,
  data: PropTypes.object,
  taskProgress: PropTypes.object
}

export default ActivityBlock
