
import React from 'react'
/*
 *  The Topic RFC Glyph
 */
const TopicRfcGlyph = (props) => {
  const { width, height } = props
  const strokeColor = props.strokeColor ? props.strokeColor : '#000000'
  return (
    <div style={{}}>
      <svg width={width} height={height} viewBox='0 0 24 24' fill='#ffffff' version='1.1'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12.6451 14.4861H6.75006C6.24784 14.4867 5.77863 14.246 5.50006 13.8448L2.00006 8.80484C1.66421 8.32117 1.66421 7.69108 2.00006 7.2074L5.50006 2.1674C5.77863 1.76623 6.24784 1.52552 6.75006 1.52612H12.6501C13.1516 1.52616 13.6199 1.7668 13.8981 2.1674L17.3981 7.2074C17.7339 7.69108 17.7339 8.32117 17.3981 8.80484L13.8981 13.8448C13.6189 14.2469 13.1483 14.4877 12.6451 14.4861Z' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M9.69702 10.1661C10.9397 10.1661 11.947 9.199 11.947 8.00607C11.947 6.81313 10.9397 5.84607 9.69702 5.84607C8.45438 5.84607 7.44702 6.81313 7.44702 8.00607C7.44702 9.199 8.45438 10.1661 9.69702 10.1661Z' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.22803 17.8663C9.437 21.1192 12.7021 23.2451 16.2974 23.1203C19.8927 22.9955 22.9893 20.6487 23.9504 17.3204C24.9114 13.9921 23.5153 10.4498 20.5 8.5658' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.2771 17.681C14.6119 19.0322 16.7998 19.1832 18.3221 18.0291C19.8444 16.8751 20.1988 14.7969 19.1391 13.2372' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  )
}
export default TopicRfcGlyph
